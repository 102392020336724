import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxContactFormMessagesScheme,
  IBoxContactFormSettingsScheme,
} from "./BoxContactForm.scheme";

export type IBoxContactForm = GenericBoxData<
  { component: "CONTACT_FORM" },
  Record<string, never>,
  Record<string, never>,
  IBoxContactFormMessages,
  IBoxContactFormSettings
>;

export type TBoxContactFormProps = IPortalBox<IBoxContactForm>;

export type IBoxContactFormMessages = z.infer<
  typeof IBoxContactFormMessagesScheme
>;

export type IBoxContactFormSettings = z.infer<
  typeof IBoxContactFormSettingsScheme
>;

export enum BoxContactFormFields {
  TOPIC = "topic",
  COMPLAIN_REASON = "complainReason",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  NIP = "nip",
  RETURN_ADDRESS = "returnAddress",
  EMAILS = "emails",
  PHONE = "phone",
  INVOICE_NUMBER = "invoiceNumber",
  MESSAGE = "message",
}
