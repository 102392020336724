import styled from "styled-components";

export const ContactSectionStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  .contact-customer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &__change-account-button {
      position: absolute;
      right: 0;
      top: 0;
    }
    &__login-message {
      a {
        color: inherit;
      }
    }
  }
  .contact-form-quest {
    width: 100%;
    &__email-loader {
    }
  }
  .contact-form-action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
