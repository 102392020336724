import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import {
  createTitleContentSettingsScheme,
  ImageInlineContentSettingsScheme,
} from "../../settingsSchemes/settingsSchemes.utils";
import { ImagePlacement } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  createTextStyleShowSettingScheme,
  UrlScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const EDITOR_INITIAL_VALUE = [
  {
    type: "paragraph",
    children: [
      {
        text: "text",
      },
    ],
    align: "left",
  },
];

export const BoxRichTextContentSettingsScheme = z.object({
  title: createTitleContentSettingsScheme(),
  description: createTextStyleShowSettingScheme(),
  imageInline: ImageInlineContentSettingsScheme,
  contentAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  imageAlignment: z
    .nativeEnum(ImagePlacement)
    .default(ImagePlacement.TOP)
    .catch(ImagePlacement.TOP),
});

export const BoxRichTextDisplaySettingsScheme = z.object({
  gap: z.number().default(20).catch(20),
});

export const BoxRichTextSettingsScheme = BoxRichTextContentSettingsScheme.merge(
  BoxRichTextDisplaySettingsScheme
);

export const BoxRichTextContentScheme = z.object({
  title: z.string().default(JSON.stringify(EDITOR_INITIAL_VALUE)),
  description: z.string().default(JSON.stringify(EDITOR_INITIAL_VALUE)),
  imageInline: z
    .object({
      imageUrl: UrlScheme.default("").catch(""),
    })
    .default({ imageUrl: "" }),
  richTextUrl: LinkContentScheme.default({
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToPageSetting: { link: "", openInNewTab: true },
    showLinkOptions: true,
    goToRowSetting: { rowId: null },
  }),
  seoDescription: z.string().default("").catch(""),
});

export const BoxRichTextMessagesScheme = z.object({
  box_rich_text_content_title: z
    .string()
    .default(JSON.stringify(EDITOR_INITIAL_VALUE)),
  box_rich_text_content_description: z
    .string()
    .default(JSON.stringify(EDITOR_INITIAL_VALUE)),
  box_rich_text_content_seo_description: themeScheme.MessageScheme.default(""),
});
