import { useState } from "react";
import * as storeLocatorResults from "@ecp-redux/api/storesLocator";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { IBoxMyAccountMyAddressListProps } from "../../BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import { IBoxStoreLocatorProps } from "../BoxStoreLocator.types";
import { IStoresDisplayProps } from "../StoreLocatorMap/StoreLocatorMap.types";
import StoresDisplayDesktop from "./StoresDisplayDesktop";
import StoresDisplayMobile from "./StoresDisplayMobile";

const StoresDisplay: React.FC<IStoresDisplayProps> = ({
  onSelectAddressButtonClick,
  cartStatus,
  visibleInLocator,
}: IStoresDisplayProps) => {
  const { messages, settings } = useBoxContext<
    IBoxStoreLocatorProps | IBoxMyAccountMyAddressListProps
  >();
  const isMobile = useIsMobilePortal();
  const [searchValue, setSearchValue] = useState("");
  const [refreshMapView, setRefreshMapView] = useState(false);
  const { data } = storeLocatorResults.usePostStoresLocatorQuery({
    page: { number: 1, size: 1000 },
    searchPhrase: searchValue,
    cartStatus,
    visibleInLocator,
  });

  const fetchStores = (val: string) => {
    setSearchValue(val);
    if (val === searchValue) {
      setRefreshMapView(!refreshMapView);
    }
  };
  return (
    <>
      {isMobile && (
        <StoresDisplayMobile
          messages={messages}
          settings={settings}
          fetchStores={fetchStores}
          data={data}
          onSelectAddressButtonClick={onSelectAddressButtonClick}
          refreshMapView={refreshMapView}
          isEmptySearchValue={searchValue === ""}
        />
      )}
      {!isMobile && (
        <StoresDisplayDesktop
          messages={messages}
          settings={settings}
          fetchStores={fetchStores}
          data={data}
          onSelectAddressButtonClick={onSelectAddressButtonClick}
          refreshMapView={refreshMapView}
          isEmptySearchValue={searchValue === ""}
        />
      )}
    </>
  );
};

export default StoresDisplay;
