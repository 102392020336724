import { useEffect, useRef } from "react";

const useInert = <T extends HTMLElement>(isDisabled: boolean) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      if (isDisabled) {
        ref.current.setAttribute("inert", "true");
      } else {
        ref.current.removeAttribute("inert");
      }
    }
  }, [isDisabled, ref]);

  return ref;
};

export default useInert;
