import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxChangePasswordMessagesScheme = z.object({
  change_password_form_title: themeScheme.MessageScheme.default("Zmień hasło"),
  change_password_current_password_placeholder:
    themeScheme.MessageScheme.default("Obecne hasło"),
  change_password_new_password_placeholder:
    themeScheme.MessageScheme.default("Nowe hasło"),
  change_password_new_password_info: themeScheme.MessageScheme.default(
    "Użyj co najmniej 8 znaków - wielkich i małych liter oraz cyfry"
  ),
  change_password_repeat_new_password_placeholder:
    themeScheme.MessageScheme.default("Powtórz nowe hasło"),
  change_password_button_label:
    themeScheme.MessageScheme.default("Zmień hasło"),
  change_password_field_is_required:
    themeScheme.MessageScheme.default("Pole jest wymagane"),
  change_password_field_wrong_password: themeScheme.MessageScheme.default(
    "Podane hasło jest nieprawidłowe"
  ),
  change_password_field_validation_error: themeScheme.MessageScheme.default(
    "Użyj co najmniej 8 znaków - wielkich i małych liter oraz cyfry"
  ),
  change_password_field_same_password_error_validation:
    themeScheme.MessageScheme.default("Hasła muszą być takie same"),
});

export const IBoxChangePasswordSettingsScheme = z.object({
  change_password_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  change_password_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  change_password_form_title_spacing:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  change_password_form_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  change_password_input_style: themeScheme.InputIdScheme.default("input$$1"),
  change_password_button_style: themeScheme.ButtonIdScheme.default("button$$1"),
  change_password_form_wrapper_margin:
    themeScheme.MarginSizeScheme.default("0px 0px 0px 0px").catch(
      "0px 0px 0px 0px"
    ),
  change_password_redirect_successful_phone_change:
    themeScheme.UrlScheme.default("").catch(""),
});
