import styled from "styled-components";

export const StyledYouPackstation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .your-packstation-section {
    &__chosen-packstation {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;
      button {
        flex-shrink: 0;
      }
    }
    &__add-new-btn {
      align-self: flex-end;
    }
  }
`;
