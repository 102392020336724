import styled from "styled-components";

export const PlaceOrderButtonContainer = styled.div<{
  $isPaymentProcessing: boolean;
}>`
  display: flex;
  justify-content: center;
  position: relative;
  button {
    p {
      visibility: ${({ $isPaymentProcessing }) =>
        $isPaymentProcessing ? "hidden" : "visible"};
    }
    width: 100%;

    .loader {
      position: absolute;
      top: calc(50% + 3px);
      left: 50%;
      transform: translate(-50%, -50%);
      display: ${({ $isPaymentProcessing }) =>
        $isPaymentProcessing ? "block" : "none"};
    }
  }
`;
