import { isClientSide } from "@ecp-redux/api/api";
import { useCallback } from "react";
import { z } from "zod";

const sessionStorageSchema = z.object({
  customer_id: z.string(),
  email: z.string(),
  collect_events: z.string(),
  is_customer_state_validated: z.string(),
  site_type: z.string(),
  srp_category_id: z.string(),
  recipient_address: z.record(z.string(), z.string()),
  billing_address: z.record(z.string(), z.string()),
});

type SessionStorageSchema = z.infer<typeof sessionStorageSchema>;
// eslint-disable-next-line @typescript-eslint/ban-types
type TSessionStorageKeys = keyof SessionStorageSchema | (string & {});

const useSetSessionStorage = () => {
  const setSessionStorageItem = useCallback(
    (key: TSessionStorageKeys, value: object | string) => {
      if (isClientSide()) {
        sessionStorage.setItem(
          key,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      }
    },
    []
  );
  const getSessionStorageItem = useCallback((key: TSessionStorageKeys) => {
    if (isClientSide()) {
      const storedItem = sessionStorage.getItem(key);
      if (!storedItem) return null;

      try {
        return JSON.parse(storedItem);
      } catch {
        return storedItem;
      }
    }
    return null;
  }, []);

  const removeSessionStorageItem = useCallback((key: TSessionStorageKeys) => {
    if (isClientSide()) {
      sessionStorage.removeItem(key);
    }
  }, []);

  return {
    setSessionStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
  };
};
export default useSetSessionStorage;
