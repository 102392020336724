import styled from "styled-components";

export const StyledPaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .payment-section__payment-method {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
