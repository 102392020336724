import styled, { css } from "styled-components";
import {
  TColorId,
  TSizeCSS,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import { B } from "msw/lib/glossary-de6278a9";

export const StyledBoxCartStepOne = styled.div(
  () => css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 100%;

    @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 20px;
    }

    .cart_products {
      grid-column: span 2 / span 2;
    }
  `
);

export const StyledProductsCartList = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return css`
    background-color: ${bg};
    width: 100%;
    padding: 32px;

    .crossed {
      opacity: 0.5;
    }

    .products-cart-list {
      &__header-container {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
      }

      &__items-count {
        align-self: center;
      }

      &__description {
        margin-bottom: 19px;
        display: flex;
        justify-content: space-between;
      }

      &__alerts-container {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      &__product-cart-element-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
      }
      &__product-cart-underline {
        color: #d3d6de;
      }
    }
  `;
});

export const StyledCrossedArea = styled.div(
  () => css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
        to top left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%
      );
    width: 100%;
    height: 100%;
  `
);

export const StyledProductsCartElement = styled.div<{
  unvailableProduct: boolean;
  imageSize: TSizeCSS;
  isPriceDifferent: boolean;
  priceDiffetentTypo: TTypoId;
}>(
  ({
    unvailableProduct,
    imageSize,
    isPriceDifferent,
    priceDiffetentTypo,
    theme,
  }) => {
    const typoPriceLineHeight = convertTypoIdToValues(
      priceDiffetentTypo,
      theme.typography
    ).lineHeight;
    return css`
      position: relative;
      display: grid;
      grid-template-columns: ${imageSize} 1fr;

      @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
        grid-template-columns: ${imageSize} 30% 1fr minmax(90px, auto);
        grid-template-rows: 1fr;
      }

      .product-cart-element {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        &__image {
          width: 100%;
          grid-column-start: 1;
          grid-row: span 2 / span 2;
          grid-row-start: 1;
          position: relative;
          height: fit-content;
        }

        &__info_details {
          margin-left: 10px;
          grid-column-start: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }

        &__name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          grid-column: span 11;
          width: 100%;
        }

        &__suggested-retail-price {
          text-decoration: line-through;
          margin-top: 5px;
        }

        &__attributes-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          gap: 4px;
        }

        &__attribute p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__delivery-time {
          display: flex;
          grid-column-start: 2;
          margin-left: 10px;
          align-self: flex-end;
          grid-row-start: 2;
        }

        &__delivery-time-details {
          flex-shrink: 0;
        }

        &__wishlist-icon {
          margin-left: 28px;
        }
      }

      .product_cart_info {
        &__text {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: 8px;
        }
        &__promo-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .product-cart-element {
        display: flex;
        grid-column-start: 1;
        height: 100%;
        padding-top: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;

        &__quantity {
          position: absolute;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          text-align: center;
          align-self: center;
        }

        @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
          grid-column-start: 3;
          grid-row: span 2;
          margin: 0;
          padding: 0;
        }
      }

      .product_cart_quantity {
        display: flex;
        flex-direction: column;
        grid-column-start: 2;
        height: 100%;
        width: 100%;
        justify-content: center;
        position: relative;
        justify-self: flex-end;

        &__unavailable-product {
          align-self: flex-end;
        }

        &__icons {
          position: absolute;
          top: 0;
          right: 0;
        }

        &__remove-button {
          position: absolute;
          top: 0;
          right: 0;
          :hover {
            opacity: 0.2;
          }
        }

        &__total-price {
          display: flex;
          min-width: 0px;
        }

        &__total-price-details {
          flex-shrink: 0;
          width: 100%;
          text-align: right;
          ${!isPriceDifferent ? `margin: ${typoPriceLineHeight / 2}px 0;` : ""}
        }
        &__suggested-retail-price {
          flex-shrink: 0;
          width: 100%;
          text-decoration: line-through;
          text-align: right;
        }

        @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
          grid-column-start: 4;
          grid-row: span 2;
        }
      }
    `;
  }
);

export const StyledSummary = styled.div<{
  backgroundColor: TColorId;
  buttonWidth: string;
  isSticky: boolean;
  totalStickyHeaderHeight: number;
}>(
  ({
    backgroundColor,
    theme,
    buttonWidth,
    isSticky,
    totalStickyHeaderHeight,
  }) => {
    const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
    return css`
      background-color: ${bg};
      width: 100%;
      ${buttonWidth ? `${StyledButton} { width: ${buttonWidth}; }` : ""}
      .summary {
        &_content {
          padding: 40px 16px 16px;
          ${isSticky
            ? `position: sticky; top:${totalStickyHeaderHeight}px;`
            : "top: 0;"}
        }

        &__header {
          margin-bottom: 1.25rem;
        }

        &__product-cart-element-container {
          background-color: ${bg};
          padding: 16px 20px;
        }

        &__products-price {
          margin: 19px 0;
          display: flex;
          justify-content: space-between;
        }

        &__save-amount {
          margin-bottom: 19px;
          display: flex;
          justify-content: space-between;
        }

        &__delivery-cost {
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        }

        &__amount-to-pay {
          display: flex;
          justify-content: space-between;
          margin: 14px 0;
          align-items: center;
        }

        &__promo-code-separator {
          color: #d3d6de;
          margin: 14px 0;
        }
        &__summary-underline {
          color: #d3d6de;
        }

        &__product-cart-underline {
          margin: 16px 0;
        }
      }
    `;
  }
);

export const StyledFixedSummary = styled.div<{
  $backgroundColor: TColorId;
  $isVisible: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ $backgroundColor, theme }) =>
    convertColorIdToHex($backgroundColor, theme.colorPalette)};
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(${({ $isVisible }) => ($isVisible ? "0" : "100%")});
  transition: transform 300ms ease-in-out;
  z-index: 100;

  button {
    width: 100%;
  }

  .one-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fixed-summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .place-order-btn {
    margin-top: 16px;
  }
`;
