import { useState } from "react";
import { StyledPaymentSection } from "./PaymentSection.styled";
import SectionWrapper from "./SectionWrapper";
import SelectionTable from "../shared/SelectionTable";
import { PaymentType } from "@ecp-redux/dto/cart.types";
import CreditCard from "../payments/CreditCard";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import ApplePay from "../payments/ApplePay";
import GooglePay from "../payments/GooglePay";
import * as cartAPI from "@ecp-redux/api/cart";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import PaymentIcon from "../shared/PaymentIcon";
import { isNotEmptyMessage } from "@ecp-boxes/helpers/isNotEmptyMessage";
import PaypalExtraComponent from "../payments/PaypalExtraComponent";

const disabledPaymentMethods = [PaymentType.PAYPAL_EXPRESS];

const paymentWithNoValidation = [
  PaymentType.CASH_ON_DELIVERY,
  PaymentType.PAYMENT_IN_ADVANCE,
  PaymentType.AUTOPAY,
];

export type TPaymentComponentRef = {
  generateNonce: () => Promise<{ nonce: string | null; hasError: boolean }>;
};

type PaymentRefKeys = "CREDIT_CARD" | "GOOGLE_PAY" | "APPLE_PAY" | "PAYPAL";

const PaymentSection = ({
  paymentRefs,
}: {
  paymentRefs: {
    [K in PaymentRefKeys]: React.RefObject<TPaymentComponentRef>;
  };
}) => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { setSectionValidation, isDeliveryChannelSelected } =
    useBoxCartStepTwoExtended();

  const { data: deliveryAndPayment } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const [changeMethod] = cartAPI.usePostChangePaymentMethodExtendedMutation();
  const paymentMethods = deliveryAndPayment?.response?.payments ?? [];

  const [optimisticSelected, setOptimisticSelected] = useState(
    paymentMethods.find(({ selected }) => selected)?.paymentType
  );

  if (paymentWithNoValidation.includes(optimisticSelected as PaymentType)) {
    setSectionValidation("payment", true);
  }

  const paymentComponent = (paymentMethod: PaymentType) => {
    const paymentMethodTitle =
      messages[`payment_${paymentMethod}_title` as keyof typeof messages];
    return (
      <div className="payment-section__payment-method">
        <StyledText
          show={!!paymentMethodTitle}
          $settings={{
            text: { color: settings.cart_color_primary },
            font: settings.section_subtext_typography,
          }}
        >
          {paymentMethodTitle}
        </StyledText>
        <PaymentIcon paymentMethod={paymentMethod} />
      </div>
    );
  };

  const extraComponentLookup: Partial<Record<PaymentType, React.ReactNode>> = {
    CREDIT_CARD: <CreditCard creditCardRef={paymentRefs.CREDIT_CARD} />,
    GOOGLE_PAY: <GooglePay googlePayRef={paymentRefs.GOOGLE_PAY} />,
    APPLE_PAY: <ApplePay applePayRef={paymentRefs.APPLE_PAY} />,
    PAYPAL: <PaypalExtraComponent />,
  };

  const paymentOptions = paymentMethods
    .filter(
      ({ enabled, paymentType }) =>
        enabled &&
        !disabledPaymentMethods.includes(paymentType) &&
        isNotEmptyMessage(
          messages[`payment_${paymentType}_title` as keyof typeof messages]
        )
    )
    .map(({ paymentType }) => {
      return {
        selected: paymentType === optimisticSelected,
        component: paymentComponent(paymentType),
        extraComponent: extraComponentLookup[paymentType],
        onClick: () => {
          setOptimisticSelected(paymentType);
          changeMethod({ paymentType });
        },
      };
    });

  return (
    <StyledPaymentSection>
      <SectionWrapper
        withEditButton={false}
        variant="payment"
        withSeparator={false}
        withSubtitle={isDeliveryChannelSelected}
      >
        {!isDeliveryChannelSelected && (
          <StyledText
            show={isNotEmptyMessage(
              messages.payment_missing_delivery_channel_description
            )}
            className="section-wrapper__subtitle"
            $settings={{
              font: settings.section_subtitle_typography,
              text: { color: settings.cart_color_secondary },
            }}
          >
            {messages.payment_missing_delivery_channel_description}
          </StyledText>
        )}
        <SelectionTable items={paymentOptions} />
      </SectionWrapper>
    </StyledPaymentSection>
  );
};

export default PaymentSection;
