import { API, api } from "./api";

export const braintree = api.injectEndpoints({
  endpoints: (builder) => ({
    getClientToken: builder.query<{ token: string }, void>({
      query: () => {
        return API.getClientToken.endpoint();
      },
    }),
  }),
});

export const { useGetClientTokenQuery } = braintree;
