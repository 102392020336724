import useLocalStorage from "../../shared/hooks/useLocalStorage";
import { LanguageType } from "@ecp-redux/dto/themeSettings/translation.types";
import { useRouter } from "next/router";
import {
  useContext,
  createContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { api } from "@ecp-redux/api/api";
import { translation } from "@ecp-redux/api/translation";
import * as currencyApi from "@ecp-redux/api/currency";
import { isPortalSide, safeParseJSON } from "@ecp-boxes/helpers/helpers";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useTheme } from "styled-components";
import Cookies from "js-cookie";
export type LanguageCurrencyContextType = {
  currency: string;
  setCurrency: (currency: string) => void;
  currencyShort: string;
  language: LanguageType;
  setPageBuilderLanguage: (language: LanguageType) => void;
  setPortalLanguage: (language: LanguageType) => void;
  availableLanguages: { name: string; language: LanguageType }[];
  storageLocale: string;
  setStorageLocale: (locale: string) => void;
  languageCode: string;
  currencyMap: Record<string, string>;
};

export const LanguageCurrencyContext =
  createContext<LanguageCurrencyContextType | null>(null);

export function useLanguageCurrencyContext() {
  const context = useContext<LanguageCurrencyContextType | null>(
    LanguageCurrencyContext
  );

  if (!context) {
    throw new Error(
      "useLanguageCurrencyContext must be used under LanguageCurrencyContext.Provider"
    );
  }

  return context;
}

export const LanguageCurrencyContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { locale, replace, asPath, pathname, query } = useRouter();
  const isCurrencyValidationCompleted = useRef(false);
  const { data } = translation.useGetLanguagesQuery();
  const dispatch = useDispatch();
  const {
    advanceSettings: { localeMessages, messages },
  } = useTheme() as IThemeState;
  const { data: currencyData } = currencyApi.useGetCurrencyQuery();

  const { value: storageLanguage, setValues: setStorageLanguage } =
    useLocalStorage("language");
  const { value: storageCurrency, setValues: setStorageCurrency } =
    useLocalStorage("currency");
  const { value: storageLocale, setValues: setStorageLocale } =
    useLocalStorage("locale");

  const defaultCurrency =
    currencyData?.configuredCurrencies?.find((currency) => currency.isDefault)
      ?.currency ??
    currencyData?.configuredCurrencies?.[0]?.currency ??
    "PLN";

  const setPageBuilderLanguage = (language: LanguageType) => {
    setStorageLanguage(language);
    dispatch(api.util.invalidateTags(["LANGUAGE"]));
  };

  const setPortalCurrency = useCallback(
    (currency: string) => {
      setStorageCurrency(currency);
      Cookies.set("curr", currency);
      dispatch(api.util.invalidateTags(["CURRENCY_DROPDOWN"]));
    },
    [dispatch, setStorageCurrency]
  );

  useEffect(() => {
    if (!currencyData || isCurrencyValidationCompleted.current) return;
    const selectedCurrency = currencyData?.configuredCurrencies?.find(
      ({ currency, locale }) =>
        currency === storageCurrency && locale === storageLocale
    );
    if (!selectedCurrency) {
      setPortalCurrency(defaultCurrency);
    }
    isCurrencyValidationCompleted.current = true;
  }, [
    currencyData,
    storageCurrency,
    setPortalCurrency,
    defaultCurrency,
    storageLocale,
  ]);

  const setPortalLanguage = (language: LanguageType) => {
    replace(
      {
        pathname,
        query,
      },
      asPath,
      {
        shallow: true,
        locale:
          language === data?.dictionaryLanguageLabel.language
            ? false
            : language,
      }
    );
  };

  useEffect(() => {
    if (locale && isPortalSide()) {
      setStorageLanguage(locale);
      dispatch(api.util.invalidateTags(["LANGUAGE"]));
    }
  }, [locale]);

  useEffect(() => {
    if (storageLanguage == null && data !== undefined) {
      setPageBuilderLanguage(data.dictionaryLanguageLabel.language);
    }
  }, [data]);

  useEffect(() => {
    if (storageLocale == null) {
      setStorageLocale("pl_PL");
    }
  }, [storageLocale, setStorageLocale]);

  useEffect(() => {
    if (storageCurrency == null || storageCurrency === "default") {
      const defaultCurrency =
        currencyData?.configuredCurrencies?.find((c) => c.isDefault)
          ?.currency ?? "PLN";
      setPortalCurrency(defaultCurrency);
      Cookies.set("curr", defaultCurrency);
    }
  }, [storageCurrency, setPortalCurrency, currencyData]);

  const availableLanguages = useMemo(() => {
    return (
      data?.additionalLanguageLabels.map((language) => ({
        name: language.name,
        language: language.language,
      })) ?? []
    );
  }, [data]);

  const language = useMemo(() => {
    if (storageLanguage === "default")
      return data?.dictionaryLanguageLabel.language ?? "pl_PL";
    return storageLanguage ?? "pl_PL";
  }, [storageLanguage, data]);

  const extractLanguageFromLocale = (locale: string | undefined) => {
    return locale?.split("_")[0] ?? "pl";
  };

  const { language: defaultLanguage } = data?.dictionaryLanguageLabel ?? {
    language: "pl_PL",
  };

  const parsedLanguage = language === "default" ? defaultLanguage : language;

  const currencyShort = isPortalSide()
    ? messages.currencyShort
    : localeMessages[language]?.currencyShort;

  const currencyMap = safeParseJSON<Record<string, string>>(
    messages.currencyShort
  );

  const parsedCurrencyShort =
    typeof currencyShort === "string" && currencyShort.startsWith("{")
      ? JSON.parse(currencyShort)
      : currencyShort;

  return (
    <LanguageCurrencyContext.Provider
      value={{
        language: parsedLanguage,
        languageCode: extractLanguageFromLocale(parsedLanguage),
        currency:
          (storageCurrency === "default" ? defaultCurrency : storageCurrency) ??
          "PLN",
        storageLocale: storageLocale ?? "pl_PL",
        setStorageLocale,
        availableLanguages,
        setPageBuilderLanguage,
        setPortalLanguage,
        setCurrency: setPortalCurrency,
        currencyMap:
          typeof currencyMap === "object" && currencyMap !== null
            ? currencyMap
            : {},
        currencyShort:
          parsedCurrencyShort && storageCurrency
            ? (parsedCurrencyShort[storageCurrency] ?? storageCurrency)
            : storageCurrency,
      }}
    >
      {children}
    </LanguageCurrencyContext.Provider>
  );
};
