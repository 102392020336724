import styled, { css } from "styled-components";

export const StyledAddressSection = styled.div<{
  $extraBottomMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .address-section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${({ $extraBottomMargin }) =>
    $extraBottomMargin &&
    css`
      margin-bottom: 28px;
    `}
`;
