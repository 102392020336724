import { IPortalBox } from "@ecp-pageTypes";
import {
  ILinkContent,
  IButtonContent,
  IImageContent,
} from "../../settingsPatterns/contentPatterns.types";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IElementImage,
  IElementInnerBox,
  IElementTextContent,
  IElementButton,
  IBoxBaseSettings,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ISpacing } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { BoxSliderMessagesScheme } from "./BoxSlider.scheme";
import { z } from "zod";

type IBoxSliderMessages = z.infer<typeof BoxSliderMessagesScheme>;

type IBoxSliderData = GenericBoxData<
  IBoxSliderContent,
  IBoxSliderContentSettings,
  IBoxSliderDisplaySettings,
  IBoxSliderMessages,
  Record<string, never>
>;

export type IBoxSliderProps = IPortalBox<IBoxSliderData> & {
  pathExtraSettings: string;
};

export enum SliderSizeOptions {
  CUSTOM = "CUSTOM",
  SMALL = "350",
  MEDIUM = "500",
  LARGE = "650",
}

export enum SliderPaginationOptions {
  DOTS = "DOTS",
  NUMBERS = "NUMBERS",
  ARROWS = "ARROWS",
  NONE = "NONE",
}

export interface ISlideContent {
  id: string;
  slideUrl: ILinkContent;
  mainButton: IButtonContent & { heading: string };
  secondaryButton: IButtonContent & { heading: string };
  image: IImageContent;
  title: string;
  description: string;
  seoDescription: string;
}

export interface IBoxSliderContent {
  slides: ISlideContent[];
}

export interface IBoxSliderDisplaySettings {
  size: SliderSizeOptions;
  paginationStyle: SliderPaginationOptions;
  padding: ISpacing;
  margin: ISpacing;
  slidesRotate: number[];
  customHeight: number;
}

export interface ISlideContentSettings {
  image: IElementImage;
  box: IElementInnerBox;
  title: IElementTextContent;
  description: IElementTextContent;
  mainButton: IElementButton;
  secondaryButton: IElementButton;
}
export interface IBoxSliderContentSettings {
  slides: ISlideContentSettings[];
}

export type IBoxSliderSettings = IBoxBaseSettings<
  IBoxSliderDisplaySettings & IBoxSliderContentSettings
>;
