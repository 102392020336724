import * as yup from "yup";
import { z, ZodString } from "zod";
import { passwordRegEx, phoneRegex, zipCodeRegex } from "./regex";

export const isRequiredCheckbox = (requiredM: string) =>
  yup.boolean().isTrue(requiredM);

export const isRequiredCheckboxZod = (requiredM: string) =>
  z.boolean().refine((value) => value === true, {
    message: requiredM,
  });

export const isEmail = (
  requiredM: string,
  invalidM: string,
  isRequired = true
) => {
  if (isRequired) {
    return yup.string().required(requiredM).email(invalidM);
  }
  return yup.string().email(invalidM);
};

export const isEmailZod = (requiredM: string, invalidM: string) =>
  z.string({ required_error: requiredM }).email(invalidM);

export const isShortText = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).max(20, invalidM);

export const isRequiredTextByLength = (
  requiredM: string,
  invalidM: string,
  maxLength: number,
  isRequired = true
) => {
  if (isRequired) {
    return yup.string().required(requiredM).max(maxLength, invalidM);
  }
  return yup.string().max(maxLength, invalidM);
};

export const isLongText = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).max(300, invalidM);

export const maxLength = (
  max: number,
  invalidM: string,
  isRequired = false
) => {
  if (isRequired) {
    return yup.string().max(max, invalidM).required(invalidM);
  }
  return yup.string().max(max, invalidM);
};

// Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character

export const isPassword = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).matches(passwordRegEx, invalidM);

export const isConfirmPassword =
  (requiredM: string, matchM: string) => (ref: string) =>
    yup
      .string()
      .required(requiredM)
      .oneOf([yup.ref(ref)], matchM);

export const isPasswordZod = (
  requiredMessage: string,
  invalidMessage: string
): ZodString =>
  z
    .string({ required_error: requiredMessage })
    .min(1, requiredMessage)
    .regex(passwordRegEx, invalidMessage);

const removeSpecialChars = (val: string) => {
  return val.replace(/\D/g, "");
};

export const isPhone = (
  invalidM: string,
  requiredM?: string,
  isRequired = false
) => {
  if (isRequired) {
    return yup
      .string()
      .transform((_, originalValue) => {
        const validatedPhone = removeSpecialChars(originalValue);
        return validatedPhone;
      })
      .matches(phoneRegex, { message: invalidM, excludeEmptyString: true })
      .required(requiredM);
  }
  return yup
    .string()
    .transform((_, originalValue) => {
      const validatedPhone = removeSpecialChars(originalValue);
      return validatedPhone;
    })
    .matches(phoneRegex, { message: invalidM, excludeEmptyString: true });
};

export const isPhoneZod = (invalidM: string) =>
  z
    .string({ required_error: invalidM })
    .transform((originalValue) => removeSpecialChars(originalValue))
    .refine((value) => phoneRegex.test(value), { message: invalidM });

export const isPhoneB2B = (invalidM: string, requiredM: string) =>
  yup
    .string()
    .transform((value, originalValue) => {
      const validatedPhone = removeSpecialChars(originalValue);
      return validatedPhone;
    })
    .matches(phoneRegex, { message: invalidM, excludeEmptyString: true })
    .required(requiredM);

export const isZipCode = (invalidM: string, requiredM: string) =>
  yup
    .string()
    .matches(zipCodeRegex, { message: invalidM, excludeEmptyString: true })
    .required(requiredM);

export const isNameOrSurname = () => yup.string().trim().max(20).notRequired();

export const isNameOrSurnameB2B = (requiredM: string) =>
  yup.string().trim().max(20).required(requiredM);

const nipValidation = (nip: string) => {
  if (typeof nip !== "string") return "";

  // eslint-disable-next-line no-useless-escape
  nip = nip.replace(/[\ \-]/gi, "");

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  if (sum % 11 === controlNumber) {
    return nip;
  }
  return "";
};

export const isNIP = (invalidM: string, isRequired = true) => {
  if (isRequired) {
    return yup
      .string()
      .transform((originalValue) => {
        if (!originalValue) return "";
        return originalValue.replace(/[\ \-]/gi, "");
      })
      .test("nip-validation", invalidM, (value) => {
        if (!value) return true;
        return nipValidation(value) !== "";
      })
      .matches(/^\d{10}$/, { message: invalidM, excludeEmptyString: true })
      .required(invalidM);
  }
  return yup
    .string()
    .transform((originalValue) => {
      if (!originalValue) return "";
      return originalValue.replace(/[\ \-]/gi, "");
    })
    .test("nip-validation", invalidM, (value) => {
      if (!value) return true;
      return nipValidation(value) !== "";
    })
    .matches(/^\d{10}$/, { message: invalidM, excludeEmptyString: true });
};

export const isRequiredString = (invalidM: string) => {
  return yup.string().trim().required(invalidM);
};

export const isString = () => {
  return yup.string().trim();
};
