import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../BoxCartStepTwoExtended/BoxCartStepTwoExtended.types";
import { TGlobalDropdownItem } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";
import ThemeStyledDropdown from "@ecp-boxes/shared/styleElements/StyledDropdown/ThemeStyledDropdown";
import { useDispatch } from "react-redux";
import { api } from "@ecp-redux/api/api";
import * as currencyApi from "@ecp-redux/api/currency";
import { useFormContext } from "react-hook-form";
import { BillingPostalAddressForm } from "./BillingPostalAddressFormExtended";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { useEffect } from "react";
const CountryDropdown = ({ isPostal }: { isPostal: boolean }) => {
  const { settings, messages } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { languageCode, setStorageLocale, storageLocale } =
    useLanguageCurrencyContext();

  const { trigger, setValue, watch } =
    useFormContext<BillingPostalAddressForm>();
  const country = watch("country");
  const dispatch = useDispatch();
  const { data: currencyData } = currencyApi.useGetCurrencyQuery();

  useEffect(() => {
    setValue("country", isPostal ? storageLocale : country);
    trigger("country");
  }, [isPostal, storageLocale]);

  const handleSelectCountry = (itemId: string) => {
    if (isPostal) {
      setStorageLocale(itemId);
      dispatch(api.util.invalidateTags(["LANGUAGE"]));
    }
    setValue("country", itemId);
    trigger("country");
  };

  function getCountryName(locale: string, lang: string): string {
    let countryCode = "";
    if (locale.includes("_")) {
      countryCode = locale.split("_")[1];
    } else {
      countryCode = locale;
    }
    const countryNames = new Intl.DisplayNames([lang], {
      type: "region",
    });
    return countryNames.of(countryCode) || countryCode;
  }

  const items = currencyData?.configuredCurrencies?.map((currency) => ({
    id: currency.locale,
    label: `${getCountryName(currency.locale, languageCode)}`,
  }));

  return (
    <div>
      <StyledText
        className="country-dropdown__label"
        $settings={{
          text: {
            color: settings.form_country_color,
          },
          font: settings.form_country_typography,
        }}
      >
        {messages.form_country_label}
      </StyledText>
      <ThemeStyledDropdown
        items={items ?? []}
        selectedItemId={isPostal ? storageLocale : country}
        onClickItem={handleSelectCountry}
        className="country-dropdown"
        style={{
          custom: {} as TGlobalDropdownItem,
          id: settings.country_dropdown_style,
        }}
      />
    </div>
  );
};
export default CountryDropdown;
