import {
  IOpeningHour,
  IStoreLocator,
} from "@ecp-redux/dto/storesLocator.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../global/components/ImageWrapper/ImageWrapper";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import htmlToReact from "../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { IBoxMyAccountMyAddressListProps } from "../../BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import { IBoxCartStepTwoProps } from "../../Cart/BoxCartStepTwo/BoxCartStepTwo.types";
import { StyledStore } from "../BoxStoreLocator.styled";
import { IBoxStoreLocatorProps } from "../BoxStoreLocator.types";

export interface IStoreProps {
  isPhoto: boolean;
  store: IStoreLocator;
  onSelectAddressButtonClick?: (storeCode: string) => void;
}

const Store: React.FC<IStoreProps> = ({
  isPhoto,
  store,
  onSelectAddressButtonClick,
}) => {
  const { messages, settings } = useBoxContext<
    | IBoxStoreLocatorProps
    | IBoxMyAccountMyAddressListProps
    | IBoxCartStepTwoProps
  >();

  const hasPhoto = (store: IStoreLocator) => {
    if (!store.photoLocation) {
      return `${
        settings.storelocator_no_image_plug.startsWith("/")
          ? process.env["NEXT_PUBLIC_MINIO_URL"]
          : ""
      }${settings.storelocator_no_image_plug}`;
    } else {
      return store.photoLocation;
    }
  };

  const dayToMessage = (days: string): string => {
    const daysNames = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];
    const daysRegex = new RegExp(daysNames.join("|"), "g");

    return days.replace(daysRegex, (match) => {
      switch (match) {
        case "MONDAY":
          return messages.store_locator_open_hours_monday;
        case "TUESDAY":
          return messages.store_locator_open_hours_tuesday;
        case "WEDNESDAY":
          return messages.store_locator_open_hours_wednesday;
        case "THURSDAY":
          return messages.store_locator_open_hours_thursday;
        case "FRIDAY":
          return messages.store_locator_open_hours_friday;
        case "SATURDAY":
          return messages.store_locator_open_hours_saturday;
        case "SUNDAY":
          return messages.store_locator_open_hours_sunday;
        default:
          return match;
      }
    });
    return "";
  };

  return (
    <StyledStore
      className="store-container"
      width={+settings.storelocator_image_width}
    >
      {isPhoto && (
        <div data-testid="store-photo" className="store-container__store-photo">
          <ImageWrapper
            imageUrl={hasPhoto(store)}
            seoDescription="Store image"
            imageFit={true}
            imageAlignment={{
              horizontal: AlignmentHorizontalOption.CENTER,
              vertical: AlignmentVerticalOption.CENTER,
            }}
            width={+settings.storelocator_image_width}
          />
        </div>
      )}
      <div className="store-container__store-info-container">
        <StyledText
          className="store-container__store-info-container__store-name"
          $settings={{
            font: settings.storelocator_store_adress_typography,
            text: { color: settings.storelocator_store_adress_color },
            show: true,
          }}
        >
          {htmlToReact(store.name)}
        </StyledText>
        <StyledText
          className="store-container__store-info-container__store-adress"
          $settings={{
            font: settings.storelocator_store_adress_typography,
            text: { color: settings.storelocator_store_adress_color },
            show: true,
          }}
        >
          {`${store.street} 
              ${store.streetNumber} 
              ${store.zipCode} 
              ${store.city}`}
        </StyledText>
        {store.openingHours.length > 0 && (
          <div className="store-container__store-info-container__store-open-hours">
            <StyledText
              className="store-container__store-info-container__store-open-hours__header"
              $settings={{
                font: settings.storelocator_store_open_typography,
                text: {
                  color: settings.storelocator_store_open_color,
                },
                show: true,
              }}
              show={isNotEmptyMessage(messages.store_locator_open_hours)}
            >
              {messages.store_locator_open_hours}
            </StyledText>
            {store.openingHours.map((item: IOpeningHour) => (
              <div
                key={item.days}
                className="store-container__store-info-container__store-open-hours__days-container"
              >
                <div className="store-container__store-info-container__store-open-hours__days-container__days-label__main">
                  <StyledText
                    className="store-container__store-info-container__store-open-hours__days-container__days-label__main__text"
                    $settings={{
                      font: settings.storelocator_store_open_typography,
                      text: {
                        color: settings.storelocator_store_open_color,
                      },
                      show: true,
                    }}
                  >
                    {dayToMessage(item.days)}
                  </StyledText>
                </div>
                <div className="store-container__store-info-container__store-open-hours__days-container__hours-label__main">
                  <StyledText
                    className="store-container__store-info-container__store-open-hours__days-container__hours-label__main__text"
                    $settings={{
                      font: settings.storelocator_store_open_typography,
                      text: {
                        color: settings.storelocator_store_open_color,
                      },
                      show: true,
                    }}
                  >
                    {`${item.hours || "-"}`}
                  </StyledText>
                </div>
              </div>
            ))}
          </div>
        )}
        <LinkWrapper
          href={store.webPageUrl}
          openInNewTab={true}
          linkStyle={settings.storelocator_store_www_color}
          fontStyle={settings.storelocator_store_www_typography}
        >
          {store.webPageUrl}
        </LinkWrapper>
        <StyledText
          className="store-container__store-info-container__store-email"
          $settings={{
            font: settings.storelocator_store_email_typography,
            text: {
              color: settings.storelocator_store_email_color,
            },
            show: true,
          }}
        >
          {store.email}
        </StyledText>

        {onSelectAddressButtonClick && (
          <div>
            <StyledButton
              className="store-container__store-info-container__store-select-address-button"
              renderAs="button"
              onClick={() =>
                onSelectAddressButtonClick &&
                onSelectAddressButtonClick(store.code)
              }
              $settings={settings.storelocator_select_address_button}
              show={
                isNotEmptyMessage(messages.storelocator_select_address_label) &&
                onSelectAddressButtonClick
              }
            >
              {messages.storelocator_select_address_label}
            </StyledButton>
          </div>
        )}
      </div>
    </StyledStore>
  );
};

export default Store;
