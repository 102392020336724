import {
  BreadcrumbSeparatorType,
  BreadcrumbTypes,
} from "../../settingsPatterns/boxes/BoxBreadcrumb/StyledBreadcrumb.types";
import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import {
  createLinkStyleSettingScheme,
  createTypoStyleSettingScheme,
} from "../../settingsSchemes/settingsSchemes.utils";
import { ColorReadoutValueSchemeBlackDefault } from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { z } from "zod";

export const BoxBreadcrumbDisplayScheme = z.object({
  breadcrumbs: z
    .object({
      text: createTypoStyleSettingScheme("typo$$1"),
      link: createLinkStyleSettingScheme("link$$1"),
    })
    .default({
      text: createTypoStyleSettingScheme("typo$$1").parse({}),
      link: createLinkStyleSettingScheme("link$$1").parse({}),
    })
    .catch({
      text: createTypoStyleSettingScheme("typo$$1").parse({}),
      link: createLinkStyleSettingScheme("link$$1").parse({}),
    }),
  separator: z
    .object({
      type: z
        .nativeEnum(BreadcrumbSeparatorType)
        .default(BreadcrumbSeparatorType.BACKSLASH)
        .catch(BreadcrumbSeparatorType.BACKSLASH),
      text: createTypoStyleSettingScheme("typo$$3")
        .default(createTypoStyleSettingScheme("typo$$3").parse({}))
        .catch(createTypoStyleSettingScheme("typo$$3").parse({})),
      color: ColorReadoutValueSchemeBlackDefault,
    })
    .default({
      type: BreadcrumbSeparatorType.BACKSLASH,
      text: createTypoStyleSettingScheme("typo$$3").parse({}),
      color: ColorReadoutValueSchemeBlackDefault.parse({}),
    }),
});

export const BoxBreadcrumbContentScheme = z
  .object({
    breadcrumbs: z.array(z.any()).default([]).catch([]),
    type: z
      .nativeEnum(BreadcrumbTypes)
      .default(BreadcrumbTypes.AUTO)
      .catch(BreadcrumbTypes.AUTO),
    homeBreadcrumb: z
      .object({
        link: LinkContentScheme.default({
          showLinkOptions: true,
          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          goToRowSetting: {
            rowId: null,
          },
          goToPageSetting: { link: "/", openInNewTab: true },
          forceOrderInsideStack: undefined,
          openPopupSetting: undefined,
          closePopupSetting: undefined,
        }),
      })
      .default({
        link: {
          showLinkOptions: true,
          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          goToRowSetting: {
            rowId: null,
          },
          goToPageSetting: { link: "/", openInNewTab: true },
          forceOrderInsideStack: undefined,
          openPopupSetting: undefined,
          closePopupSetting: undefined,
        },
      })
      .catch({
        link: {
          showLinkOptions: true,
          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          goToRowSetting: {
            rowId: null,
          },
          goToPageSetting: { link: "/", openInNewTab: true },
          forceOrderInsideStack: undefined,
          openPopupSetting: undefined,
          closePopupSetting: undefined,
        },
      }),
    searchResultBreadcrumb: z.string().default("").catch(""),
  })
  .default({
    breadcrumbs: [],
    type: BreadcrumbTypes.AUTO,
    homeBreadcrumb: {
      link: {
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: undefined,
        goToPageSetting: { link: "/", openInNewTab: true },
        forceOrderInsideStack: undefined,
        openPopupSetting: undefined,
        closePopupSetting: undefined,
      },
    },
    searchResultBreadcrumb: "",
  })
  .catch({
    breadcrumbs: [],
    type: BreadcrumbTypes.AUTO,
    homeBreadcrumb: {
      link: {
        showLinkOptions: true,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: undefined,
        goToPageSetting: { link: "/", openInNewTab: true },
        forceOrderInsideStack: undefined,
        openPopupSetting: undefined,
        closePopupSetting: undefined,
      },
    },
    searchResultBreadcrumb: "",
  });

export const BoxBreadcrumbMessagesScheme = z.object({
  box_breadcrumb_content_home_breadcrumb_heading:
    themeScheme.MessageScheme.default(""),
});
