import styled, { css } from "styled-components";

export const StyledNotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .headerText {
    margin-top: 39px;
    margin-bottom: 17px;
  }
`;

export const StyledNotificationsForm = styled.form(() => {
  return css`
    width: 100%;
    margin-bottom: 3px;
    .notificationsTypeWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 40px;
      margin-bottom: 11px;
    }
    .notificationsType,
    .singleCheckbox {
      width: 90px;
      display: flex;
      justify-content: center;
    }
    .singleConsentWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .consentCheckboxWrapper {
      display: flex;
      align-items: center;
      gap: 40px;
    }
    .consentDefinition {
      display: flex;
      align-items: center;
    }
    .singleCheckbox[type="checkbox"]:before {
    }
    .underline {
      color: #efefef;
    }
    .submitButton {
      margin-top: 39px;
      padding: 12px 32px;
    }
  `;
});
