import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { IBoxNotificationsProps } from "./BoxNotifications.types";
import {
  StyledNotificationsForm,
  StyledNotificationsWrapper,
} from "./BoxNotifications.styled";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { Consent } from "@ecp-redux/dto/notifications.type";
import { PopupActionsList } from "../../../../src/lib/settingsPatterns/contentPatterns.types";
import { useRouter } from "next/router";
import { notifications } from "@ecp-redux/api/notifications";
import { useLinkContentActions } from "@ecp-boxes/shared/hooks/useLinkContentAction";
import { useRowPopupContext } from "@ecp-boxes/structure/Contexts/RowPopupContext";

const BoxNotifications: React.FC<IBoxNotificationsProps> = ({
  messages,
  settings,
}) => {
  const router = useRouter();
  const { control, setValue, handleSubmit } = useForm<{
    consents: Consent[];
  }>({
    defaultValues: { consents: [] },
  });

  const [initialConsents, setInitialConsents] = useState<Consent[]>([]);
  const [currentConsents, setCurrentConsents] = useState<Consent[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  const { data } = notifications.useGetNotificationsQuery();

  const { openPopup } = useLinkContentActions();
  const { addPopupAction } = useRowPopupContext();

  useEffect(() => {
    if (data) {
      setInitialConsents(data.consents);
      setCurrentConsents(data.consents);
      data.consents.forEach((consent, index) => {
        setValue(`consents.${index}.consentId`, consent.consentId);
        setValue(`consents.${index}.platform`, consent.platform);
        setValue(`consents.${index}.email`, consent.email);
        setValue(`consents.${index}.sms`, consent.sms);
      });
    }
  }, [data, setValue]);

  const handleCheckboxChange = (
    index: number,
    field: keyof Consent,
    value: boolean
  ) => {
    const updatedConsents = [...currentConsents];
    updatedConsents[index] = {
      ...updatedConsents[index],
      [field]: value,
    };
    setCurrentConsents(updatedConsents);
    detectChanges(updatedConsents);
  };

  const detectChanges = (updatedConsents: Consent[]) => {
    const changesDetected = updatedConsents.some((currentConsent, index) => {
      const initialConsent = initialConsents[index];
      return (
        currentConsent.platform !== initialConsent.platform ||
        currentConsent.email !== initialConsent.email ||
        currentConsent.sms !== initialConsent.sms
      );
    });
    setHasChanges(changesDetected);
  };

  const handleConfirmNavigation = (url: string) => () => {
    setHasChanges(false);
    router.push(url);
  };

  const onSubmit = () => {
    setInitialConsents([...currentConsents]);
    setHasChanges(false);
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (hasChanges) {
        router.events.emit("routeChangeError");
        addPopupAction({
          actionType: PopupActionsList.CONFIRM,
          actionFunction: handleConfirmNavigation(url),
        });
        openPopup(settings.popup_redirect_confirmation);
        throw `Navigation to ${url} was blocked due to unsaved changes.`;
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [hasChanges, router.events]);

  return (
    <StyledNotificationsWrapper>
      <StyledText
        className="headerText"
        $settings={{
          font: settings.notifications_header_typo,
          text: { color: settings.notifications_header_color },
        }}
      >
        {messages.notifications_header_text}
      </StyledText>

      <StyledNotificationsForm onSubmit={handleSubmit(onSubmit)}>
        <div className="notificationsTypeWrapper">
          <StyledText
            className="notificationsType"
            $settings={{
              font: settings.notifications_type_typo,
              text: { color: settings.notifications_type_color },
            }}
          >
            {messages.notifications_type_platform}
          </StyledText>
          <StyledText
            className="notificationsType"
            $settings={{
              font: settings.notifications_type_typo,
              text: { color: settings.notifications_type_color },
            }}
          >
            {messages.notifications_type_email}
          </StyledText>
          <StyledText
            className="notificationsType"
            $settings={{
              font: settings.notifications_type_typo,
              text: { color: settings.notifications_type_color },
            }}
          >
            {messages.notifications_type_text_message}
          </StyledText>
        </div>
        <hr className="underline" />
        {currentConsents.map((consent, index) => (
          <div key={consent.consentId}>
            <div className="singleConsentWrapper">
              <div className="consentDefinition">
                <p>{consent.definition}</p>
              </div>
              <div className="consentCheckboxWrapper">
                <Controller
                  name={`consents.${index}.platform`}
                  control={control}
                  render={() => (
                    <input
                      className="singleCheckbox"
                      type="checkbox"
                      checked={consent.platform}
                      onChange={(e) =>
                        handleCheckboxChange(
                          index,
                          "platform",
                          e.target.checked
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name={`consents.${index}.email`}
                  control={control}
                  render={() => (
                    <input
                      className="singleCheckbox"
                      type="checkbox"
                      checked={consent.email}
                      onChange={(e) =>
                        handleCheckboxChange(index, "email", e.target.checked)
                      }
                    />
                  )}
                />
                <Controller
                  name={`consents.${index}.sms`}
                  control={control}
                  render={() => (
                    <input
                      className="singleCheckbox"
                      type="checkbox"
                      checked={consent.sms}
                      onChange={(e) =>
                        handleCheckboxChange(index, "sms", e.target.checked)
                      }
                    />
                  )}
                />
              </div>
            </div>
            <hr className="underline" />
          </div>
        ))}
        <StyledButton
          className="submitButton"
          renderAs="button"
          type="submit"
          $settings={settings.notifications_button_typo}
          show={hasChanges}
        >
          {messages.notifications_button_text}
        </StyledButton>
      </StyledNotificationsForm>
    </StyledNotificationsWrapper>
  );
};

export default BoxNotifications;
