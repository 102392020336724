import { IGetCustomerData, INewDataToSend } from "../dto/myData.types";
import { API, api } from "./api";

export const myData = api
  .enhanceEndpoints({ addTagTypes: ["MYDATA"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCustomerData: builder.query<IGetCustomerData, void>({
        query: () => {
          return API.getCustomerData.endpoint();
        },
        providesTags: ["MYDATA"],
      }),
      getCustomerDataWithNoValidation: builder.query<IGetCustomerData, void>({
        query: () => {
          return API.getCustomerDataWithNoValidation.endpoint();
        },
        providesTags: ["MYDATA"],
      }),

      putMyDataForm: builder.mutation<void, INewDataToSend>({
        query: (body) => {
          return {
            url: API.putMyDataForm.endpoint(),
            method: "PUT",
            credentials: "include",
            mode: "cors",
            body,
          };
        },
        invalidatesTags: ["MYDATA"],
      }),
    }),
  });

export const {
  useGetCustomerDataQuery,
  usePutMyDataFormMutation,
  useLazyGetCustomerDataQuery,
  useLazyGetCustomerDataWithNoValidationQuery,
  useGetCustomerDataWithNoValidationQuery,
} = myData;

export const { getCustomerData, putMyDataForm } = myData.endpoints;
