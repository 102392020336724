import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";
import braintree, {
  ApplePay as ApplePayType,
  ApplePaySession,
} from "braintree-web";

import * as braintreeAPI from "@ecp-redux/api/braintree";
import { useEffect, useImperativeHandle, useState } from "react";
import CartLoader from "../shared/CartLoader";
import { ApplePayContainer } from "./ApplePay.styled";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import { TPaymentComponentRef } from "../sections/PaymentSection";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

declare global {
  interface Window {
    ApplePaySession?: typeof ApplePaySession;
  }
}

const ApplePay = ({
  applePayRef,
}: {
  applePayRef: React.RefObject<TPaymentComponentRef>;
}) => {
  const [braintreeClient, setBraintreeClient] = useState<ApplePayType | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { setSectionValidation, amountToPay } = useBoxCartStepTwoExtended();
  const { data: clientToken, isLoading: isTokenLoading } =
    braintreeAPI.useGetClientTokenQuery(undefined, {
      skip: !isPortalSide(),
    });
  const { currency, storageLocale } = useLanguageCurrencyContext();

  const [, countryCode] = storageLocale.split("_");

  const isProcessing = isTokenLoading || isLoading;

  useEffect(() => {
    if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
      setErrorMessage(messages.error_apple_pay_not_supported);
      setSectionValidation("payment", false);
      return;
    }
    if (!clientToken?.token) {
      setSectionValidation("payment", false);
      return;
    }
    const initializeBraintree = async () => {
      setIsLoading(true);
      try {
        const client = await braintree.client.create({
          authorization: clientToken.token,
        });

        const braintreeClient = await braintree.applePay.create({
          client: client,
        });

        setBraintreeClient(braintreeClient);
        setSectionValidation("payment", true);
      } catch (error) {
        console.error("Error initializing Braintree:", error);
        setSectionValidation("payment", false);
      } finally {
        setIsLoading(false);
      }
    };

    initializeBraintree();
  }, [clientToken]);

  useEffect(() => {
    return () => {
      braintreeClient?.teardown();
    };
  }, [braintreeClient]);

  useImperativeHandle(applePayRef, () => ({
    generateNonce: () => {
      return new Promise<{ nonce: string | null; hasError: boolean }>(
        (resolve, reject) => {
          if (!braintreeClient || !window.ApplePaySession) {
            return reject({ nonce: null, hasError: true });
          }

          try {
            if (typeof amountToPay !== "number") {
              return reject({ nonce: null, hasError: true });
            }
            setIsLoading(true);
            const paymentRequest = {
              total: {
                label: settings.braintree_config_store_name,
                amount: amountToPay.toFixed(2),
              },
              requiredBillingContactFields: ["postalAddress"],
              countryCode,
              currencyCode: currency,
              supportedNetworks: ["visa", "masterCard", "amex"],
              merchantCapabilities: ["supports3DS"],
            };

            const session =
              braintreeClient.createPaymentRequest(paymentRequest);

            const applePaySession = new window.ApplePaySession(3, session);

            applePaySession.onvalidatemerchant = async (event) => {
              try {
                const merchantValidation =
                  await braintreeClient.performValidation({
                    validationURL: event.validationURL,
                    displayName: settings.braintree_config_store_name,
                  });
                applePaySession.completeMerchantValidation(merchantValidation);
              } catch (err) {
                setIsLoading(true);
                console.error("Merchant validation failed:", err);
                applePaySession.abort();
                return reject({ nonce: null, hasError: true });
              }
            };

            applePaySession.onpaymentauthorized = async (event) => {
              try {
                const payload = await braintreeClient.tokenize({
                  token: event.payment.token,
                });

                applePaySession.completePayment(
                  window.ApplePaySession?.STATUS_SUCCESS ??
                    ApplePaySession.STATUS_SUCCESS
                );
                resolve({ nonce: payload.nonce, hasError: false });
              } catch (err) {
                setIsLoading(false);
                console.error("Payment processing failed:", err);
                applePaySession.completePayment(
                  window.ApplePaySession?.STATUS_FAILURE ??
                    ApplePaySession.STATUS_FAILURE
                );
                return reject({ nonce: null, hasError: true });
              }
            };

            applePaySession.oncancel = () => {
              setIsLoading(false);
              return reject({ nonce: null, hasError: true });
            };

            applePaySession.begin();
          } catch (error) {
            console.error("Error processing Apple Pay payment:", error);
            setIsLoading(false);
            return reject({ nonce: null, hasError: true });
          } finally {
            setIsLoading(false);
          }
        }
      );
    },
  }));

  return (
    <ApplePayContainer
      className="payment-container"
      $isLoading={isTokenLoading || isLoading}
    >
      {!isProcessing && (
        <StyledText
          $settings={{
            text: { color: settings.cart_color_primary },
            font: settings.section_subtext_typography,
          }}
        >
          {errorMessage ? errorMessage : messages.payment_APPLE_PAY_description}
        </StyledText>
      )}
      {(isTokenLoading || isLoading) && <CartLoader />}
    </ApplePayContainer>
  );
};
export default ApplePay;
