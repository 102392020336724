import { isPortalSide } from "@ecp-boxes/helpers/helpers";
import braintree from "braintree-web";

import * as braintreeAPI from "@ecp-redux/api/braintree";
import { useEffect, useState } from "react";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";
import { PaypalContainer } from "./Paypal.styled";
import useInert from "@ecp-boxes/shared/hooks/useInert";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import {
  convertButtonIdToValues,
  convertColorIdToHex,
} from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  AddressPostalBilling,
  IBillingPostalAddAddressExtendedRequest,
} from "@ecp-redux/dto/cartAddresses.type";
const Paypal = ({
  handlePurchase,
  paymentAutofillAddress,
}: {
  handlePurchase: (nonce?: string) => void;
  paymentAutofillAddress:
    | AddressPostalBilling
    | IBillingPostalAddAddressExtendedRequest
    | null;
}) => {
  const { amountToPay, isEverySectionValid } = useBoxCartStepTwoExtended();
  const theme = useTheme() as IThemeState;
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const [paypalInstance, setPaypalInstance] =
    useState<braintree.PayPalCheckout | null>(null);
  const { setSectionValidation } = useBoxCartStepTwoExtended();
  const { data: clientToken } = braintreeAPI.useGetClientTokenQuery(undefined, {
    skip: !isPortalSide(),
  });
  const { currency } = useLanguageCurrencyContext();
  const paypalContainerRef = useInert<HTMLDivElement>(!isEverySectionValid);

  useEffect(() => {
    if (!clientToken?.token || typeof amountToPay !== "number") return;

    let paypalRendered = false;

    const initializeBraintree = async () => {
      try {
        const client = await braintree.client.create({
          authorization: clientToken.token,
        });

        const paypalCheckoutInstance = await braintree.paypalCheckout.create({
          client,
        });

        setPaypalInstance(paypalCheckoutInstance);

        await paypalCheckoutInstance.loadPayPalSDK({
          currency: currency,
          intent: "authorize",
        });

        if (!paypalRendered) {
          window.paypal
            .Buttons({
              //@ts-expect-error paypal types are not updated
              fundingSource: window.paypal.FUNDING.PAYPAL,
              createOrder: function () {
                return paypalCheckoutInstance.createPayment({
                  //@ts-expect-error paypal types are not updated
                  flow: "checkout",
                  amount: amountToPay.toFixed(2),
                  currency: currency,
                  enableShippingAddress: true,
                  shippingAddressEditable: true,
                  shippingAddressOverride: {
                    recipientName: paymentAutofillAddress?.firstName ?? "",
                    city: paymentAutofillAddress?.city ?? "",
                    countryCode: paymentAutofillAddress?.country ?? "",
                    postalCode: paymentAutofillAddress?.zipCode ?? "",
                    line1: "",
                    phone: paymentAutofillAddress?.phone ?? "",
                    state: "",
                  },
                });
              },
              onApprove: async function (data) {
                const payload =
                  await paypalCheckoutInstance.tokenizePayment(data);
                handlePurchase(payload.nonce);
                return payload;
              },
              onCancel: function () {
                console.log("PayPal payment cancelled");
              },
              onError: function (err) {
                console.error("PayPal error", err);
              },
            })
            .render("#paypal-button");
          paypalRendered = true;
        }

        setSectionValidation("payment", true);
      } catch (error) {
        console.error("Error initializing Braintree PayPal:", error);
      }
    };

    initializeBraintree();

    return () => {
      paypalInstance?.teardown();
      setPaypalInstance(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken, currency, amountToPay]);

  const {
    background: { disabledColor },
  } = convertButtonIdToValues(
    settings.basket_step_2_3_button_summary,
    theme?.globalObjects?.buttons
  );

  return (
    <PaypalContainer
      $background={convertColorIdToHex(disabledColor, theme?.colorPalette)}
      ref={paypalContainerRef}
      className="payment-container"
      $isEverySectionValid={isEverySectionValid}
    >
      <div id="paypal-button" />
    </PaypalContainer>
  );
};

export default Paypal;
