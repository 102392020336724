import {
  BoxButtonMessagesScheme,
  BoxButtonSettingsScheme,
} from "@ecp-boxes/settingsPatterns/boxes/BoxButton/StyledButton.types";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { z } from "zod";
import {
  BoxAddToCartMessagesScheme,
  BoxAddToCartSettingsScheme,
} from "./BoxAddToCart/BoxAddToCart.scheme";
import {
  IBoxAddToCartQuantityB2BMessagesScheme,
  IBoxAddToCartQuantityB2BSettingsScheme,
} from "./BoxAddToCartB2B/BoxAddToCartQuantityB2B.scheme";
import {
  IBoxAddToCartQuantityContentMessagesScheme,
  IBoxAddToCartQuantityContentSettingsScheme,
} from "./BoxAddToCartQuantity/BoxAddToCartQuantity.scheme";
import {
  IBoxArticleContentMessagesScheme,
  IBoxArticleContentSettingsScheme,
} from "./BoxArticleContent/BoxArticleContent.scheme";
import { IBoxB2BAddToCartUnitSettingsScheme } from "./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit.scheme";
import {
  BoxCompareContentScheme,
  BoxCompareContentSettingsScheme,
  BoxCompareDisplaySettingsScheme,
  IBoxCompareMessagesScheme,
  IBoxCompareSettingsScheme,
} from "./BoxCompare/BoxCompare.scheme";
import {
  IBoxCompareHeaderMessagesScheme,
  IBoxCompareHeaderSettingsScheme,
} from "./BoxCompareHeader/BoxCompareHeader.scheme";
import {
  IBoxContactFormMessagesScheme,
  IBoxContactFormSettingsScheme,
} from "./BoxContactForm/BoxContactForm.scheme";
import {
  IBoxContractorsMessageScheme,
  IBoxContractorsSettingsScheme,
} from "./BoxContractors/BoxContractors.scheme";
import {
  IBoxDropdownMessagesScheme,
  IBoxDropdownSettingsScheme,
} from "./BoxDropdown/BoxDropdown.scheme";
import {
  IBoxLoginMessagesScheme,
  IBoxLoginSettingsScheme,
} from "./BoxLogin/BoxLogin.scheme";
import {
  IBoxMiniCartMessagesScheme,
  IBoxMiniCartSettingsScheme,
} from "./BoxMiniCart/BoxMiniCart.scheme";
import {
  IBoxMiniCartB2BMessagesScheme,
  IBoxMiniCartB2BSettingsScheme,
} from "./BoxMiniCartB2B/BoxMiniCartB2B.scheme";
import {
  IBoxMultiMenuMessagesScheme,
  IBoxMultiMenuSettingsScheme,
} from "./BoxMultiMenu/BoxMultiMenu.scheme";
import {
  IBoxMyAccountConsentsMessagesScheme,
  IBoxMyAccountConsentsSettingsScheme,
} from "./BoxMyAccountConsents/BoxMyAccountConsents.scheme";
import {
  IBoxMyAccountHeaderMessagesScheme,
  IBoxMyAccountHeaderSettingsScheme,
} from "./BoxMyAccountHeader/BoxMyAccountHeader.scheme";
import {
  IBoxMyAccountMyAddressListMessagesScheme,
  IBoxMyAccountMyAddressListSettingsScheme,
} from "./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.scheme";
import {
  IBoxMyAccountMyDataMessagesScheme,
  IBoxMyAccountMyDataSettingsScheme,
} from "./BoxMyAccountMyData/BoxMyAccountMyData.scheme";
import {
  IBoxMyAccountMyOrdersMessagesScheme,
  ITBoxMyAccountMyOrdersSettingsScheme,
} from "./BoxMyAccountMyOrders/BoxMyAccountMyOrders.scheme";
import {
  IBoxMyAccountMyOrdersDetailsMessagesScheme,
  IBoxMyAccountMyOrdersDetailsSettingsScheme,
} from "./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails.scheme";
import {
  IBoxNewsletterMessagesScheme,
  IBoxNewsletterSettingsScheme,
} from "./BoxNewsletter/BoxNewsletter.scheme";
import {
  IBoxPhotoGalleryMessagesScheme,
  IBoxPhotoGallerySettingsScheme,
} from "./BoxPhotoGallery/BoxPhotoGallery.scheme";
import {
  IBoxProductAttributesMessagesScheme,
  IBoxProductAttributesSettingsScheme,
} from "./BoxProductAttributes/BoxProductAttributes.scheme";
import {
  IBoxProductSliderMessagesScheme,
  IBoxProductSliderSettingsScheme,
} from "./BoxProductSlider/BoxProductSlider.scheme";
import {
  IBoxRegisterMessagesScheme,
  IBoxRegisterSettingsScheme,
} from "./BoxRegister/BoxRegister.scheme";
import {
  IBoxRemindMessagesScheme,
  IBoxRemindSettingsScheme,
} from "./BoxRemind/BoxRemind.scheme";
import {
  IBoxSearchBarMessagesScheme,
  IBoxSearchBarSettingsScheme,
} from "./BoxSearchBar/BoxSearchBar.scheme";
import {
  BoxSearchFiltersContentScheme,
  BoxSearchFiltersContentSettingsScheme,
  BoxSearchFiltersDisplaySettingsScheme,
  IBoxSearchFiltersMessagesScheme,
  IBoxSearchFiltersSettingsScheme,
} from "./BoxSearchFilters/BoxSearchFilters.scheme";
import {
  IBoxSearchResultsMessagesScheme,
  IBoxSearchResultsSettingsScheme,
} from "./BoxSearchResults/BoxSearchResults.scheme";
import {
  BoxSearchResultsB2BContentScheme,
  BoxSearchResultsB2BContentSettingsScheme,
  BoxSearchResultsB2BDisplaySettingsScheme,
  BoxSearchResultsB2BMessagesScheme,
  BoxSearchResultsB2BSettingsScheme,
} from "./BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import {
  BoxStoreLocatorMessagesScheme,
  BoxStoreLocatorSettingsScheme,
} from "./BoxStoreLocator/BoxStoreLocator.scheme";
import {
  BoxTableDataContentScheme,
  BoxTableDataContentSettingsScheme,
  BoxTableDataDisplaySettingsScheme,
  BoxTableDataMessagesScheme,
  BoxTableDataSettingsScheme,
} from "./BoxTableData/BoxTableData.types";
import {
  BoxTextContentScheme,
  BoxTextContentSettingsScheme,
  BoxTextDisplaySettingsScheme,
  BoxTextMessagesScheme,
} from "./BoxText/BoxText.scheme";
import {
  BoxTilesSliderContentScheme,
  BoxTilesSliderContentSettingsScheme,
  BoxTilesSliderDisplaySettingsScheme,
  BoxTilesSliderSettingsScheme,
  BoxTilesSliderMessagesScheme,
} from "./BoxTilesSlider/BoxTilesSlider.scheme";
import {
  IBoxCartStepOneMessagesScheme,
  IBoxCartStepOneSettingsScheme,
} from "./Cart/BoxCartStepOne/BoxCartStepOne.scheme";
import {
  IBoxCartStepTwoMessagesScheme,
  IBoxCartStepTwoSettingsScheme,
} from "./Cart/BoxCartStepTwo/BoxCartStepTwo.scheme";
import {
  IBoxOrderSummaryMessagesScheme,
  IBoxOrderSummarySettingsScheme,
} from "./Cart/BoxOrderSummary/BoxOrderSummary.scheme";
import {
  BoxCartB2BMessagesScheme,
  BoxCartB2BSettingsScheme,
} from "./CartB2B/BoxCartB2B/BoxCartB2B.scheme";
import {
  BoxSummaryB2BMessagesScheme,
  BoxSummaryB2BSettingsScheme,
} from "./CartB2B/BoxSummaryB2B/BoxSummaryB2B.scheme";
import {
  BoxImageContentSettingsScheme,
  BoxImageDisplaySettingsScheme,
  BoxImageMessagesScheme,
} from "./BoxImage/BoxImage.scheme";
import { BoxHtmlMessageScheme } from "./BoxHtml/BoxHtml.scheme";
import {
  BoxButtonContentScheme,
  BoxButtonContentSettingsScheme,
} from "./BoxButton/BoxButton.scheme";
import {
  BoxListOfButtonsMessagesScheme,
  BoxListOfButtonsSettingsScheme,
} from "./BoxListOfButtons/BoxListOfButtons.types";
import {
  BoxFacetsVariantsContentScheme,
  BoxFacetsVariantsContentSettingsScheme,
  BoxFacetsVariantsDisplaySettingsScheme,
} from "./BoxFacetsVariants/BoxFacetsVariants.scheme";
import {
  IBoxPaypalPaymentMessagesScheme,
  IBoxPaypalPaymentSettingsScheme,
} from "./BoxPaypalPayment/BoxPaypalPayment.scheme";
import {
  IBoxStepBarMessagesScheme,
  IBoxStepBarSettingsScheme,
} from "./BoxStepBar/BoxStepBar.scheme";
import {
  IBoxVerifyPhoneNumberMessagesScheme,
  IBoxVerifyPhoneNumberSettingsScheme,
} from "./BoxVerifyPhoneNumber/BoxVerifyPhoneNumber.scheme";
import {
  IBoxChangePhoneNumberMessagesScheme,
  IBoxChangePhoneNumberSettingsScheme,
} from "./BoxChangePhoneNumber/BoxChangePhoneNumber.scheme";
import {
  IBoxChangePasswordMessagesScheme,
  IBoxChangePasswordSettingsScheme,
} from "./BoxChangePassword/BoxChangePassword.scheme";
import {
  IBoxNotificationsMessagesScheme,
  IBoxNotificationsSettingsScheme,
} from "./BoxNotifications/BoxNotifications.scheme";
import { IBoxCurrencyDropdownSettingsScheme } from "./BoxCurrencyDropdown/BoxCurrencyDropdown.scheme";
import { BoxBannerMessageScheme } from "./BoxBanner/BoxBanner.scheme";
import { BoxSliderMessagesScheme } from "./BoxSlider/BoxSlider.scheme";
import { BoxVideoMessagesScheme } from "./BoxVideo/BoxVideo.scheme";
import { BoxRichTextMessagesScheme } from "./BoxRichText/BoxRichText.scheme";
import {
  IBoxLanguageSwitcherMessagesScheme,
  IBoxLanguageSwitcherSettingsScheme,
} from "./BoxLanguageSwitcher/BoxLanguageSwitcher.scheme";
import { BoxBreadcrumbMessagesScheme } from "./BoxBreadcrumb/BoxBreadcrumb.scheme";
import {
  IBoxCartStepTwoExtendedMessagesScheme,
  IBoxCartStepTwoExtendedSettingsScheme,
} from "./Cart/BoxCartStepTwoExtended/BoxCartStepTwoExtended.scheme";
import {
  BoxShowPageMessagesScheme,
  BoxShowPageSettingsScheme,
} from "./BoxShowPage/BoxShowPage.scheme";

const emptyMS = {
  messages: z.object({}),
  settings: z.object({}),
};

export const boxScheme: Record<
  TSystemBoxType | TRegularBoxType,
  {
    messages: z.AnyZodObject;
    settings: z.AnyZodObject;
    displaySettings?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
    contentSettings?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
    content?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
  }
> = {
  TEXT: {
    ...emptyMS,
    messages: BoxTextMessagesScheme,
    content: BoxTextContentScheme,
    displaySettings: BoxTextDisplaySettingsScheme,
    contentSettings: BoxTextContentSettingsScheme,
  },
  IMAGE: {
    ...emptyMS,
    messages: BoxImageMessagesScheme,
    contentSettings: BoxImageContentSettingsScheme,
    displaySettings: BoxImageDisplaySettingsScheme,
  },
  SLIDER: {
    ...emptyMS,
    messages: BoxSliderMessagesScheme,
  },
  VIDEO: {
    ...emptyMS,
    messages: BoxVideoMessagesScheme,
  },
  HTML: {
    ...emptyMS,
    messages: BoxHtmlMessageScheme,
  },
  RICH_TEXT: {
    ...emptyMS,
    messages: BoxRichTextMessagesScheme,
  },
  BUTTON: {
    ...emptyMS,
    messages: BoxButtonMessagesScheme,
    settings: BoxButtonSettingsScheme,
    contentSettings: BoxButtonContentSettingsScheme,
    content: BoxButtonContentScheme,
  },
  BANNER: {
    ...emptyMS,
    messages: BoxBannerMessageScheme,
  },
  ARTICLE_LIST: emptyMS,
  BREADCRUMB: { ...emptyMS, messages: BoxBreadcrumbMessagesScheme },
  PRODUCT_VARIANT: emptyMS,
  GET_DATA: emptyMS,
  ACCORDION: emptyMS,
  LIST_OF_BUTTONS: {
    ...emptyMS,
    messages: BoxListOfButtonsMessagesScheme,
    settings: BoxListOfButtonsSettingsScheme,
  },
  TILES_SLIDER: {
    settings: BoxTilesSliderSettingsScheme,
    messages: BoxTilesSliderMessagesScheme,
    displaySettings: BoxTilesSliderDisplaySettingsScheme,
    contentSettings: BoxTilesSliderContentSettingsScheme,
    content: BoxTilesSliderContentScheme,
  },
  GET_TABLE_DATA: {
    messages: BoxTableDataMessagesScheme,
    settings: BoxTableDataSettingsScheme,
    displaySettings: BoxTableDataDisplaySettingsScheme,
    contentSettings: BoxTableDataContentSettingsScheme,
    content: BoxTableDataContentScheme,
  },
  SEARCH_RESULTS_B2B: {
    messages: BoxSearchResultsB2BMessagesScheme,
    settings: BoxSearchResultsB2BSettingsScheme,
    displaySettings: BoxSearchResultsB2BDisplaySettingsScheme,
    contentSettings: BoxSearchResultsB2BContentSettingsScheme,
    content: BoxSearchResultsB2BContentScheme,
  },
  PRODUCT_ATTRIBUTES: {
    settings: IBoxProductAttributesSettingsScheme,
    messages: IBoxProductAttributesMessagesScheme,
  },
  COMPARE: {
    settings: IBoxCompareSettingsScheme,
    messages: IBoxCompareMessagesScheme,
    displaySettings: BoxCompareDisplaySettingsScheme,
    contentSettings: BoxCompareContentSettingsScheme,
    content: BoxCompareContentScheme,
  },
  FACETS_VARIANTS: {
    ...emptyMS,
    displaySettings: BoxFacetsVariantsDisplaySettingsScheme,
    contentSettings: BoxFacetsVariantsContentSettingsScheme,
    content: BoxFacetsVariantsContentScheme,
  },
  SYSTEM: emptyMS,

  MYACCOUNT_CONSENTS: {
    messages: IBoxMyAccountConsentsMessagesScheme,
    settings: IBoxMyAccountConsentsSettingsScheme,
  },
  MYACCOUNT_HEADER: {
    messages: IBoxMyAccountHeaderMessagesScheme,
    settings: IBoxMyAccountHeaderSettingsScheme,
  },
  LOGIN: {
    messages: IBoxLoginMessagesScheme,
    settings: IBoxLoginSettingsScheme,
  },

  REMIND_PASSWORD: {
    messages: IBoxRemindMessagesScheme,
    settings: IBoxRemindSettingsScheme,
  },
  REGISTER: {
    messages: IBoxRegisterMessagesScheme,
    settings: IBoxRegisterSettingsScheme,
  },
  PHOTO_GALLERY: {
    messages: IBoxPhotoGalleryMessagesScheme,
    settings: IBoxPhotoGallerySettingsScheme,
  },
  ARTICLE_CONTENT: {
    messages: IBoxArticleContentMessagesScheme,
    settings: IBoxArticleContentSettingsScheme,
  },
  CART_STEP_ONE: {
    messages: IBoxCartStepOneMessagesScheme,
    settings: IBoxCartStepOneSettingsScheme,
  },
  CART_STEP_TWO: {
    messages: IBoxCartStepTwoMessagesScheme,
    settings: IBoxCartStepTwoSettingsScheme,
  },
  CART_STEP_TWO_EXTENDED: {
    messages: IBoxCartStepTwoExtendedMessagesScheme,
    settings: IBoxCartStepTwoExtendedSettingsScheme,
  },
  NEWSLETTER: {
    messages: IBoxNewsletterMessagesScheme,
    settings: IBoxNewsletterSettingsScheme,
  },
  SEARCH_BAR: {
    messages: IBoxSearchBarMessagesScheme,
    settings: IBoxSearchBarSettingsScheme,
  },
  DROPDOWN: {
    messages: IBoxDropdownMessagesScheme,
    settings: IBoxDropdownSettingsScheme,
  },
  CONTACT_FORM: {
    messages: IBoxContactFormMessagesScheme,
    settings: IBoxContactFormSettingsScheme,
  },
  ORDER_SUMMARY: {
    messages: IBoxOrderSummaryMessagesScheme,
    settings: IBoxOrderSummarySettingsScheme,
  },
  MYACCOUNT_MYORDERS: {
    messages: IBoxMyAccountMyOrdersMessagesScheme,
    settings: ITBoxMyAccountMyOrdersSettingsScheme,
  },
  MYACCOUNT_ORDER_DETAILS: {
    messages: IBoxMyAccountMyOrdersDetailsMessagesScheme,
    settings: IBoxMyAccountMyOrdersDetailsSettingsScheme,
  },
  MYACCOUNT_MYDATA: {
    messages: IBoxMyAccountMyDataMessagesScheme,
    settings: IBoxMyAccountMyDataSettingsScheme,
  },
  MINI_CART: {
    messages: IBoxMiniCartMessagesScheme,
    settings: IBoxMiniCartSettingsScheme,
  },
  ADD_TO_CART: {
    messages: BoxAddToCartMessagesScheme,
    settings: BoxAddToCartSettingsScheme,
  },
  SHOW_PAGE: {
    messages: BoxShowPageMessagesScheme,
    settings: BoxShowPageSettingsScheme,
  },
  MYACCOUNT_ADDRESSES: {
    messages: IBoxMyAccountMyAddressListMessagesScheme,
    settings: IBoxMyAccountMyAddressListSettingsScheme,
  },
  STORE_LOCATOR: {
    messages: BoxStoreLocatorMessagesScheme,
    settings: BoxStoreLocatorSettingsScheme,
  },
  ADD_TO_CART_QUANTITY: {
    messages: IBoxAddToCartQuantityContentMessagesScheme,
    settings: IBoxAddToCartQuantityContentSettingsScheme,
  },
  SEARCH_FILTERS: {
    messages: IBoxSearchFiltersMessagesScheme,
    settings: IBoxSearchFiltersSettingsScheme,
    displaySettings: BoxSearchFiltersDisplaySettingsScheme,
    contentSettings: BoxSearchFiltersContentSettingsScheme,
    content: BoxSearchFiltersContentScheme,
  },
  SEARCH_RESULTS: {
    messages: IBoxSearchResultsMessagesScheme,
    settings: IBoxSearchResultsSettingsScheme,
  },
  PRODUCT_SLIDER: {
    messages: IBoxProductSliderMessagesScheme,
    settings: IBoxProductSliderSettingsScheme,
  },
  MULTI_MENU: {
    messages: IBoxMultiMenuMessagesScheme,
    settings: IBoxMultiMenuSettingsScheme,
  },
  CONTRACTORS: {
    messages: IBoxContractorsMessageScheme,
    settings: IBoxContractorsSettingsScheme,
  },
  CART_B2B: {
    messages: BoxCartB2BMessagesScheme,
    settings: BoxCartB2BSettingsScheme,
  },
  B2B_ADD_TO_CART_QUANTITY: {
    messages: IBoxAddToCartQuantityB2BMessagesScheme,
    settings: IBoxAddToCartQuantityB2BSettingsScheme,
  },
  B2B_CART_SUMMARY: {
    messages: BoxSummaryB2BMessagesScheme,
    settings: BoxSummaryB2BSettingsScheme,
  },
  B2B_ADD_TO_CART_UNIT: {
    messages: emptyMS.messages,
    settings: IBoxB2BAddToCartUnitSettingsScheme,
  },
  MINI_CART_B2B: {
    messages: IBoxMiniCartB2BMessagesScheme,
    settings: IBoxMiniCartB2BSettingsScheme,
  },
  COMPARE_HEADER: {
    messages: IBoxCompareHeaderMessagesScheme,
    settings: IBoxCompareHeaderSettingsScheme,
  },
  PAYPAL_PAYMENT: {
    messages: IBoxPaypalPaymentMessagesScheme,
    settings: IBoxPaypalPaymentSettingsScheme,
  },
  STEP_BAR: {
    messages: IBoxStepBarMessagesScheme,
    settings: IBoxStepBarSettingsScheme,
  },
  VERIFY_PHONE_NUMBER: {
    messages: IBoxVerifyPhoneNumberMessagesScheme,
    settings: IBoxVerifyPhoneNumberSettingsScheme,
  },
  CHANGE_PHONE_NUMBER: {
    messages: IBoxChangePhoneNumberMessagesScheme,
    settings: IBoxChangePhoneNumberSettingsScheme,
  },
  CHANGE_PASSWORD: {
    messages: IBoxChangePasswordMessagesScheme,
    settings: IBoxChangePasswordSettingsScheme,
  },
  CLIENT_NOTIFICATIONS: {
    messages: IBoxNotificationsMessagesScheme,
    settings: IBoxNotificationsSettingsScheme,
  },
  CURRENCY_DROPDOWN: {
    messages: emptyMS.messages,
    settings: IBoxCurrencyDropdownSettingsScheme,
  },
  LANGUAGE_SWITCHER: {
    messages: IBoxLanguageSwitcherMessagesScheme,
    settings: IBoxLanguageSwitcherSettingsScheme,
  },
};
