import React, { useState } from "react";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  AddressPostalBilling,
  AddressType,
  TAddresses,
  isPostalBillingAddress,
} from "@ecp-redux/dto/cartAddresses.type";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import BillingPostalAddressFormExtended from "./BillingPostalAddressFormExtended";
import AddressInfo from "../BoxCartStepTwoExtended/potentialBoxes/DeliveryChannels/elements/SelectedAddress/elements/AddressInfo";
import { StyledChangeAddress } from "../BoxCartStepTwoExtended/BoxCartStepTwoExtended.styled";
import { IBoxCartStepTwoExtendedProps } from "../BoxCartStepTwoExtended/BoxCartStepTwoExtended.types";
import SelectionTable from "../BoxCartStepTwoExtended/components/shared/SelectionTable";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { useBoxCartStepTwoExtended } from "../BoxCartStepTwoExtended/context/BoxCartStepTwoExtended.context";

interface ChangeAddressProps {
  selectedAddress: TAddresses | null;
  addresses: TAddresses[];
  handleToggleEditAddress: () => void;
  addressType: AddressType.POSTAL | AddressType.BILLING;
  setIsAddressValid: (isValid: boolean) => void;
}

const ChangeBillingPostalAddressExtended: React.FC<ChangeAddressProps> = ({
  selectedAddress,
  addresses = [],
  addressType,
  handleToggleEditAddress,
  setIsAddressValid,
}) => {
  const [showForm, setShowForm] = useState(false);
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { isLogged } = useBoxCartStepTwoExtended();
  const hasOnlySelectedAddress =
    addresses.length === 0 && selectedAddress != null;

  const [editAddress] = useState<null | TAddresses>(
    hasOnlySelectedAddress ? selectedAddress : null
  );
  const [isLoading, setIsLoading] = useState(false);

  const [changeBillingAddress] =
    cartAPI.usePostChangeCartSelectedAddressMutation();
  const [changePostalAddress] =
    cartAPI.usePostChangeCartSelectedAddressExtendedMutation();

  const isPostalForm = addressType === AddressType.POSTAL;

  const closeForm = () => {
    handleToggleEditAddress();
  };

  return (
    <StyledChangeAddress
      data-testid="ChangeBillingPostalAddressComponent"
      className="change-address"
      $settings={settings}
    >
      {!showForm && isLogged && addresses.length !== 0 && (
        <>
          <SelectionTable
            verticalPadding={10}
            isLoading={isLoading}
            items={addresses.map((address) => ({
              selected: address.id === selectedAddress?.id,
              component: (
                <AddressInfo address={address as AddressPostalBilling} />
              ),
              onClick: async () => {
                setIsLoading(true);
                if (isPostalForm) {
                  await changePostalAddress({ addressId: address.id });
                } else {
                  await changeBillingAddress({ addressId: address.id });
                }
                setIsLoading(false);
                handleToggleEditAddress();
              },
            }))}
          />
          <StyledButton
            className="change-address__add-new-address"
            renderAs="button"
            $settings={settings.select_form_button_style}
            onClick={() => {
              setShowForm(true);
            }}
          >
            {messages.delivery_add_new_address}
          </StyledButton>
        </>
      )}

      {(showForm || !isLogged || addresses.length === 0) && (
        <div>
          <BillingPostalAddressFormExtended
            setIsAddressValid={setIsAddressValid}
            disableForm={closeForm}
            addressType={addressType}
            editData={isPostalBillingAddress(editAddress) ? editAddress : null}
            isDefaultAddress={addresses.length === 0}
          />
        </div>
      )}
    </StyledChangeAddress>
  );
};

export default ChangeBillingPostalAddressExtended;
