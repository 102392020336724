import styled, { css } from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import { IBoxCartStepTwoExtendedSettings } from "./BoxCartStepTwoExtended.types";

export const StyledBoxCartStepTwoExtended = styled.div<{
  $backgroundColor: TColorId;
  $summaryBackgroundColor: TColorId;
  $isLoading: boolean;
}>(({ $backgroundColor, theme, $isLoading, $summaryBackgroundColor }) => {
  const bg = convertColorIdToHex($backgroundColor, theme.colorPalette);
  const summaryBg = convertColorIdToHex(
    $summaryBackgroundColor,
    theme.colorPalette
  );
  return css`
    ${$isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
    width: 100%;
    max-width: 1160px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-inline: auto;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-template-columns: 1fr;
      gap: 0px;
    }
    .icon-placeholder {
      width: 48px;
      height: 24px;
    }
    .summary {
      background-color: unset;
      .summary_content {
        padding: 20px;
        background-color: ${summaryBg};
        .summary__header {
          margin-bottom: 12px;
        }
      }
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin-top: 20px;
      }
      .promo-code {
        margin-top: 16px;
      }
    }

    .cart-step-two {
      &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 50px;
        isolation: isolate;
        &::after {
          content: "";
          background-color: ${bg};
          position: absolute;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          z-index: -1;
        }
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          padding: 10px;
        }
      }
    }
    .left-indent {
      padding-left: 16px;
    }
    .loader:not(.input-loader-container .loader) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `;
});

export const StyledDeliveryChannels = styled.div(
  () => css`
    .delivery-channels {
      &__channel-container__main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      &__channel-container {
        display: flex;
        flex-direction: column;
        gap: 28px;
      }

      &__channel-button-container {
        justify-self: end;
        align-self: end;
      }

      &__alert {
        margin-bottom: 15px;
      }
    }
  `
);

export const StyledDeliveryElement = styled.div<{
  deliveryChannelDisabled: boolean;
}>(
  ({ deliveryChannelDisabled }) => css`
    display: flex;
    gap: 15px;
    align-items: center;

    ${deliveryChannelDisabled
      ? `
          opacity: 0.2;
          pointer-events: none;
          `
      : ""}

    .delivery-channel-element {
      &__icon {
        flex: none;
        height: 2.25rem;
        width: 2.25rem;
      }

      &__info-container {
        display: flex;

        &__price {
          min-width: 80px;
        }
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        &__info-container {
          width: 100vw;
        }
      }
    }
  `
);

export const StyledSelectedAddress = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 15px;

    .selected-address {
      &__background-delivery-form {
        padding: 10px;
      }

      &__delivery-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      &__background-address-payment {
        padding: 10px;
      }

      &__delivery-address {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  `
);

export const StyledChangeAddress = styled.div<{
  $settings: IBoxCartStepTwoExtendedSettings;
}>(
  ({ $settings, theme }) => css`
    .change-address {
      display: flex;
      flex-direction: column;

      &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;
        border: 1px solid
          ${convertColorIdToHex($settings.frame_color, theme.colorPalette)};
        padding: 10px;

        &:not(:nth-last-child(1 of &)) {
          border-bottom: none;
        }

        &--selected {
          background-color: ${convertColorIdToHex(
            $settings.selection_background_color,
            theme.colorPalette
          )};
        }

        &__button {
          align-self: flex-start;
          flex-grow: 1;

          &__icon {
            margin-left: auto;
          }
        }

        &__radio {
          overflow: hidden;

          &__address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &__add-new-address {
        float: right;
      }

      &__buttons-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  `
);

export const StyledBillingPostalAddressForm = styled.div(
  () => css`
    .buttons {
      display: flex;
      gap: 10px;
      margin-top: 5px;
      align-items: center;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
        ${`${StyledButton} { width: 100%; }`}
      }
    }

    .half_inputs {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
    }

    .billing-postal-address-form {
      &__add-address-button {
        margin-bottom: 0.75rem;
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__address-type {
          display: flex;
          gap: 1.25rem;
        }
      }
    }
  `
);

export const StyledBillingAddress = styled.div(
  () => css`
    background-color: #ffffff;
    padding: 1.25rem;

    .billing {
      &__header {
        margin-bottom: 15px;
      }

      &__alert {
        margin-bottom: 15px;
      }

      &__selected-address-background {
        padding: 10px;
      }

      &__select-billing {
        display: flex;
        justify-content: space-between;

        &__button {
          align-self: flex-start;
        }
      }
    }
  `
);

export const StyledPayments = styled.div(
  () => css`
    padding: 1.25rem;

    .payments {
      &___payment-header {
        margin-bottom: 15px;
      }

      &__alert {
        margin-bottom: 15px;
      }

      &__payment-method-background {
        padding: 10px;
      }

      &__payment-method-text {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      &__edit-payment-button {
        align-self: flex-start;
      }

      &__payments-methods-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &__content {
          display: flex;
          flex-direction: column;
        }
      }

      &__payment-button {
        justify-self: end;
        align-self: end;
      }
    }
  `
);

export const StyledPaymentElement = styled.div<{
  paymentDisabled: boolean;
}>(
  ({ paymentDisabled }) => css`
    display: flex;
    gap: 15px;
    align-items: center;

    ${paymentDisabled
      ? `
            pointer-events: none;
            opacity: 0.5;
          `
      : ""}
  `
);
