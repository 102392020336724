import { memo } from "react";
import { isEqual } from "lodash";
import styled, { css } from "styled-components";
import { IPortalSlot, SectionType } from "@ecp-pageTypes";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import composeCss from "../../settingsPatterns/composeCss";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Slot from "./Slot";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";

interface ISlotLine {
  slots: IPortalSlot[];
  $settings: IDisplaySettingsSection;
  mobileSecondLine?: boolean;
  type: SectionType;
  path: PortalPath;
}

export const StyledSlotsLine = styled.div<{
  $settings: IDisplaySettingsSection;
  columnsInSection: number;
  firstLine?: boolean;
  type: SectionType;
  slotsInSection: number;
}>((props) => {
  const { $settings, firstLine, columnsInSection, slotsInSection, theme } =
    props;
  const {
    spacingBetweenSlots,
    fullWidthBackground,
    popup: { enabled: popupEnabled } = { popupEnabled: false },
  } = $settings;
  const isFitBackground = !fullWidthBackground;
  return css`
    border-color: transparent;
    display: grid;
    max-width: 100%;
    height: fit-content;
    height: -webkit-fit-content;
    overflow: clip;
    ${!isPortalSide() &&
    `
    &:hover {
      overflow: visible;
    }
  `}
    &:before {
      ${$settings.radius &&
      composeCss.borderRadiusWithCorners($settings.radius)};
    }
    ${composeCss.minHeight($settings.height)}

    ${isFitBackground
      ? composeCss.backgroundWithOpacity($settings.background, theme)
      : ""}
  grid-template-columns: repeat(${columnsInSection}, minmax(0, 1fr));

    ${slotsInSection > 1
      ? `column-gap: ${
          spacingBetweenSlots !== null || spacingBetweenSlots !== undefined
            ? spacingBetweenSlots
            : theme.stylePages.section.spacingBetweenSlots
        }px;`
      : ""}
    ${firstLine
      ? `margin-bottom: ${theme.stylePages.section.spacingBetweenSections}px;`
      : ""}
    ${composeCss.clampPadding($settings.padding, theme)};
    ${composeCss.width($settings.width, popupEnabled, theme)}
    ${composeCss.minHeight($settings.height)}
    ${composeCss.borderColor($settings.border.color, theme)};
    ${composeCss.borderStyle($settings.border.style)};
    ${composeCss.borderWidth($settings.border.width)};
    ${isFitBackground &&
    $settings.radius &&
    composeCss.borderRadiusWithCorners($settings.radius)};
    #overlay-slot {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  `;
});

const SlotsLine: React.FC<ISlotLine> = ({
  slots,
  mobileSecondLine,
  $settings,
  type,
  path,
}) => {
  const columnsInSection = slots.reduce(
    (result, slot) => result + slot.columns,
    0
  );
  if (!slots.length && mobileSecondLine) {
    return null;
  }
  return (
    <StyledSlotsLine
      $settings={$settings}
      id="slots-line"
      columnsInSection={columnsInSection}
      data-testid={mobileSecondLine ? "mobileSecondLine" : "firstLineInSection"}
      firstLine={!mobileSecondLine}
      slotsInSection={slots.length}
      type={type}
    >
      {slots.map((slot: IPortalSlot, index: number) => {
        return (
          <Slot
            key={slot.id}
            slotData={slot}
            type={type}
            path={path.concatPaths(
              new PortalPath([
                {
                  type: "SLOT",
                  elementId: slot.id,
                  elementIndex: index,
                },
              ])
            )}
          />
        );
      })}
    </StyledSlotsLine>
  );
};

export default memo(SlotsLine, isEqual);
