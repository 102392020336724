import {
  IPortalBoxes,
  IPortalPage,
  IPortalPageResponse,
  IPortalPublication,
  IPortalSection,
  IPortalSlot,
  IPortalStack,
} from "./portal.types";

export const transformPortalPage = ({
  page,
  boxes,
  headerBoxes,
  footerBoxes,
}: IPortalPageResponse): IPortalPage => ({
  ...page,
  contents: [
    {
      id: page.content[0]?.id ?? "",
      title: page.content[0]?.title ?? "",
      description: page.content[0]?.description ?? "",
      keywords: page.content[0]?.keywords ?? "",
      language: page.content[0]?.language ?? "",
    },
  ],
  sections: transformPortalSection(page.sections, boxes),
  header:
    page.header && headerBoxes
      ? transformPortalPage({ page: page.header, boxes: headerBoxes })
      : null,
  footer:
    page.footer && footerBoxes
      ? transformPortalPage({ page: page.footer, boxes: footerBoxes })
      : null,
});

const transformPortalSection = (
  sections: IPortalPageResponse["page"]["sections"],
  boxes?: IPortalBoxes
): IPortalSection[] => {
  return sections?.map((s) => {
    return {
      ...s,
      slots: transformPortalSlot(s.slots, boxes),
    };
  });
};

const transformPortalSlot = (
  slots: IPortalPageResponse["page"]["sections"]["0"]["slots"],
  boxes?: IPortalBoxes
): IPortalSlot[] => {
  return slots.map((s) => {
    return {
      ...s,
      stacks: transformPortalStack(s.stacks, boxes),
      sections: transformPortalSection(s.sections, boxes),
    };
  });
};

const transformPortalStack = (
  stacks: IPortalPageResponse["page"]["sections"]["0"]["slots"]["0"]["stacks"],
  boxes?: IPortalBoxes
): IPortalStack[] => {
  return stacks.map((s) => {
    return {
      publications: transformPortalPublication(s.publications, boxes),
    };
  });
};

const transformPortalPublication = (
  publications: IPortalPageResponse["page"]["sections"]["0"]["slots"]["0"]["stacks"]["0"]["publications"],
  boxes?: IPortalBoxes
): IPortalPublication[] => {
  return publications.map((p) => {
    return {
      ...p,
      box: boxes?.[p?.boxId],
    };
  });
};
