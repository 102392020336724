import { nanoid } from "nanoid";
import { z } from "zod";
import {
  SpacingScheme,
  createTextStyleShowSettingScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { createImageContentScheme } from "../../settingsSchemes/contentSchemes.utils";
import {
  InnerBoxContentSettingScheme,
  MainButtonContentSettingScheme,
  SecondButtonContentSettingScheme,
  createImageContentSettingScheme,
  createTitleContentSettingsScheme,
} from "../../settingsSchemes/settingsSchemes.utils";
import { SliderPaginationOptions, SliderSizeOptions } from "./BoxSlider.types";

export const BoxSliderDisplayScheme = z.object({
  size: z
    .nativeEnum(SliderSizeOptions)
    .default(SliderSizeOptions.MEDIUM)
    .catch(SliderSizeOptions.MEDIUM),
  customHeight: z.number().default(600).catch(600),
  paginationStyle: z
    .nativeEnum(SliderPaginationOptions)
    .default(SliderPaginationOptions.DOTS)
    .catch(SliderPaginationOptions.DOTS),
  padding: SpacingScheme.default({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }).catch({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  margin: SpacingScheme.default({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }).catch({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  slidesRotate: z.array(z.number()).default([]).catch([]),
});

const TitleContentSettingsScheme = createTitleContentSettingsScheme();
const TextStyleShowSettingsScheme = createTextStyleShowSettingScheme();

export const createBoxSliderSlideContentSettingsScheme = (
  backgroundUrl?: string
) =>
  z.object({
    image: createImageContentSettingScheme(backgroundUrl),
    box: InnerBoxContentSettingScheme,
    title: TitleContentSettingsScheme,
    description: TextStyleShowSettingsScheme,
    mainButton: MainButtonContentSettingScheme,
    secondaryButton: SecondButtonContentSettingScheme,
  });

export const createBoxSliderSlideContentScheme = (backgroundUrl?: string) =>
  z.object({
    id: z.string().default(nanoid()).catch(nanoid()),
    image: createImageContentScheme(backgroundUrl)
      .default({
        imageUrl: backgroundUrl ?? "",
      })
      .catch({
        imageUrl: backgroundUrl ?? "",
      }),
    title: z.string().default("Add title").catch("Add title"),
    description: z.string().default("Add description").catch("Add description"),
    slideUrl: z
      .object({
        showLinkOptions: z.boolean().default(false).catch(false),
        selectedGoToOption: z
          .union([
            z.literal(SelectedGoToOption.GO_TO_PAGE),
            z.literal(SelectedGoToOption.GO_TO_ROW),
          ])
          .default(SelectedGoToOption.GO_TO_PAGE)
          .catch(SelectedGoToOption.GO_TO_PAGE),
        goToRowSetting: z
          .object({
            rowId: z.union([z.string(), z.number(), z.null()]),
          })
          .default({ rowId: null })
          .catch({ rowId: null }),
        goToPageSetting: z.object({
          link: z.string().nullable().default(null).catch(null),
          openInNewTab: z.boolean().default(true).catch(true),
        }),
      })
      .default({
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: null, openInNewTab: true },
      })
      .catch({
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: null, openInNewTab: true },
      }),
    mainButton: z
      .object({ heading: z.string(), link: z.string().default("").catch("") })
      .default({
        heading: "Button",
        link: "",
      }),
    secondaryButton: z
      .object({ heading: z.string(), link: z.string().default("").catch("") })
      .default({
        heading: "Button",
        link: "",
      }),
    seoDescription: z.string().default("").catch(""),
  });

export const BoxSliderMessagesScheme = z.object({});
