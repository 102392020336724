import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import * as auth from "@ecp-redux/api/auth";
import * as myData from "@ecp-redux/api/myData";
import { IUserLoginForm } from "@ecp-redux/dto/auth.types";
import { useState } from "react";

const useLogin = () => {
  const [loginError, setLoginError] = useState(false);
  const [postLogin] = auth.usePostLoginMutation();
  const [trigger] = myData.useLazyGetCustomerDataWithNoValidationQuery();
  const { setSessionStorageItem } = useSetSessionStorage();

  const loginUser = async (data: IUserLoginForm) => {
    try {
      const response = await postLogin(data).unwrap();
      const customerData = await trigger().unwrap();
      if (customerData) {
        setSessionStorageItem("customer_id", String(customerData.customerId));
        setSessionStorageItem("email", String(customerData.email));
        setSessionStorageItem("is_customer_state_validated", "true");
      }

      return response;
    } catch (_) {
      setLoginError(true);
      return null;
    }
  };

  const clearLoginError = () => {
    setLoginError(false);
  };

  return { loginUser, loginError, clearLoginError };
};

export default useLogin;
