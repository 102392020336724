import { AddToCartB2B2CRequest } from "./../dto/cartB2B.types";
import {
  DeleteProductRequest,
  ICartB2B,
  ICartB2B_DTO,
  IMiniCartB2BResponse,
  IMiniCartB2BResponseDTO,
  ISummaryB2B_StepTwo,
  NotificationErrorB2B_Cart,
  SetDeliveryDateB2BRequest,
  SetPaymentMethodB2BRequest,
} from "../dto/cartB2B.types";
import { getDateParsed, parseTimeToTimezoneOffset } from "../helpers";
import { API, api } from "./api";

export const transformCartB2B = (data: ICartB2B_DTO): ICartB2B => ({
  orders: data.orders.map((order) => ({
    ...order,
    deliveryDate:
      order.deliveryDate !== null
        ? getDateParsed(order.deliveryDate)
        : order.deliveryDate,
    deliveryCalendar: order.deliveryCalendar.map((deliveryCalendar) => ({
      ...deliveryCalendar,
      deliveryDate: getDateParsed(deliveryCalendar.deliveryDate),
    })),
  })),
  summary: data.summary,
  notificationErrors: [
    ...new Set([
      ...data.notificationErrors.map((error) => error.type),
      ...data.orders
        .map((order) =>
          order.cartLines.map((cartLine) => cartLine.notificationErrors)
        )
        .flat(2)
        .map((error) => error.type),
    ]),
  ],
  unavailableProducts: data.notificationErrors
    .filter(
      (e) => e.type === NotificationErrorB2B_Cart.PRODUCT_IS_NOT_AVAILABILITY
    )
    .map((e) => e.data),
  stockVerification: data.stockVerification,
  hasNotificationErrors: data.hasNotificationErrors,
  cartId: data.cartId,
});

export const cartB2B = api.injectEndpoints({
  endpoints: (builder) => ({
    getCartB2B: builder.query<ICartB2B, void>({
      query: () => {
        return {
          url: API.getCartB2B.endpoint(),
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
      providesTags: [
        "CONTRACTORS",
        "CART_B2B",
        "LANGUAGE",
        "CURRENCY_DROPDOWN",
      ],
    }),
    deleteProductCartB2B: builder.mutation<ICartB2B, DeleteProductRequest>({
      query: (query) => {
        return {
          url: API.deleteProductCartB2B.endpoint(query),
          method: "DELETE",
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      invalidatesTags: ["MINICART_B2B"],
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    putSetDeliveryDateCartB2B: builder.mutation<
      ICartB2B,
      SetDeliveryDateB2BRequest
    >({
      query: (body) => {
        return {
          url: API.putSetDeliveryDateCartB2B.endpoint(),
          method: "PUT",
          body,
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    putClearProductTypeB2B: builder.mutation<ICartB2B, string>({
      query: (productType) => {
        return {
          url: API.putClearProductTypeB2B.endpoint(productType),
          method: "DELETE",
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      invalidatesTags: ["MINICART_B2B"],
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    putClearCartB2B: builder.mutation<ICartB2B, void>({
      query: () => {
        return {
          url: API.putClearCartB2B.endpoint(),
          method: "DELETE",
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      invalidatesTags: ["MINICART_B2B"],
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    putPaymentMethodCartB2B: builder.mutation<
      ICartB2B,
      SetPaymentMethodB2BRequest
    >({
      query: (body) => {
        return {
          url: API.putSetPaymentMethodCartB2B.endpoint(),
          method: "PUT",
          body,
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    putPlaceOrderCartB2B: builder.mutation<ICartB2B, void>({
      query: (body) => {
        return {
          url: API.putPlaceOrderCartB2B.endpoint(),
          method: "PUT",
          body,
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      transformResponse: (response: ICartB2B_DTO) => transformCartB2B(response),
    }),
    getSummaryB2B: builder.query<ISummaryB2B_StepTwo, string>({
      query: (cartId) => {
        return {
          url: API.getSummaryB2B.endpoint(cartId),
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      providesTags: ["LANGUAGE", "CURRENCY_DROPDOWN"],
    }),
    getMiniCartB2B: builder.query<IMiniCartB2BResponse, void>({
      query: () => {
        return {
          url: API.getMiniCartB2B.endpoint(),
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },
      transformResponse: (response: IMiniCartB2BResponseDTO) => {
        return {
          ...response,
          count: response.orders.reduce(
            (acc, order) => acc + order.cartLines.length,
            0
          ),
          summary: {
            ...response.summary,
          },
        };
      },
      providesTags: [
        "CONTRACTORS",
        "CART_B2B",
        "MINICART_B2B",
        "LANGUAGE",
        "CURRENCY_DROPDOWN",
      ],
    }),
    postAddProductB2BQuantity: builder.mutation<
      ICartB2B,
      AddToCartB2B2CRequest
    >({
      query: ({ sku, quantity, unitOfMeasure, isCart }) => {
        return {
          url: API.putAddToCartB2B.endpoint(),
          method: "PUT",
          body: {
            sku,
            quantity,
            unitOfMeasure,
            isCart,
          },
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
        };
      },

      invalidatesTags: ["CART_B2B"],

      transformResponse: (response: ICartB2B_DTO) => {
        return transformCartB2B(response);
      },
    }),
  }),
});

export const {
  useGetCartB2BQuery,
  useGetMiniCartB2BQuery,
  useLazyGetMiniCartB2BQuery,
  useLazyGetSummaryB2BQuery,
  usePutClearProductTypeB2BMutation,
  usePutClearCartB2BMutation,
  usePutSetDeliveryDateCartB2BMutation,
  useDeleteProductCartB2BMutation,
  usePutPaymentMethodCartB2BMutation,
  usePutPlaceOrderCartB2BMutation,
  usePostAddProductB2BQuantityMutation,
} = cartB2B;

export const { getCartB2B } = cartB2B.endpoints;
