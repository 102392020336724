import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import * as braintreeAPI from "@ecp-redux/api/braintree";
import braintree, { HostedFields } from "braintree-web";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertInputIdToValues } from "@ecp-boxes/shared/components/Input/StyledInput/StyledInput.methods";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { HostedFieldsHostedFieldsFieldName } from "braintree-web/hosted-fields";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { CreditCardStyled } from "./CreditCard.styled";
import CartLoader from "../shared/CartLoader";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import { TPaymentComponentRef } from "../sections/PaymentSection";

const CreditCard = ({
  creditCardRef,
}: {
  creditCardRef: React.RefObject<TPaymentComponentRef>;
}) => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { setSectionValidation } = useBoxCartStepTwoExtended();
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme() as IThemeState;
  const { data: clientToken, isLoading: isTokenLoading } =
    braintreeAPI.useGetClientTokenQuery(undefined, {
      skip: !isPortalSide(),
    });

  useImperativeHandle(creditCardRef, () => ({
    generateNonce: async () => {
      const formState = hostedFields?.getState();
      if (!formState || !hostedFields) return { nonce: null, hasError: true };

      let hasInvalidFields = false;
      Object.keys(formState.fields).forEach((field) => {
        if (
          !formState.fields[field as HostedFieldsHostedFieldsFieldName].isValid
        ) {
          hasInvalidFields = true;
          (
            formState.fields[field as HostedFieldsHostedFieldsFieldName]
              .container as HTMLElement
          ).classList.add("braintree-hosted-fields-invalid");
        }
      });

      if (hasInvalidFields) {
        setSectionValidation("payment", false);
        return { nonce: null, hasError: true };
      }

      try {
        const { nonce } = await hostedFields.tokenize();
        return { nonce, hasError: false };
      } catch (err) {
        console.error(err);
        return { nonce: null, hasError: true };
      }
    },
  }));

  const isProcessing = isTokenLoading || isLoading;

  const formRef = useRef<HTMLFormElement>(null);

  const { text, font, ...inputStyle } = useMemo(
    () =>
      convertInputIdToValues(
        settings.basket_step_2_full_input,
        theme.globalObjects.inputs
      ),
    [settings.basket_step_2_full_input, theme.globalObjects.inputs]
  );

  useEffect(() => {
    if (!clientToken?.token) return;

    const inputFontStyle = convertTypoIdToValues(font.input, theme.typography);
    const initializeBraintree = async () => {
      setIsLoading(true);
      try {
        const hostedFieldsInstance = await braintree.hostedFields.create({
          client: await braintree.client.create({
            authorization: clientToken.token,
          }),
          styles: {
            input: {
              color: convertColorIdToHex(text.color, theme.colorPalette),
              "font-family": inputFontStyle.family,
              "font-size": `${inputFontStyle.size}px`,
              "font-weight": `${inputFontStyle.weight}`,
              "letter-spacing": `${inputFontStyle.letterSpacing}px`,
              "line-height": `${inputFontStyle.lineHeight}px`,
            },
            "input.invalid": {
              color: convertColorIdToHex(text.errorColor, theme.colorPalette),
            },
            "input.focus": {
              color: convertColorIdToHex(text.activeColor, theme.colorPalette),
            },
            "::-webkit-input-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            ":-moz-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            "::-moz-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            ":-ms-input-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
          },
          fields: {
            number: {
              selector: "#card-number",
              placeholder: messages.card_number_placeholder,
            },
            cvv: {
              selector: "#cvv",
              placeholder: messages.cvv_placeholder,
            },
            expirationDate: {
              selector: "#expiration-date",
              placeholder: messages.expiration_date_placeholder,
            },
          },
        });

        setHostedFields(hostedFieldsInstance);

        // Event listeners
        hostedFieldsInstance.on("focus", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.add("focus");
        });

        hostedFieldsInstance.on("blur", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.remove("focus");
        });

        hostedFieldsInstance.on("validityChange", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.toggle(
            "invalid",
            !event.fields[event.emittedBy].isValid
          );

          const state = hostedFieldsInstance.getState();
          const isValid = Object.values(state.fields).every(
            (field) => field.isValid
          );
          setSectionValidation("payment", isValid);
        });
      } catch (err) {
        console.error(err);
        setSectionValidation("payment", false);
      } finally {
        setIsLoading(false);
      }
    };

    initializeBraintree();
    setSectionValidation("payment", false);

    return () => {
      hostedFields?.teardown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken]);

  return (
    <CreditCardStyled
      id="my-sample-form"
      ref={formRef}
      $inputStyle={{ ...inputStyle, font }}
      $isLoading={isProcessing}
    >
      <header>
        <StyledText
          $settings={{
            text: { color: settings.cart_color_primary },
            font: settings.section_subtext_typography,
          }}
        >
          {messages.payment_CREDIT_CARD_description}
        </StyledText>
      </header>
      <div className="card-info">
        <div className="input-container card-number">
          <label htmlFor="card-number">{messages.card_number}</label>
          <div id="card-number"></div>
        </div>
        <div className="input-container expiration-date">
          <label htmlFor="expiration-date">{messages.expiration_date}</label>
          <div id="expiration-date"></div>
        </div>
        <div className="input-container cvv">
          <label htmlFor="cvv">{messages.cvv}</label>
          <div id="cvv"></div>
        </div>
      </div>
      {isProcessing && <CartLoader />}
    </CreditCardStyled>
  );
};
export default CreditCard;
