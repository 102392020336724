import styled from "styled-components";
import { TSizeCSS } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledText from "../../styleElements/StyledText/StyledText";

export const StyledCheckboxList = styled.div<{ gap?: TSizeCSS }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ gap }) => (gap ? gap : "5px")};
`;

export const Item = styled.div<{ disabled?: boolean }>`
  align-items: center;
  position: relative;
  ${({ disabled }) => (disabled ? "opacity: 0.4;" : "")};

  .checkbox-list__element {
    &__checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }
  }
`;

export const CheckboxButtonLabel = styled.label`
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
`;

export const CheckboxButton = styled.input`
  opacity: 0;
  z-index: var(--zindex-checkbox-button);
  cursor: pointer;

  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  position: relative;

  &:checked + ${CheckboxButtonLabel} {
    background: black;
    &::before {
      content: "";
      position: absolute;
      left: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:indeterminate + ${CheckboxButtonLabel} {
    display: block;
    background: black;
    &::before {
      content: "";
      position: absolute;
      left: 4px;
      width: 6px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 0px 0;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  ${StyledText} {
    display: inline-block;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  ${StyledText} {
    display: inline-block;
  }
`;
