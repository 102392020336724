import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import { GlobalTableCellReadoutValueScheme } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  Border_v2Scheme,
  ButtonReadoutValueScheme,
  ColorReadoutValueScheme,
  DropdownReadoutValueScheme,
  InputReadoutValueScheme,
  SpacingScheme,
  TypoReadoutValueScheme,
  defaultBorder,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export enum BoxSearchResultsB2BEndpointType {
  PRODUCT_CATALOG = "PRODUCT_CATALOG",
  ACTIONS = "ACTIONS",
}

export type IBoxSearchResultsB2BProps = IPortalBox<IBoxSearchResultsB2BData>;

export type IBoxSearchResultsB2BData = GenericBoxData<
  IBoxSearchResultsB2BContent,
  IBoxSearchResultsB2BContentSettings,
  IBoxSearchResultsB2BDisplaySettings,
  IBoxSearchResultsB2BMessages,
  IBoxSearchResultsB2BSettings
>;

export type IBoxSearchResultsB2BSettings = z.infer<
  typeof BoxSearchResultsB2BSettingsScheme
>;

export type IBoxSearchResultsB2BMessages = z.infer<
  typeof BoxSearchResultsB2BMessagesScheme
>;

export type IBoxSearchResultsB2BContent = z.infer<
  typeof BoxSearchResultsB2BContentScheme
>;

export type IBoxSearchResultsB2BContentSettings = z.infer<
  typeof BoxSearchResultsB2BContentSettingsScheme
>;

export type IBoxSearchResultsB2BDisplaySettings = z.infer<
  typeof BoxSearchResultsB2BDisplaySettingsScheme
>;

export enum SrpB2bColumnType {
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  UNITS = "UNITS",
  ACTIONS = "ACTIONS",
}

export const TableAttributeSettingsScheme = z.discriminatedUnion("columnType", [
  z.object({
    columnType: z.literal(SrpB2bColumnType.IMAGE),
    translationText: z.string().default("label"),
    width: z.number().default(70),
    headerStyle: GlobalTableCellReadoutValueScheme,
    dataStyle: GlobalTableCellReadoutValueScheme,
    alignment: z
      .object({
        horizontal: z
          .nativeEnum(AlignmentHorizontalOption)
          .default(AlignmentHorizontalOption.CENTER),
        vertical: z
          .nativeEnum(AlignmentVerticalOption)
          .default(AlignmentVerticalOption.CENTER),
      })
      .default({
        horizontal: AlignmentHorizontalOption.CENTER,
        vertical: AlignmentVerticalOption.CENTER,
      }),
    imageFit: z.boolean().default(false),
  }),
  z.object({
    columnType: z.literal(SrpB2bColumnType.TEXT),
    translationText: z.string().default("label"),
    width: z.number().default(70),
    headerStyle: GlobalTableCellReadoutValueScheme,
    dataStyle: GlobalTableCellReadoutValueScheme,
    prefixText: z.string().default("prefix"),
    suffixText: z.string().default("suffix"),
    prefix: z.boolean().default(false),
    prefixTextStyle: TypoReadoutValueScheme,
    prefixColor: ColorReadoutValueScheme,
    suffix: z.boolean().default(false),
    suffixTextStyle: TypoReadoutValueScheme,
    suffixColor: ColorReadoutValueScheme,
    spaceBetweenPrefixSuffix: z.number().default(5),
  }),
  z.object({
    columnType: z.literal(SrpB2bColumnType.UNITS),
    translationText: z.string().default("label"),
    width: z.number().default(70),
    headerStyle: GlobalTableCellReadoutValueScheme,
    dataStyle: GlobalTableCellReadoutValueScheme,
    dropdownStyle: DropdownReadoutValueScheme,
  }),
  z.object({
    columnType: z.literal(SrpB2bColumnType.ACTIONS),
    translationText: z.string().default("label"),
    width: z.number().default(70),
    headerStyle: GlobalTableCellReadoutValueScheme,
    dataStyle: GlobalTableCellReadoutValueScheme,
    actionInput: InputReadoutValueScheme,
    actionPlusButton: ButtonReadoutValueScheme,
    actionMinusButton: ButtonReadoutValueScheme,
    actionPlusLabel: z.string().default("+"),
    actionMinusLabel: z.string().default("-"),
    actionSpaceBetweenInputButtons: z.number().default(5),
    actionAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.CENTER),
  }),
]);

export const BoxSearchResultsB2BContentSettingsScheme = z
  .object({
    tableAttributes: z.record(TableAttributeSettingsScheme).default({}),
    contentType: z
      .record(
        z.nativeEnum(BoxSearchResultsB2BEndpointType),
        z.array(z.string())
      )
      .default({
        PRODUCT_CATALOG: [],
        ACTIONS: [],
      }),
    paginationLabel: z.string().default("Pokaż na stronie"),
    columnsOrder: z.array(z.string()).default([]),
  })
  .default({
    tableAttributes: {},
    paginationLabel: "Pokaż na stronie",
    columnsOrder: [],
    contentType: {
      PRODUCT_CATALOG: [],
      ACTIONS: [],
    },
  });

export type ISrpB2bAttributeData = z.infer<typeof TableAttributeSettingsScheme>;

export const BoxSearchResultsB2BDisplaySettingsScheme = z
  .object({
    fixedTableSize: z.boolean().default(false),
    fixedWidth: z.number().default(1000),
    fixedMaxHeight: z.number().default(600),
    fixedMaxColumnWidth: z.number().default(400),
    autoMaxWidth: z.number().default(1000),
    autoMaxHeight: z.number().default(600),
    tableBackground: ColorReadoutValueScheme,
    tableBorder: Border_v2Scheme.default({
      radius: 0,
      width: { left: 1, right: 1, top: 1, bottom: 1 },
      style: "SOLID",
      color: {
        id: "color$$1",
        custom: "#FFFFFF",
      },
    }),
    padding: SpacingScheme.default({ top: 5, bottom: 5, left: 5, right: 5 }),
    columnsSpacing: z.number().default(5),
    rowsSpacing: z.number().default(5),
    spacingBetweenHeaderAndData: z.number().default(5),
    stickyHeader: z.boolean().default(true),
    headerHeight: z.number().default(80),
    rowHeight: z.number().default(60),
    sorting: z
      .object({
        show: z.boolean().default(true),
        iconColor: ColorReadoutValueScheme,
        iconColorActive: ColorReadoutValueScheme,
        iconSize: z.number().default(24),
      })
      .default({
        show: true,
        iconColor: { id: "color$$1", custom: "#FFFFFF" },
        iconColorActive: { id: "color$$1", custom: "#FFFFFF" },
        iconSize: 24,
      }),
    pagination: z
      .object({
        show: z.boolean().default(true),
        afterRows: z.number().default(100),
        alignment: z
          .object({
            horizontal: z
              .nativeEnum(AlignmentHorizontalOption)
              .default(AlignmentHorizontalOption.CENTER),
            vertical: z
              .nativeEnum(AlignmentVerticalOption)
              .default(AlignmentVerticalOption.CENTER),
          })
          .default({
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          }),
        textStyle: TypoReadoutValueScheme,
        textColor: ColorReadoutValueScheme,
      })
      .default({
        show: true,
        afterRows: 100,
        alignment: {
          horizontal: AlignmentHorizontalOption.CENTER,
          vertical: AlignmentVerticalOption.CENTER,
        },
        textStyle: { id: "typo$$1", custom: {} },
        textColor: { id: "color$$1", custom: "#FFFFFF" },
      }),
  })
  .default({
    fixedTableSize: false,
    fixedWidth: 1000,
    fixedMaxHeight: 600,
    fixedMaxColumnWidth: 400,
    autoMaxWidth: 1000,
    autoMaxHeight: 600,
    tableBackground: "color$$1",
    tableBorder: defaultBorder,
    padding: { top: 5, bottom: 5, left: 5, right: 5 },
    columnsSpacing: 10,
    rowsSpacing: 10,
    spacingBetweenHeaderAndData: 5,
    stickyHeader: true,
    headerHeight: 80,
    rowHeight: 60,
    sorting: {
      show: true,
      iconColor: { id: "color$$1", custom: "#FFFFFF" },
      iconColorActive: { id: "color$$1", custom: "#FFFFFF" },
      iconSize: 24,
    },
    pagination: {
      show: true,
      afterRows: 100,
      alignment: {
        horizontal: AlignmentHorizontalOption.CENTER,
        vertical: AlignmentVerticalOption.CENTER,
      },
      textStyle: { id: "typo$$1", custom: {} },
      textColor: { id: "color$$1", custom: "#FFFFFF" },
    },
  });

export const BoxSearchResultsB2BContentScheme = z.object({}).default({});

export const BoxSearchResultsB2BSettingsScheme = z.object({
  package_type_dropdown_id:
    themeScheme.DropdownIdScheme.default("dropdown$$1").catch("dropdown$$1"),
  change_product_quantity_input_id:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  product_page_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  srp_mobile_sorting_parameter_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_parameter_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  srp_mobile_sorting_parameter_top_background_color:
    themeScheme.ColorIdScheme.default("color$$4").catch("color$$4"),
  srp_mobile_sorting_parameter_value_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_sorting_parameter_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_expand_button_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_expand_button_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").default("snackbar$$1"),
});

export const BoxSearchResultsB2BMessagesScheme = z.object({
  srp_mobile_sorting_label:
    themeScheme.MessageScheme.default("Sortuj").catch("Sortuj"),
  srp_mobile_sorting_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki").catch("Pokaż wyniki"),
  scoreSortText:
    themeScheme.MessageScheme.default("Polecane").catch("Polecane"),
  created_atSortText:
    themeScheme.MessageScheme.default("Najnowsze").catch("Najnowsze"),
  ascPriceSortText:
    themeScheme.MessageScheme.default("Najniższa cena").catch("Najniższa cena"),
  descPriceSortText:
    themeScheme.MessageScheme.default("Najwyższa cena").catch("Najwyższa cena"),
  srp_mobile_expand_button_label:
    themeScheme.MessageScheme.default("Sortowanie").catch("Sortowanie"),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
});

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
  NONE = "none",
}

export enum SortType {
  RECOMMENDED = "score",
  LATEST = "created_at",
  PRICE = "price",
}

export interface SortOption {
  type: SortType;
  direction: SortDirection;
}
