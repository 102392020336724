import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";

import {
  BoxSearchFiltersContentScheme,
  BoxSearchFiltersContentSettingsScheme,
  BoxSearchFiltersDisplaySettingsScheme,
  IBoxSearchFiltersMessagesScheme,
  IBoxSearchFiltersSettingsScheme,
} from "./BoxSearchFilters.scheme";

export enum FacetStyle {
  ACCORDION = "ACCORDION",
  LIST = "LIST",
}

export enum RANGE_DISPLAY_STYLE {
  INPUTS = "INPUTS",
  SLIDER = "SLIDER",
  SLIDER_INPUTS = "SLIDER_INPUTS",
}

export type IBoxSearchFiltersData = GenericBoxData<
  IBoxSearchFiltersContent,
  IBoxSearchFiltersContentSettings,
  IBoxSearchFiltersDisplaySettings,
  IBoxSearchFiltersMessages,
  IBoxSearchFiltersSettings
>;

export type IBoxSearchFiltersMessages = z.infer<
  typeof IBoxSearchFiltersMessagesScheme
>;
export type IBoxSearchFiltersSettings = z.infer<
  typeof IBoxSearchFiltersSettingsScheme
>;

export type IBoxSearchFiltersContentSettings = z.infer<
  typeof BoxSearchFiltersContentSettingsScheme
>;

export type IBoxSearchFiltersContent = z.infer<
  typeof BoxSearchFiltersContentScheme
>;

export type IBoxSearchFiltersDisplaySettings = z.infer<
  typeof BoxSearchFiltersDisplaySettingsScheme
>;

export type BoxSearchFiltersProps = IPortalBox<IBoxSearchFiltersData>;

export interface AttributeValueViewModel {
  name: string;
  count: number;
  checked: boolean;
}

export interface Attribute {
  name: string;
  translation: string | null;
  values: AttributeValueViewModel[];
}

export interface FacetVM {
  attributeId: string;
  translation: string;
  values: AttributeValueViewModel[];
  min?: string;
  max?: string;
}

export interface ISearchFacetProps
  extends Omit<
    IBoxSearchFiltersData,
    | "messages"
    | "settings"
    | "mobileSettings"
    | "mobileDisplaySettings"
    | "mobileContentSettings"
  > {
  facet: FacetVM;
  selectValue: (checked: boolean, facetId: string, value: string) => void;
}

export interface ISearchFiltersProps {
  contentSettings: IBoxSearchFiltersContentSettings;
  displaySettings: IBoxSearchFiltersDisplaySettings;
  content: IBoxSearchFiltersContent;
}
