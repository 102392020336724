import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import Loader from "@ecp-boxes/shared/components/Loader/Loader";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";

const CartLoader = ({ size = 24, borderSize = 2 }) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const theme = useTheme() as IThemeState;
  return (
    <Loader
      size={size}
      borderSize={borderSize}
      borderColor={convertColorIdToHex(
        settings.cart_color_secondary,
        theme.colorPalette
      )}
    />
  );
};
export default CartLoader;
