import { MOBILE_BREAKPOINT } from "@ecp-boxes/global/global";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import {
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import styled from "styled-components";
import { css } from "styled-components";
export const StyledProductsCartElementExtended = styled.div<{
  unvailableProduct: boolean;
  bubbleBgColor: TColorId;
  testTypo: TTypoId;
}>(({ unvailableProduct, bubbleBgColor, testTypo, theme }) => {
  const bgBubble = convertColorIdToHex(bubbleBgColor, theme.colorPalette);

  const typo = convertTypoIdToValues(testTypo, theme.typography);

  return css`
    position: relative;
    display: grid;
    grid-template-columns: 30% 1fr;

    @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
      grid-template-columns: 20% 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .product-cart-element {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      &__image {
        display: flex;
        width: 100%;
        grid-column-start: 1;
        grid-row: span 2 / span 2;
        grid-row-start: 1;
        ${unvailableProduct && "opacity: 0.2;"};
        position: relative;
        height: fit-content;
        justify-content: center;
      }

      &__info_details {
        margin-left: 10px;
        grid-column-start: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }

      &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        grid-column: span 11;
        width: 100%;
      }

      &__suggested-retail-price {
        text-decoration: line-through;
      }

      &__attributes-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 4px;
      }

      &__delivery-time {
        display: flex;
        grid-column-start: 2;
        margin-left: 10px;
        align-self: flex-end;
        grid-row-start: 2;
      }

      &__delivery-time-details {
        flex-shrink: 0;
      }

      &__bubble-quantity {
        background-color: ${bgBubble};
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(10px, -10px);
        max-height: ${typo.lineHeight}px;
        max-width: ${typo.lineHeight}px;
        aspect-ratio: 1 / 1;
        padding: 1em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .product_cart_info {
      &__text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
      }
      &__promo-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .product-cart-element {
      display: flex;
      grid-column-start: 1;
      height: 100%;
      padding-top: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
        grid-column-start: 3;
        grid-row: span 2;
        margin: 0;
        padding: 0;
      }
    }

    .product_cart_quantity {
      display: flex;
      position: relative;
      flex-direction: column;
      grid-column-start: 2;
      height: 100%;
      width: 100%;
      justify-content: center;
      justify-self: flex-end;
      justify-content: flex-end;
      padding: 0 10px 10px 0;
      margin: 10px 0;

      &__icons {
        position: absolute;
        top: 0;
        right: 0;
      }

      &__remove-button {
        position: absolute;
        top: 0;
        right: 0;
        :hover {
          opacity: 0.2;
        }
      }

      &__total-price {
        display: flex;
        min-width: 0px;
      }

      &__total-price-details {
        flex-shrink: 0;
        width: 100%;
        text-align: left;
        margin-left: 10px;
      }
      &__suggested-retail-price {
        flex-shrink: 0;
        width: 100%;
        text-decoration: line-through;
        text-align: left;
        margin-left: 10px;
      }

      @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
        position: absolute;
        width: max-content;
        grid-column-start: 3;
        justify-content: flex-start;
        right: 10px;
        padding: 0px;
        margin-top: 0;
        &__total-price-details {
          text-align: right;
        }
        &__suggested-retail-price {
          text-align: right;
        }
      }
    }
  `;
});
