import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import CartLoader from "../shared/CartLoader";
import { PlaceOrderButtonContainer } from "./PlaceOrderButton.styled";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  AddressPostalBilling,
  IBillingPostalAddAddressExtendedRequest,
} from "@ecp-redux/dto/cartAddresses.type";
import Paypal from "../payments/Paypal";
const PlaceOrderButton = ({
  onClick,
  isPaymentProcessing,
  isPaypalPaymentSelected,
  paymentAutofillAddress,
  handlePurchase,
}: {
  onClick: () => void;
  isPaymentProcessing: boolean;
  isPaypalPaymentSelected: boolean;
  handlePurchase: (nonce?: string) => void;
  paymentAutofillAddress:
    | AddressPostalBilling
    | IBillingPostalAddAddressExtendedRequest
    | null;
}) => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { isFetching: isCartFetching } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const { isEverySectionValid } = useBoxCartStepTwoExtended();
  const isProcessing = isPaymentProcessing || isCartFetching;
  return isPaypalPaymentSelected ? (
    <Paypal
      handlePurchase={handlePurchase}
      paymentAutofillAddress={paymentAutofillAddress}
    />
  ) : (
    <PlaceOrderButtonContainer
      $isPaymentProcessing={isProcessing}
      className="place-order-btn"
    >
      <StyledButton
        $settings={settings.basket_step_2_3_button_summary}
        renderAs={"button"}
        disabled={!isEverySectionValid || isProcessing}
        onClick={onClick}
      >
        <CartLoader />
        <p>{messages.summary_button_next_step}</p>
      </StyledButton>
    </PlaceOrderButtonContainer>
  );
};
export default PlaceOrderButton;
