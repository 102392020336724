import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import {
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledText from "../../styleElements/StyledText/StyledText";
import {
  CustomRadioButton,
  Item,
  RadioButton,
  RadioButtonLabel,
} from "./Radio.styled";
import { PropsWithChildren } from "react";

interface IRadioTextStyles {
  labelTypo: TTypoId;
  labelColor: TColorId;
}

interface IFormRadioProps<T extends FieldValues> extends UseControllerProps<T> {
  label: JSX.Element | string;
  textStyles: IRadioTextStyles;
  value?: string;
  defaultChecked?: boolean;
}

interface RadioProps extends PropsWithChildren<{}> {
  checked: boolean;
  onClick: () => void;
  className?: string;
  customIcon?: React.ReactNode;
}

export const Radio: React.FC<RadioProps> = ({
  checked,
  onClick,
  children,
  className,
  customIcon,
}): JSX.Element => {
  return (
    <Item className={className}>
      <RadioButton
        type="radio"
        checked={checked}
        data-testid="radio-button"
        onClick={onClick}
      />
      <RadioButtonLabel />
      {children}
    </Item>
  );
};

export const FormRadio = <T extends FieldValues>(
  props: IFormRadioProps<T>
): JSX.Element => {
  const { label, textStyles, defaultChecked, value } = props;
  const { field } = useController(props);
  return (
    <Item>
      {label !== null && (
        <>
          <RadioButton
            type="radio"
            {...field}
            value={value}
            checked={field.value === value}
            defaultChecked={defaultChecked}
          />
          <RadioButtonLabel />
          <StyledText
            $settings={{
              font: textStyles.labelTypo,
              text: { color: textStyles.labelColor },
            }}
          >
            {label}
          </StyledText>
        </>
      )}
    </Item>
  );
};

interface ICustomRadioProps extends RadioProps {
  fullfiledColor: TColorId;
  emptyColor: TColorId;
  outlineColor: TColorId;
  onChange: () => void;
}
export const CustomRadio: React.FC<ICustomRadioProps> = ({
  checked,
  onClick,
  fullfiledColor,
  emptyColor,
  outlineColor,
  onChange,
}): JSX.Element => {
  return (
    <CustomRadioButton
      type="radio"
      checked={checked}
      data-testid="customRadio-button"
      onClick={onClick}
      onChange={onChange}
      fullfiledColor={fullfiledColor}
      emptyColor={emptyColor}
      outlineColor={outlineColor}
    />
  );
};
