import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxStepBarMessagesScheme = z.object({
  labels: themeScheme.MessageArrayScheme.default(
    ""
  ),
});

export const IBoxStepBarSettingsScheme = z.object({
  labels_active_state_urls: themeScheme.UrlArrayScheme.default(
    "/"
  ),
  icons_url: themeScheme.UrlScheme.default("").catch(""),
  icons_size: themeScheme.SizeCSSScheme.default("24px").catch("24px"),
  icons_default_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  icons_active_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  labels_typography: themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  labels_color: themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  label_active_state_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
