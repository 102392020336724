import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";

const PaypalExtraComponent = () => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  return (
    <div className="payment-container">
      <StyledText
        $settings={{
          text: { color: settings.cart_color_primary },
          font: settings.section_subtext_typography,
        }}
      >
        {messages.payment_PAYPAL_description}
      </StyledText>
    </div>
  );
};
export default PaypalExtraComponent;
