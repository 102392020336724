import styled, {
  FlattenSimpleInterpolation,
  css,
  useTheme,
} from "styled-components";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isPortalSide } from "../../helpers/helpers";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { useRowPopup } from "./useRowPopup";

export const StyledRowPopupContainer = styled.div(
  ({
    $settings,
    theme: { colorPalette },
    isPopupOpen,
  }: {
    $settings: IDisplaySettingsSection;
    isPopupOpen: boolean;
    theme: IThemeState;
  }) => {
    const isPortal = isPortalSide();
    const isMobile = useIsMobilePortal();
    const themeSettings = useTheme() as IThemeState;
    const { elemLeft } = useRowPopup();

    const { scrollBehind, overlayColor } = $settings.popup;

    let stylesForEditMode: FlattenSimpleInterpolation | string = "";
    if (elemLeft) {
      stylesForEditMode = css`
        left: ${elemLeft}px;
        width: ${themeSettings.stylePages.pages.maxWidth}px;
      `;
    }
    return css`
      position: fixed;
      z-index: var(--zindex-row-popup);
      ${isPortal || isMobile
        ? css`
            width: 100%;
            inset: 0;
            overflow-y: scroll;
            overflow-x: hidden;
          `
        : css`
            inset: 0 0 0 auto;
            width: calc(
              100% - (var(--width-sidebar-menu) + var(--width-sidebar-content))
            );
            height: calc(100% - (var(--height-pb-header)));
            margin-top: var(--height-pb-header);
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: none;
            ${stylesForEditMode}
          `}
      ${!isPopupOpen && "pointer-events: none;"}
      .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${convertColorIdToHex(overlayColor, colorPalette)};
        pointer-events: ${scrollBehind && isPortal ? "none" : "auto"};
      }
    `;
  }
);
