import { useEffect, useMemo } from "react";
import * as searchResults from "@ecp-redux/api/searchResults";

export const useProductInfo = (
  productsInfo: Required<{ productSku: string }>[] | undefined
) => {
  const [getProducts, products] = searchResults.useLazyGetPostProductsQuery();

  const productsSkus = useMemo(
    () => productsInfo?.map((e) => e.productSku),
    [productsInfo]
  );

  const UniqueProductsSkus = [...new Set(productsSkus)];

  useEffect(() => {
    if (productsSkus?.length && productsSkus.length > 0) {
      getProducts(
        {
          projection: ["coverPhoto", "url", "deliveryTime", "wishlist", "sku"],
          skus: UniqueProductsSkus,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts, productsSkus]);

  const getProductInfo = (sku: string) =>
    products.data?.products?.find((e) => e.sku === sku);

  return {
    getProductInfo,
    isLoadingProduct: products.isLoading,
  };
};
