import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxPaypalPaymentMessagesScheme = z.object({
  card_number: z.string().default("Card Number"),
  expiration_date: z.string().default("Valid Thru"),
  cvv: z.string().default("CVV"),
  payment_method: z.string().default("Payment Method"),
  continue: z.string().default("Continue"),
  card_number_placeholder: z.string().default("Card Number"),
  expiration_date_placeholder: z.string().default("MM/YY"),
  cvv_placeholder: z.string().default("CVV"),
});

export const IBoxPaypalPaymentSettingsScheme = z.object({
  header_typo: themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  header_color: themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  input_style: themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  continue_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
});
