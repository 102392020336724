import { IGetCustomerData } from "@ecp-redux/dto/myData.types";
import { createContext, useContext, useState } from "react";
import { ChannelType, IDeliveryChannel } from "@ecp-redux/dto/cart.types";
import { produce } from "immer";
import { IBillingPostalAddAddressExtendedRequest } from "@ecp-redux/dto/cartAddresses.type";
export interface IBoxCartStepTwoExtendedContextType {
  selectedDeliveryChannel: IDeliveryChannel | undefined;
  customerData: IGetCustomerData | undefined;
  isLogged: boolean;
  amountToPay: number | undefined;
  setIsSameBillingAddress: React.Dispatch<React.SetStateAction<boolean>>;
  isSameBillingAddress: boolean;
  selectedAddress: IBillingPostalAddAddressExtendedRequest | null;
  setSelectedAddress: React.Dispatch<
    React.SetStateAction<IBillingPostalAddAddressExtendedRequest | null>
  >;
  selectedBillingAddress: IBillingPostalAddAddressExtendedRequest | null;
  setSelectedBillingAddress: React.Dispatch<
    React.SetStateAction<IBillingPostalAddAddressExtendedRequest | null>
  >;
}

type TSectionValidationLookup = {
  contact: boolean;
  address: boolean;
  payment: boolean;
  billingAddress: boolean;
  delivery: boolean;
};

interface IInnerContextValues {
  isEverySectionValid: boolean;
  setSectionValidation: (
    section: keyof TSectionValidationLookup,
    isValid: boolean
  ) => void;
  sectionValidationLookup: TSectionValidationLookup;
  isSameBillingAddress: boolean | undefined;
  isDeliveryChannelSelected: boolean;
  isPackstationSelected: boolean;
  isAnyPackstationAvailable: boolean;
  isPickupInStoreSelected: boolean;
}

export const BoxCartStepTwoExtendedContext = createContext<
  IBoxCartStepTwoExtendedContextType & IInnerContextValues
>({ selectedAddress: null } as IBoxCartStepTwoExtendedContextType &
  IInnerContextValues);

export const useBoxCartStepTwoExtended = () => {
  const context = useContext(BoxCartStepTwoExtendedContext);

  if (!context) {
    throw new Error(
      "useBoxCartStepTwoExtended must be used within a BoxCartStepTwoExtendedProvider"
    );
  }

  return context;
};

export const BoxCartStepTwoExtendedContextProvider = ({
  children,
  data,
}: React.PropsWithChildren<{
  data: IBoxCartStepTwoExtendedContextType;
}>) => {
  const [sectionValidationLookup, setSectionValidationLookup] =
    useState<TSectionValidationLookup>({
      contact: false,
      address: false,
      payment: false,
      billingAddress: false,
      delivery: false,
    });

  const setSectionValidation = (
    section: keyof TSectionValidationLookup,
    isValid: boolean
  ) => {
    setSectionValidationLookup(
      produce((draft) => {
        draft[section] = isValid;
      })
    );
  };

  const isEverySectionValid = Object.values(sectionValidationLookup).every(
    (isValid) => isValid
  );

  const isPackstationSelected =
    data.selectedDeliveryChannel?.channelType === ChannelType.INPOST;
  const isPickupInStoreSelected =
    data.selectedDeliveryChannel?.channelType === ChannelType.STORE;

  const isAnyPackstationAvailable = !!(
    data.selectedDeliveryChannel?.addresses &&
    data.selectedDeliveryChannel?.addresses?.length > 0
  );

  const isDeliveryChannelSelected =
    data.selectedDeliveryChannel?.channelType !== undefined;

  return (
    <BoxCartStepTwoExtendedContext.Provider
      value={{
        ...data,
        isEverySectionValid,
        setSectionValidation,
        sectionValidationLookup,
        isDeliveryChannelSelected,
        isPackstationSelected,
        isAnyPackstationAvailable,
        isPickupInStoreSelected,
      }}
    >
      {children}
    </BoxCartStepTwoExtendedContext.Provider>
  );
};
