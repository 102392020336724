import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import styled, { css } from "styled-components";

export const StyledRadioSelect = styled.div<{
  $radioButtonColor: TColorId;
  $selected: boolean;
  selectionColor: TColorId;
}>(({ $radioButtonColor, theme, $selected, selectionColor }) => {
  const color = convertColorIdToHex($radioButtonColor, theme.colorPalette);
  const bgSelectionColor = convertColorIdToHex(
    selectionColor,
    theme.colorPalette
  );
  return css`
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    cursor: pointer;
    .radio-select__dot {
      position: relative;
      top: 1px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid ${color};
      flex-shrink: 0;
    }
    .radio-select__content {
      width: inherit;
    }

    ${$selected &&
    css`
      background-color: ${bgSelectionColor} !important;
      .radio-select__dot::after {
        content: "";
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${color};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
  `;
});
