import { useState } from "react";
import { IStoreLocator } from "@ecp-redux/dto/storesLocator.types";
import ChevronIcon from "../../../global/components/ChevronIcon/ChevronIcon";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { ReactComponent as PinIcon } from "../../../shared/icons/pin.svg";
import { StyledBackground } from "../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { StyledStoresDisplayMobile } from "../BoxStoreLocator.styled";
import StoreLocatorMap from "../StoreLocatorMap/StoreLocatorMap";
import StoreLocatorMapListElement from "../StoreLocatorMap/StoreLocatorMapListElement";
import StoresSearchBar from "./StoresSearchBar";

export interface IStoresDisplayMobileProps {
  messages: any;
  settings: any;
  fetchStores: (val: string) => void;
  data: any;
  refreshMapView: boolean;
  isEmptySearchValue: boolean;
  onSelectAddressButtonClick?: (storeCode: string) => void;
}

const StoresDisplayMobile: React.FC<IStoresDisplayMobileProps> = ({
  messages,
  settings,
  fetchStores,
  data,
  onSelectAddressButtonClick,
  refreshMapView,
  isEmptySearchValue,
}: IStoresDisplayMobileProps) => {
  const [tableView, setTableView] = useState(true);
  const [storeCode, setStoreCode] = useState("");
  const [storeSearchPhrase, setStoreSearchPhrase] = useState("");

  const handleStoreCode = (code: string) => {
    setStoreCode(code);
    setTableView(true);
  };

  return (
    <StyledStoresDisplayMobile
      className="stores-display-mobile"
      mapUsedInPopup={onSelectAddressButtonClick !== undefined}
    >
      {tableView && (
        <div className="stores-display-mobile__top">
          <StoresSearchBar
            onClickButton={fetchStores}
            storeSearchPhrase={storeSearchPhrase}
            setStoreSearchPhrase={setStoreSearchPhrase}
          />

          <button
            onClick={() => {
              setTableView(false);
              setStoreCode("");
            }}
          >
            <StyledText
              className="stores-display-mobile__top__show-map-label"
              $settings={{
                font: settings.storelocator_mobile_show_map_typo,
                text: {
                  color: settings.storelocator_mobile_show_map_color,
                },
              }}
              show={isNotEmptyMessage(messages.storelocator_mobile_show_map)}
            >
              {messages.storelocator_mobile_show_map}
              <PinIcon />
            </StyledText>
          </button>
        </div>
      )}
      {!tableView && (
        <StyledBackground
          backgroundColor={
            settings.storelocator_mobile_show_shop_list_background_color
          }
        >
          <button
            data-testid="button-last-view"
            className="stores-display-mobile__show-shop-list-button"
            onClick={() => setTableView(true)}
          >
            <ChevronIcon width="14px" height="14px" />
            <StyledText
              $settings={{
                font: settings.storelocator_mobile_show_shop_list_typo,
                text: {
                  color: settings.storelocator_mobile_show_shop_list_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.storelocator_mobile_show_shop_list
              )}
            >
              {messages.storelocator_mobile_show_shop_list}
            </StyledText>
          </button>
        </StyledBackground>
      )}

      {tableView ? (
        <div className="stores-display-mobile__list-element">
          {data?.result?.length > 0 ? (
            data.result.map((store: IStoreLocator) => {
              const { code } = store;
              return (
                <StoreLocatorMapListElement
                  key={code}
                  store={store}
                  isActive={storeCode === code}
                  onSelectAddressButtonClick={onSelectAddressButtonClick}
                />
              );
            })
          ) : (
            <div className="stores-display-mobile__store-not-found">
              {messages.storelocator_not_found}
            </div>
          )}
        </div>
      ) : (
        <div className="stores-display-mobile__map-container">
          <StoreLocatorMap
            stores={data?.result}
            onSelectAddressButtonClick={onSelectAddressButtonClick}
            setStoreCode={handleStoreCode}
            message={messages.storelocator_not_found}
            fetchStores={fetchStores}
            refreshMapView={refreshMapView}
            isEmptySearchValue={isEmptySearchValue}
            storeSearchPhrase={storeSearchPhrase}
            setStoreSearchPhrase={setStoreSearchPhrase}
          />
        </div>
      )}
    </StyledStoresDisplayMobile>
  );
};

export default StoresDisplayMobile;
