import { memo, useEffect, useRef } from "react";
import { isEqual } from "lodash";
import styled, { DefaultTheme, css } from "styled-components";
import { IPortalPage, SectionType } from "@ecp-pageTypes";
import {
  isPortalSide,
  transformPageTypeToSectionType,
} from "../../helpers/helpers";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { CommunicationBetweenBoxesProvider } from "../Contexts/CommunicationBetweenBoxes";
import { LayoutControllerProvider } from "../Contexts/LayoutControllerContext";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import RowPopupContainer from "../RowPopup/RowPopupContainer";
import { prepereSections } from "./DynamicPage.methods";
import { useTooltipContext } from "../Contexts/TooltipContext";
import { StyledTooltip } from "@ecp-boxes/shared/styleElements/StyledTooltip/StyledTooltip";
import { usePageInPageContext } from "../PageInPage/PageInPageContext";
import { usePathname } from "next/navigation";

export interface IDynamicPageProps {
  data: IPortalPage;
  activePathExtraSettings: string;
}

export const StyledPage = styled.div<{
  theme: DefaultTheme;
  isPageInPage?: boolean;
  $isEditMode?: boolean;
}>(({ theme, $isEditMode = false, isPageInPage }) => {
  return css`
    background: ${convertColorIdToHex(
      theme.stylePages.pages.page.background.color,
      theme.colorPalette
    )};
    position: relative;
    ${isPageInPage ? "height: 100%;" : "height: 100dvh;"}
    ${theme.advanceSettings.customCss};

    ${!$isEditMode &&
    isPortalSide() &&
    !theme.stylePages.pages.headerFooter.stickyHeader
      ? "overflow-y: auto;"
      : ""}
  `;
});

export const StyledMain = styled.main<{
  theme: DefaultTheme;
}>(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    row-gap: ${theme.stylePages.section.spacingBetweenSections}px;
    width: 100%;
    flex: 1;
  `;
});

const ScrollWrapper = styled.div<{ isPageInPage: boolean }>`
  width: 100%;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  ${({ isPageInPage }) =>
    isPageInPage &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

const DynamicPage: React.FC<IDynamicPageProps> = ({
  data,
  activePathExtraSettings,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const pageInPage = usePageInPageContext();
  const pathname = usePathname();
  const type = transformPageTypeToSectionType(data?.type);

  const { tooltipData } = useTooltipContext();

  const headerSections = data?.header?.sections;
  const footerSections = data?.footer?.sections;

  const isPageInPage = pageInPage !== null;

  useEffect(() => {
    isPortalSide() &&
      scrollRef.current?.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
      });
  }, [pathname]);

  return (
    <LayoutControllerProvider boxSliderActiveId={activePathExtraSettings}>
      <CommunicationBetweenBoxesProvider>
        <StyledPage className="dynamic-page" isPageInPage={isPageInPage}>
          <ScrollWrapper
            id="scroll-wrapper"
            data-pageid={data?.id}
            isPageInPage={isPageInPage}
          >
            <RowPopupContainer />
            {!isPageInPage && <div id="scrollTopElement" ref={scrollRef} />}
            {headerSections && <Header sections={headerSections} />}

            <StyledTooltip
              id={`tooltip-${tooltipData?.imageId}`}
              $tooltipStyle={tooltipData?.tooltipStyle ?? "tooltip$$1"}
              offset={tooltipData?.spacing}
              isOpen={tooltipData !== null}
            >
              {tooltipData?.label}
            </StyledTooltip>

            <StyledMain id="portal">
              {data?.sections?.length > 0 &&
                data.sections.reduce(
                  prepereSections(data?.sections, type ?? SectionType.MAIN),
                  []
                )}
            </StyledMain>
            {footerSections && <Footer sections={footerSections} />}
          </ScrollWrapper>
        </StyledPage>
      </CommunicationBetweenBoxesProvider>
    </LayoutControllerProvider>
  );
};

export default memo(DynamicPage, isEqual);
