import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxCartStepTwoMessagesScheme = z.object({
  delivery_channel_header:
    themeScheme.MessageScheme.default("1. Sposób dostawy"),
  delivery_days: themeScheme.MessageScheme.default("Dni roboczych"),
  delivery_form: themeScheme.MessageScheme.default("Forma dostawy"),
  delivery_point_address: themeScheme.MessageScheme.default("Punkt odbioru"),
  delivery_address: themeScheme.MessageScheme.default("Adres dostawy"),
  delivery_add_new_address:
    themeScheme.MessageScheme.default("Dodaj nowy adres"),
  delivery_back_button: themeScheme.MessageScheme.default("Wróć"),
  delivery_range_unavailable: themeScheme.MessageScheme.default(
    "Informacje o dostawie niedostępne"
  ),
  delivery_message_STORE: themeScheme.MessageScheme.default("Odbiór w sklepie"),
  delivery_message_PICK_UP_AT_POINT:
    themeScheme.MessageScheme.default("PICK UP POINT"),
  delivery_message_POCZTEX: themeScheme.MessageScheme.default("POCZTEX"),
  delivery_message_DPD: themeScheme.MessageScheme.default("kurier DPD"),
  delivery_message_UPS: themeScheme.MessageScheme.default("kurier UPS"),
  delivery_message_INPOST: themeScheme.MessageScheme.default(
    "Dostawa InPost Paczkomaty 24/7"
  ),
  form_heading_first_name: themeScheme.MessageScheme.default("Imię"),
  form_placeholder_first_name: themeScheme.MessageScheme.default("Imię"),
  form_heading_last_name: themeScheme.MessageScheme.default("Nazwisko"),
  form_placeholder_last_name: themeScheme.MessageScheme.default("Nazwisko"),
  form_heading_street: themeScheme.MessageScheme.default("Ulica"),
  form_placeholder_street: themeScheme.MessageScheme.default("Ulica"),
  form_heading_house_number: themeScheme.MessageScheme.default("Dom"),
  form_placeholder_house_number: themeScheme.MessageScheme.default("Dom"),
  form_heading_apartment_number:
    themeScheme.MessageScheme.default("Mieszkanie"),
  form_placeholder_apartment_number:
    themeScheme.MessageScheme.default("Mieszkanie"),
  form_heading_city: themeScheme.MessageScheme.default("Miasto"),
  form_placeholder_city: themeScheme.MessageScheme.default("Miasto"),
  form_heading_zip_code: themeScheme.MessageScheme.default("Kod pocztowy"),
  form_placeholder_zip_code: themeScheme.MessageScheme.default("Kod pocztowy"),
  form_heading_country: themeScheme.MessageScheme.default("Kraj"),
  form_placeholder_country: themeScheme.MessageScheme.default("Kraj"),
  form_button_save: themeScheme.MessageScheme.default("Zapisz"),
  form_button_cancel: themeScheme.MessageScheme.default("Anuluj"),
  form_syntax_verification_zip_code: themeScheme.MessageScheme.default(
    "Niepoprawny kod pocztowy"
  ),
  form_syntax_verification_phone: themeScheme.MessageScheme.default(
    "Niepoprawny nr telefonu"
  ),
  form_syntax_verification_nip:
    themeScheme.MessageScheme.default("Niepoprawny nip"),
  form_heading_default_address: themeScheme.MessageScheme.default(
    "Ustaw adres domyślny"
  ),
  form_heading_phone: themeScheme.MessageScheme.default("Numer telefonu"),
  form_placeholder_phone: themeScheme.MessageScheme.default("xxx-xxx-xxx"),
  form_placeholder_company_name:
    themeScheme.MessageScheme.default("Nazwa firmy"),
  form_heading_company_name: themeScheme.MessageScheme.default("Nazwa firmy"),
  form_placeholder_nip: themeScheme.MessageScheme.default("NIP"),
  form_heading_nip: themeScheme.MessageScheme.default("NIP"),
  form_heading_company: themeScheme.MessageScheme.default("firmowy"),
  form_heading_private: themeScheme.MessageScheme.default("prywatny"),
  form_required_verification: themeScheme.MessageScheme.default(
    "To pole jest wymagane"
  ),
  billing_header: themeScheme.MessageScheme.default("2. Dane do faktury"),
  add_new_billing_address: themeScheme.MessageScheme.default(
    "Podaj adres do faktury"
  ),
  add_new_postal_address: themeScheme.MessageScheme.default(
    "Podaj adres do dostawy"
  ),
  payment_header: themeScheme.MessageScheme.default("3. Płatność"),
  payment_name_CASH_ON_DELIVERY: themeScheme.MessageScheme.default(
    "Płatność przy odbiorze"
  ),
  payment_name_E_WALLET: themeScheme.MessageScheme.default("Portfel e-wallet"),
  payment_name_GIFT_CARD:
    themeScheme.MessageScheme.default("Karta podarunkowa"),
  payment_name_PAYMENT_IN_ADVANCE:
    themeScheme.MessageScheme.default("Płatność z góry"),
  payment_method: themeScheme.MessageScheme.default("Metoda płatności"),
  summary_button_next_step:
    themeScheme.MessageScheme.default("Zamawiam i płacę"),
  summary_amount_to_pay:
    themeScheme.MessageScheme.default("Łączna kwota z vat"),
  summary_delivery_costs_before_select:
    themeScheme.MessageScheme.default("Dostawa od"),
  summary_delivery_costs_after_select:
    themeScheme.MessageScheme.default("Dostawa"),
  summary_save_ammont: themeScheme.MessageScheme.default("Oszczędzasz"),
  summary_products_price: themeScheme.MessageScheme.default("Cena produktów"),
  summary_header: themeScheme.MessageScheme.default("Podsumowanie"),
  basket_step_choose_shop_button:
    themeScheme.MessageScheme.default("Wybierz sklep"),
  store_locator_open_hours:
    themeScheme.MessageScheme.default("Godziny otwarcia:"),
  storelocator_view_label: themeScheme.MessageScheme.default("Widok: "),
  store_search_bar_search_input_placeholder_text:
    themeScheme.MessageScheme.default("Wpisz miejscowość, kod pocztowy"),
  storelocator_select_address_label:
    themeScheme.MessageScheme.default("Wybierz adres"),
  storelocator_not_found: themeScheme.MessageScheme.default(
    "Nie znaleziono sklepu"
  ),
  storelocator_mobile_show_map: themeScheme.MessageScheme.default("Pokaż mapę"),
  storelocator_mobile_show_shop_list:
    themeScheme.MessageScheme.default("Lista sklepów"),
  store_locator_open_hours_monday:
    themeScheme.MessageScheme.default("Poniedziałek"),
  store_locator_open_hours_tuesday: themeScheme.MessageScheme.default("Wtorek"),
  store_locator_open_hours_wednesday:
    themeScheme.MessageScheme.default("Środa"),
  store_locator_open_hours_thursday:
    themeScheme.MessageScheme.default("Czwartek"),
  store_locator_open_hours_friday: themeScheme.MessageScheme.default("Piątek"),
  store_locator_open_hours_saturday:
    themeScheme.MessageScheme.default("Sobota"),
  store_locator_open_hours_sunday:
    themeScheme.MessageScheme.default("Niedziela"),
  basket_step_default_address:
    themeScheme.MessageScheme.default("Adres domyślny"),
  basket_unavailable_delivery_channels_alert: themeScheme.MessageScheme.default(
    "Na ten moment nie możesz złożyć zamówienia"
  ),
  mobile_top_header: themeScheme.MessageScheme.default("Koszyk"),
  basket_missing_billing_address_alert: themeScheme.MessageScheme.default(
    "Brak adresu do faktury"
  ),
  basket_missing_delivery_address_alert: themeScheme.MessageScheme.default(
    "Brak adresu dostawy"
  ),
  basket_missing_payment_method_alert: themeScheme.MessageScheme.default(
    "Brak metody płatności"
  ),
  order_amount_not_cover_amount_limits: themeScheme.MessageScheme.default(
    "Kwota zamówienia nie spełnia limitów kwotowych dla wybranego kanału dostawy."
  ),
  basket_no_products_has_been_added_alert: themeScheme.MessageScheme.default(
    "Nie dodano żadnych produktów"
  ),
  packstation_error_text: themeScheme.MessageScheme.default(
    "Błąd podczas zapisu punktu odbioru"
  ),
  basket_packstation_not_available_alert: themeScheme.MessageScheme.default(
    "Punkt odbioru jest niedostępny"
  ),
  delete_delivery_address_popup_message: themeScheme.MessageScheme.default(
    "Czy na pewno chcesz usunąć tę pozycję z listy adresów?"
  ),
  delete_delivery_address_popup_title: themeScheme.MessageScheme.default(
    "Usuń adres paczkomatu"
  ),
  delete_delivery_address_popup_delete_button_message:
    themeScheme.MessageScheme.default("Usuń"),
  delete_delivery_address_popup_cancel_button_message:
    themeScheme.MessageScheme.default("Anuluj"),
});

export const IBoxCartStepTwoSettingsScheme = z.object({
  basket_step_all_summary_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  basket_step_all_main_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_step_all_second_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  basket_step_all_back_button:
    themeScheme.ButtonIdScheme.default("button$$4").catch("button$$4"),
  basket_step_all_background_color:
    themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  basket_step_all_summary_background_color:
    themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  basket_step_all_background_adress_payment_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  basket_step_2_3_typography_header_1:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  basket_step_2_3_typography_paragraph_4:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  basket_step_2_typography_paragraph_3:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  basket_step_all_typography_paragraph_1:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  basket_step_all_typography_paragraph_2:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  basket_step_all_color_second:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  basket_step_all_color_main:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_2_full_input:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  basket_step_2_half_input:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  basket_step_2_3_button_summary:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_step_0_typography_header_2:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  basket_step_2_checkbox_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  basket_step_2_checbox_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delivery_image_UPS: themeScheme.UrlScheme.default(
    "https://cdn4.iconfinder.com/data/icons/logos-and-brands-1/512/351_Ups_logo-512.png"
  ).catch(
    "https://cdn4.iconfinder.com/data/icons/logos-and-brands-1/512/351_Ups_logo-512.png"
  ),
  delivery_image_DPD: themeScheme.UrlScheme.default(
    "https://play-lh.googleusercontent.com/kJltYYZirCp43XAmh5Dei8QhG7tlkQcDyd_sWmSj97a1wDiEPI5kmiISHoYcx3vGEQM"
  ).catch(
    "https://play-lh.googleusercontent.com/kJltYYZirCp43XAmh5Dei8QhG7tlkQcDyd_sWmSj97a1wDiEPI5kmiISHoYcx3vGEQM"
  ),
  delivery_image_INPOST: themeScheme.UrlScheme.default(
    "https://play-lh.googleusercontent.com/U42IZv6a-m7qO6fi7MwNw76kVmxpO94RzpoT_s83MtWxyE4qih7-gpRifHpZCtC8CBU"
  ).catch(
    "https://play-lh.googleusercontent.com/U42IZv6a-m7qO6fi7MwNw76kVmxpO94RzpoT_s83MtWxyE4qih7-gpRifHpZCtC8CBU"
  ),
  delivery_image_POCZTEX: themeScheme.UrlScheme.default(
    "https://play-lh.googleusercontent.com/lhXPcKc5RkWQILlyHIpVoEV2b8C7YkDgz6kzlavFbGSeM2oGbeG6hPo6xGwbOHqIrA"
  ).catch(
    "https://play-lh.googleusercontent.com/lhXPcKc5RkWQILlyHIpVoEV2b8C7YkDgz6kzlavFbGSeM2oGbeG6hPo6xGwbOHqIrA"
  ),
  delivery_image_PICK_UP_AT_POINT: themeScheme.UrlScheme.default(
    "https://icons.veryicon.com/png/o/system/star-and-home-distribution-system-management/pick-up-point-management.png"
  ).catch(
    "https://icons.veryicon.com/png/o/system/star-and-home-distribution-system-management/pick-up-point-management.png"
  ),
  delivery_image_STORE: themeScheme.UrlScheme.default(
    "https://icons.veryicon.com/png/o/system/star-and-home-distribution-system-management/pick-up-point-management.png"
  ).catch(
    "https://icons.veryicon.com/png/o/system/star-and-home-distribution-system-management/pick-up-point-management.png"
  ),
  basket_step_2_summary_order_url: themeScheme.UrlScheme.default(
    "/informacje-o-zamowieniu"
  ).catch("/informacje-o-zamowieniu"),
  basket_step_2_url_step_0:
    themeScheme.UrlScheme.default("/cart").catch("/cart"),
  basket_step_choose_shop_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  storelocator_store_adress_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_store_adress_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_open_typography:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  storelocator_store_open_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_www_typography:
    themeScheme.TypoIdScheme.default("typo$$10").catch("typo$$10"),
  storelocator_store_www_color:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  storelocator_store_picked_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  storelocator_image_width:
    themeScheme.SettingNumberScheme.default("232").catch("232"),
  store_search_bar_search_input_settings:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  store_search_bar_search_button_settings:
    themeScheme.ButtonIdScheme.default("button$$3").catch("button$$3"),
  store_search_bar_wrapper_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  storelocator_view_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_view_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_select_address_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  storelocator_mobile_show_map_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_map_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_shop_list_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_background_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  basket_step_choose_default_address_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_choose_default_address_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  basket_alert_background:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  basket_alert_typo_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_alert_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  basket_step_2_top_header_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_2_top_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_2_top_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_store_email_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_email_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_no_image_plug: themeScheme.UrlScheme.default(
    "/ecp-media-cms/store_locator.png"
  ).catch("/ecp-media-cms/store_locator.png"),
  basket_step_all_amount_to_pay_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_not_found_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_not_found_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  packstation_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  delete_delivery_address_popup_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  delete_delivery_address_popup_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delete_delivery_address_popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  delete_delivery_address_popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  delete_delivery_address_popup_delete_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  delete_delivery_address_popup_cancel_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  delete_delivery_address_popup_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_all_summary_fixed_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_all_summary_fixed_enabled:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
  basket_step_all_summary_sticky_enabled:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
});
