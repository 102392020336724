import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import SectionWrapper from "./SectionWrapper";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useEffect, useState } from "react";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import AddressElement from "../shared/AddressElement";
import * as cartAPI from "@ecp-redux/api/cart";
import SelectionTable from "../shared/SelectionTable";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { StyledPickUpInStoreSection } from "./PickUpInStoreSection.styled";
import SelectStoreFromMapPopup from "@ecp-boxes/boxes/BoxMyAccountMyAdressList/elements/SelectStoreFromMapPopup";
import { useOpenPortalSnackbar } from "@ecp-boxes/shared/components/Snackbar/Snackbar";
import { AddressType } from "@ecp-redux/dto/cartAddresses.type";
const PickUpInStoreSection = () => {
  const { settings, messages } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNewStoreMode, setIsAddNewStoreMode] = useState(false);
  const [addStoreAddress] =
    cartAPI.usePostCartAddressByPortalUserTokenMutation();
  const [changeSelectedAddress] =
    cartAPI.usePostChangeCartSelectedAddressMutation();
  const { selectedDeliveryChannel, isLogged } = useBoxCartStepTwoExtended();
  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const { addresses = [], selectedAddress: selectedStore } =
    selectedDeliveryChannel ?? {};

  const stores = (isLogged ? addresses : [selectedStore])
    .filter(Boolean)
    .map((address) => ({
      selected: selectedStore?.id === address?.id,
      component: <AddressElement address={address} isStore />,
      onClick: async () => {
        if (!address) return;
        setIsLoading(true);
        await changeSelectedAddress({ addressId: address.id });
        setIsLoading(false);
        setIsEdit(false);
      },
    }));
  useEffect(() => {
    if ((isLogged && addresses.length === 0) || !selectedStore) {
      setIsEdit(true);
    }
  }, [addresses, selectedStore, isLogged]);

  const selectStoreAddress = async (code: string) => {
    const response = await addStoreAddress({
      code,
      type: AddressType.STORE,
    });
    if (response.error) {
      openPortalSnackbar(
        settings.packstation_error_snackbar,
        messages.packstation_error_text
      );
    } else {
      await changeSelectedAddress({ addressId: response.data.addressId });
    }
    setIsAddNewStoreMode(false);
    setIsEdit(false);
  };

  return (
    <StyledPickUpInStoreSection className="pick-up-in-store-section">
      {isEdit ? (
        <SectionWrapper
          variant="store"
          withSeparator={false}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          withEditButton={
            (!isLogged || addresses?.length > 0) && !!selectedStore
          }
        >
          <SelectionTable items={stores} isLoading={isLoading} />
          <StyledButton
            className="your-packstation-section__add-new-btn"
            renderAs="button"
            $settings={settings.change_button_style}
            onClick={() => setIsAddNewStoreMode(true)}
          >
            {messages.add_new}
          </StyledButton>
        </SectionWrapper>
      ) : (
        <div className="pick-up-in-store-section__chosen-store">
          <AddressElement
            address={selectedStore}
            isStore
            className="left-indent"
          />
          <StyledButton
            renderAs="button"
            $settings={settings.change_button_style}
            onClick={() => setIsEdit(true)}
          >
            {messages.change_point}
          </StyledButton>
        </div>
      )}
      {isAddNewStoreMode && (
        <SelectStoreFromMapPopup
          onStoreSelect={(code) => selectStoreAddress(code)}
          closePopup={() => setIsAddNewStoreMode(false)}
        />
      )}
    </StyledPickUpInStoreSection>
  );
};

export default PickUpInStoreSection;
