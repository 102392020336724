import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { IStoreLocator } from "@ecp-redux/dto/storesLocator.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { StyledStoreLocatorMapContainer } from "../BoxStoreLocator.styled";
import StoresSearchBar from "../elements/StoresSearchBar";
import { IStoresLocatorMapProps } from "./StoreLocatorMap.types";
import StoreLocatorMapListElement from "./StoreLocatorMapListElement";

export const DEFAULT_PROPS = {
  center: {
    lat: 51.756044889225024,
    lng: 19.45015437515874,
  },
  zoom: 6,
  zoomOnActive: 12,
};

interface IMapBounding {
  max_lng: number;
  min_lng: number;
  max_lat: number;
  min_lat: number;
}

const pointInBound = (
  point: IStoreLocator,
  mapBounding: IMapBounding | null
) => {
  if (mapBounding === null) return false;
  return (
    +point.geoLatitude <= mapBounding.max_lat &&
    +point.geoLatitude >= mapBounding.min_lat &&
    +point.geoLongitude >= mapBounding.min_lng &&
    +point.geoLongitude <= mapBounding.max_lng
  );
};

const makeBounding = (
  bounds: google.maps.LatLngBounds,
  stores: IStoreLocator[],
  setMapBounding: (bound: IMapBounding | null) => void
): void => {
  stores?.forEach((store) => {
    bounds.extend({
      lat: +store.geoLatitude,
      lng: +store.geoLongitude,
    });
  });
  setMapBounding({
    max_lng: bounds.getNorthEast().lng(),
    min_lng: bounds.getSouthWest().lng(),
    max_lat: bounds.getNorthEast().lat(),
    min_lat: bounds.getSouthWest().lat(),
  });
};

const StoreLocatorMap: React.FC<IStoresLocatorMapProps> = ({
  stores,
  onSelectAddressButtonClick,
  setStoreCode,
  message,
  fetchStores,
  refreshMapView,
  isEmptySearchValue,
  storeSearchPhrase,
  setStoreSearchPhrase,
}: IStoresLocatorMapProps) => {
  const theme = useTheme() as IThemeState;
  const API_KEY = theme.advanceSettings.settings.googleMapApiKey; //!! Defining the api key in the page builder is necessary for the map to work
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: DEFAULT_PROPS.center.lat,
    lng: DEFAULT_PROPS.center.lng,
  });
  const [activeCode, setActiveCode] = useState<string | null>(null);
  const [zoom, setZoom] = useState<number>(DEFAULT_PROPS.zoom);
  const [mapBounding, setMapBounding] = useState<IMapBounding | null>(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const isMobile = useIsMobilePortal();

  const onChildClick = (lat: number, lng: number, code: string) => {
    setCenter({ lat, lng });
    setZoom(DEFAULT_PROPS.zoomOnActive);
    setActiveCode(code);
    setStoreCode && setStoreCode(code);
  };

  const handleMapChange = () => {
    if (map !== null) {
      const bounds = map?.getBounds();
      bounds &&
        setMapBounding({
          max_lng: bounds.getNorthEast().lng(),
          min_lng: bounds.getSouthWest().lng(),
          max_lat: bounds.getNorthEast().lat(),
          min_lat: bounds.getSouthWest().lat(),
        });
    }
  };

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const filteredStoresByMapBounding = useMemo(() => {
    return stores?.length > 0
      ? stores.filter((m) => pointInBound(m, mapBounding))
      : null;
  }, [stores, mapBounding]);

  useEffect(() => {
    if (map !== null && stores?.length > 0) {
      if (stores?.length === 1) {
        setCenter({
          lat: +stores[0].geoLatitude,
          lng: +stores[0].geoLongitude,
        });
        setZoom(DEFAULT_PROPS.zoomOnActive);
        const bounds = new window.google.maps.LatLngBounds();
        makeBounding(bounds, stores, setMapBounding);
      } else if (isEmptySearchValue && !isFirstRender) {
        map.setCenter({
          lat: DEFAULT_PROPS.center.lat,
          lng: DEFAULT_PROPS.center.lng,
        });
        map.setZoom(DEFAULT_PROPS.zoom);
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        makeBounding(bounds, stores, setMapBounding);
        !isFirstRender && map.fitBounds(bounds);
      }
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, map, refreshMapView]);

  return (
    <StyledStoreLocatorMapContainer>
      <div className="storelocator-map-wrapper">
        {stores?.length === 0 && (
          <div className="storelocator-map-wrapper__store-not-found">
            {message}
          </div>
        )}
        {isMobile && fetchStores && (
          <div className="storelocator-map-wrapper__search-bar">
            <StoresSearchBar
              onClickButton={fetchStores}
              storeSearchPhrase={storeSearchPhrase}
              setStoreSearchPhrase={setStoreSearchPhrase}
            />
          </div>
        )}
        <LoadScript googleMapsApiKey={API_KEY}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            center={center as { lat: number; lng: number }}
            zoom={zoom}
            options={{
              fullscreenControl: !isMobile,
              minZoom: 3,
              mapTypeControl: !isMobile,
              streetViewControl: !isMobile,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onDragEnd={handleMapChange}
            onZoomChanged={() => {
              handleMapChange();
              map && setZoom(map.getZoom() as number);
            }}
          >
            {stores?.length > 0 &&
              stores.map(({ geoLatitude, geoLongitude, code }) => {
                return (
                  <Marker
                    icon={{
                      url: require(
                        activeCode === code
                          ? "../../../../lib/shared/icons/fullfilledMarker.svg"
                          : "../../../../lib/shared/icons/unfullfilledMarker.svg"
                      ).default,
                    }}
                    key={code}
                    position={{ lat: +geoLatitude, lng: +geoLongitude }}
                    onClick={() => {
                      onChildClick(+geoLatitude, +geoLongitude, code);
                    }}
                  />
                );
              })}
          </GoogleMap>
        </LoadScript>
      </div>

      {!isMobile && (
        <div className="storelocator-list-wrapper">
          {filteredStoresByMapBounding?.map((store) => {
            const { geoLatitude, geoLongitude, code } = store;
            return (
              <StoreLocatorMapListElement
                key={code}
                store={store}
                onClick={() => {
                  onChildClick(+geoLatitude, +geoLongitude, code);
                }}
                isActive={activeCode === code}
                onSelectAddressButtonClick={onSelectAddressButtonClick}
              />
            );
          })}
        </div>
      )}
    </StyledStoreLocatorMapContainer>
  );
};

export default StoreLocatorMap;
