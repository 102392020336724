import Image from "next/image";
import { PaymentType } from "@ecp-redux/dto/cart.types";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";

const PaymentIcon = ({ paymentMethod }: { paymentMethod: PaymentType }) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const iconUrl =
    settings[`payment_${paymentMethod}_icon` as keyof typeof settings];
  if (!iconUrl) {
    return <div className="icon-placeholder" />;
  }
  return (
    <Image
      src={iconUrl}
      alt={paymentMethod}
      role="presentation"
      width={48}
      height={24}
      style={{ objectFit: "contain", width: "48px", height: "24px" }}
    />
  );
};
export default PaymentIcon;
