import React, { useEffect, useState } from "react";
import { StyledAddressSection } from "./AddressSection.styled";
import { StyledSelectedAddress } from "../../BoxCartStepTwoExtended.styled";
import AddressInfo from "../../potentialBoxes/DeliveryChannels/elements/SelectedAddress/elements/AddressInfo";
import SectionWrapper from "./SectionWrapper";
import { AddressType } from "@ecp-redux/dto/cartAddresses.type";
import ChangeBillingPostalAddressExtended from "@ecp-boxes/boxes/Cart/shared/ChangeBillingPostalAddressExtended";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import * as cartAPI from "@ecp-redux/api/cart";

const AddressSection: React.FC = () => {
  const { data: cartData } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery() ?? {};
  const hasSelectedShippingAddress =
    cartData?.response?.recipientAddress !== null;
  const [editAddress, setEditAddress] = useState(!hasSelectedShippingAddress);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const { isLogged, setSectionValidation } = useBoxCartStepTwoExtended();

  const handleToggleEditAddress = () => {
    if (!hasSelectedShippingAddress) {
      setEditAddress(true);
    } else {
      setEditAddress(!editAddress);
    }
  };

  const { recipientAddress, recipientAddresses } = cartData?.response ?? {
    recipientAddress: null,
    recipientAddresses: [],
  };

  useEffect(() => {
    setSectionValidation(
      "address",
      isLogged ? hasSelectedShippingAddress : isAddressValid
    );
  }, [
    isAddressValid,
    isLogged,
    setSectionValidation,
    hasSelectedShippingAddress,
  ]);

  return (
    <StyledAddressSection
      className="delivery-channels"
      data-testid="DeliveryChannelsComponent"
    >
      <SectionWrapper
        variant="address"
        isEdit={editAddress}
        setIsEdit={setEditAddress}
        withEditButton={
          isLogged && recipientAddresses?.length !== 0 && !!recipientAddress
        }
      >
        <StyledSelectedAddress className="address__selected-address-container">
          {!editAddress && recipientAddress && isLogged ? (
            <AddressInfo address={recipientAddress} />
          ) : (
            <ChangeBillingPostalAddressExtended
              handleToggleEditAddress={handleToggleEditAddress}
              selectedAddress={recipientAddress}
              addresses={recipientAddresses}
              addressType={AddressType.POSTAL}
              setIsAddressValid={setIsAddressValid}
            />
          )}
        </StyledSelectedAddress>
      </SectionWrapper>
    </StyledAddressSection>
  );
};

export default AddressSection;
