import { useEffect } from "react";
import { useObserveElementDimensions } from "../../../global/components/ImageWrapper/ImageWrapper.methods";
import { getModifiedSectionUuid } from "../../../helpers/getModifiedSectionUuid";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useSectionEffectsContext } from "../../Contexts/SectionEffectsContext";
import { ISectionProps, usePrepareSectionSettings } from "../Section";
import { StyledStickySectionEffects } from "./SectionEffects.styled";
import { useRowPopupContext } from "../../../structure/Contexts/RowPopupContext";
import { isClientSide } from "@ecp-redux/api/api";

export type TSectionEffects = Omit<ISectionProps, "path"> & {
  children: JSX.Element;
};

const SectionEffects = ({ children, sectionData, type }: TSectionEffects) => {
  const [ref, , height] = useObserveElementDimensions();
  const isMobile = useIsMobilePortal();
  const { setSectionEffectsData } = useSectionEffectsContext();
  const { activePopup } = useRowPopupContext();
  const displaySettings = usePrepareSectionSettings(
    sectionData.displaySettings,
    type
  );

  const activePopupHeight =
    (isClientSide()
      ? document?.getElementById(`SECTION=${activePopup?.sectionData.id}`)
          ?.offsetHeight
      : 0) ?? 0;

  useEffect(() => {
    setSectionEffectsData();
  }, [height, sectionData, isMobile]);

  return (
    <StyledStickySectionEffects
      data-height={
        sectionData.uuid != activePopup?.sectionData.uuid
          ? (height ?? 0)
          : (height ?? 0) - (activePopupHeight ?? 0)
      }
      id={`${sectionData.id}`}
      data-sectionuuid={getModifiedSectionUuid(sectionData, isMobile)}
      $sectionUuid={getModifiedSectionUuid(sectionData, isMobile)}
      $displaySettings={displaySettings}
      ref={ref}
    >
      {children}
    </StyledStickySectionEffects>
  );
};

export default SectionEffects;
