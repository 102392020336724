import { useRef, useState } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import InputMask from "react-input-mask";
import { useTheme } from "styled-components";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import SearchIcon from "../../../shared/icons/SearchIcon/SearchIcon";
import { IElementInput } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  IThemeState,
  TInputId,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  StyledInput,
  StyledInputComponent,
  StyledInputLabel,
  StyledInputSearchComponent,
  StyledInputValidation,
} from "./StyledInput/StyledInput";
import Loader from "../Loader/Loader";

interface IInputProps<T extends FieldValues> extends UseControllerProps<T> {
  $settings: IElementInput | TInputId;
  label: string;
  placeholder: string;
  disabled?: boolean;
  formNoValidate?: boolean;
  maxLength?: number;
  withoutErrorMessage?: boolean;
  mask?: string;
  className?: string;
  id?: string;
  floatingLabel?: boolean;
  isLoading?: boolean;
}

interface IInputSearchProps {
  $settings: IElementInput | TInputId;
  placeholder: string;
  iconColor: `color$$${number}`;
  onChange: (e: any) => void;
  className?: string;
}

export interface IInputSettings {
  $settings: IElementInput | TInputId | TInputReadoutValue;
  isLoading?: boolean;
  hasLabel?: boolean;
}

export const InputText = <T extends FieldValues>(
  props: IInputProps<T>
): JSX.Element => {
  const { field, fieldState } = useController(props);
  const {
    $settings,
    label,
    placeholder,
    disabled,
    maxLength,
    mask,
    className,
    id,
    floatingLabel = false,
  } = props;

  return (
    <div className={className ?? "input-text"}>
      {!mask && (
        <StyledInputComponent $settings={$settings}>
          <div className="input-loader-container">
            {props.isLoading && (
              <Loader size={14} borderColor="#B3B6BD" borderSize={2} />
            )}
          </div>
          <StyledInput
            $settings={$settings}
            placeholder={placeholder}
            disabled={disabled}
            type="text"
            formNoValidate={fieldState.error === undefined}
            isLoading={props.isLoading}
            maxLength={maxLength}
            {...field}
            className={className ? `${className}__input` : "input-text__input"}
            {...(id && { id })}
          />
          <StyledInputLabel
            $settings={$settings}
            className={className ? `${className}__label` : "input-text__label"}
          >
            {label}
          </StyledInputLabel>
          {fieldState.error !== undefined && (
            <StyledInputValidation
              $settings={$settings}
              role="alert"
              className={
                className
                  ? `${className}__validation`
                  : "input-text__validation"
              }
            >
              {fieldState.error.message}
            </StyledInputValidation>
          )}
        </StyledInputComponent>
      )}
      {mask && (
        <InputMask mask={mask} maskChar="" {...field}>
          <StyledInputComponent $settings={$settings}>
            <StyledInput
              $settings={$settings}
              placeholder={placeholder}
              disabled={disabled}
              type="text"
              formNoValidate={fieldState.error === undefined}
              maxLength={maxLength}
              {...field}
              className={
                className
                  ? `${className}-mask__input`
                  : "input-text-mask__input"
              }
              {...(id && { id })}
              data-testid="input-form-mask"
            />
            <StyledInputLabel
              $settings={$settings}
              className={
                className ? `${className}-mask__label` : "input-mask__label"
              }
            >
              {label}
            </StyledInputLabel>
            {fieldState.error !== undefined && (
              <StyledInputValidation
                $settings={$settings}
                role="alert"
                className={
                  className
                    ? `${className}-mask__validation`
                    : "input-text-mask__validation"
                }
              >
                {fieldState.error.message}
              </StyledInputValidation>
            )}
          </StyledInputComponent>
        </InputMask>
      )}
    </div>
  );
};

export const InputPassword = <T extends FieldValues>(
  props: IInputProps<T> & { reverseVisiblePass?: boolean }
): JSX.Element => {
  const {
    $settings,
    label,
    placeholder,
    className,
    reverseVisiblePass = false,
    floatingLabel = false,
  } = props;

  const { field, fieldState } = useController(props);
  const [visiblePass, setVisiblePass] = useState(reverseVisiblePass);

  const showPass = () => setVisiblePass((prevVisiblePass) => !prevVisiblePass);
  return (
    <StyledInputComponent
      $settings={$settings}
      hasLabel={!!label}
      className={className ? className : "input-text"}
    >
      {!visiblePass ? (
        <FiEyeOff
          className="eye-icon"
          size={22}
          color="#CCCCCC"
          onClick={showPass}
        />
      ) : (
        <FiEye
          className="eye-icon"
          size={22}
          color="#CCCCCC"
          onClick={showPass}
        />
      )}
      <StyledInput
        $settings={$settings}
        placeholder={placeholder}
        type={visiblePass ? "text" : "password"}
        formNoValidate={fieldState.error === undefined}
        {...field}
        className={
          className
            ? `${className}--visible__input`
            : "input-password--visible__input"
        }
      />
      <StyledInputLabel
        $settings={$settings}
        className={className ? `${className}__label` : "input-password__label"}
      >
        {label}
      </StyledInputLabel>
      {fieldState.error !== undefined && (
        <StyledInputValidation
          $settings={$settings}
          role="alert"
          className={
            className
              ? `${className}--visible__validation`
              : "input-password--visible__validation"
          }
        >
          {fieldState.error.message}
        </StyledInputValidation>
      )}
    </StyledInputComponent>
  );
};

export const InputPhone = <T extends FieldValues>(
  props: IInputProps<T>
): JSX.Element => {
  const {
    $settings,
    label,
    placeholder,
    disabled,
    withoutErrorMessage = false,
    className,
    mask,
  } = props;
  const { field, fieldState } = useController(props);
  return (
    <div className={className ?? "input-phone"}>
      {!mask && (
        <StyledInputComponent $settings={$settings}>
          <StyledInputLabel
            $settings={$settings}
            className={className ? `${className}__label` : "input-phone__label"}
          >
            {label}
          </StyledInputLabel>
          <StyledInput
            $settings={$settings}
            placeholder={placeholder}
            disabled={disabled}
            type="tel"
            formNoValidate={fieldState.error === undefined ? true : false}
            {...field}
            onChange={(e) => {
              field.onChange(e.target.value.replace(/[^+0-9]/g, ""));
            }}
            className={className ? `${className}__input` : "input-phone__input"}
          />
          {fieldState.error !== undefined &&
            fieldState.error.message &&
            !withoutErrorMessage && (
              <StyledInputValidation
                $settings={$settings}
                role="alert"
                className={
                  className
                    ? `${className}__validation`
                    : "input-phone__validation"
                }
              >
                {" "}
                {fieldState.error.message}
              </StyledInputValidation>
            )}
        </StyledInputComponent>
      )}
      {mask && (
        <InputMask mask={mask} maskChar="" {...field}>
          <StyledInputComponent $settings={$settings}>
            <StyledInputLabel
              $settings={$settings}
              className={
                className ? `${className}__label` : "input-phone__label"
              }
            >
              {label}
            </StyledInputLabel>
            <StyledInput
              $settings={$settings}
              placeholder={placeholder}
              disabled={disabled}
              type="tel"
              formNoValidate={fieldState.error === undefined ? true : false}
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value.replace(/[^+0-9]/g, ""));
              }}
              className={
                className ? `${className}__input` : "input-phone__input"
              }
            />
            {fieldState.error !== undefined &&
              fieldState.error.message &&
              !withoutErrorMessage && (
                <StyledInputValidation
                  $settings={$settings}
                  role="alert"
                  className={
                    className
                      ? `${className}__validation`
                      : "input-phone__validation"
                  }
                >
                  {" "}
                  {fieldState.error.message}
                </StyledInputValidation>
              )}
          </StyledInputComponent>
        </InputMask>
      )}
    </div>
  );
};

export const InputSearch = (props: IInputSearchProps): JSX.Element => {
  const { $settings, placeholder, className, onChange, iconColor } = props;
  const theme = useTheme() as IThemeState;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={className ?? ""}>
      <StyledInputSearchComponent
        $settings={$settings}
        iconWrapperHeight={
          inputRef.current !== null ? inputRef?.current.offsetHeight : 0
        }
      >
        <StyledInput
          ref={inputRef}
          $settings={$settings}
          placeholder={placeholder}
          type="text"
          onChange={onChange}
          className={className ? `${className}__input` : "input-search__input"}
        />
        <div className="iconContainer">
          <SearchIcon
            fill={convertColorIdToHex(iconColor, theme.colorPalette)}
          />
        </div>
      </StyledInputSearchComponent>
    </div>
  );
};
