import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import {
  isPackstationAddress,
  isPostalBillingAddress,
  isStoreAddress,
  TAddresses,
} from "@ecp-redux/dto/cartAddresses.type";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { concatAddress } from "@ecp-boxes/helpers/helpers";
import classNames from "classnames";

const AddressElement = ({
  address,
  isPackstation,
  isPostalBilling,
  isStore,
  className,
}: {
  address: TAddresses | undefined | null;
  isPackstation?: boolean;
  isPostalBilling?: boolean;
  isStore?: boolean;
  className?: string;
}) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  if (!address) return null;

  const { addressOneLine } = concatAddress(address);
  if (isPackstation && isPackstationAddress(address)) {
    return (
      <div className={classNames(className)}>
        <StyledText
          $settings={{
            font: settings.section_strong_text_typography,
            text: {
              color: settings.cart_color_primary,
            },
          }}
        >
          {address.packstationCode}
        </StyledText>
        <StyledText
          $settings={{
            font: settings.section_subtext_typography,
            text: {
              color: settings.cart_color_secondary,
            },
          }}
        >
          {addressOneLine}
        </StyledText>
      </div>
    );
  }
  if (isPostalBilling && isPostalBillingAddress(address)) {
    return (
      <div className={classNames(className)}>
        <StyledText
          $settings={{
            font: settings.section_strong_text_typography,
            text: {
              color: settings.cart_color_primary,
            },
          }}
        >
          {address.firstName} {address.lastName}
        </StyledText>
        <StyledText
          $settings={{
            font: settings.section_subtext_typography,
            text: {
              color: settings.cart_color_secondary,
            },
          }}
        >
          {addressOneLine}
        </StyledText>
      </div>
    );
  }
  if (isStore && isStoreAddress(address)) {
    return (
      <div className={classNames(className)}>
        <StyledText
          $settings={{
            font: settings.section_strong_text_typography,
            text: {
              color: settings.cart_color_primary,
            },
          }}
        >
          {address.storeName}
        </StyledText>
        <StyledText
          $settings={{
            font: settings.section_subtext_typography,
            text: {
              color: settings.cart_color_secondary,
            },
          }}
        >
          {addressOneLine}
        </StyledText>
      </div>
    );
  }
  return null;
};

export default AddressElement;
