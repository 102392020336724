import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxChangePhoneNumberMessagesScheme = z.object({
  change_phone_number_form_title: themeScheme.MessageScheme.default(
    "Zmień numer telefonu"
  ),
  change_phone_number_label: themeScheme.MessageScheme.default("Nr telefonu"),
  change_phone_number_button_label: themeScheme.MessageScheme.default("Zmień"),
  change_phone_number_validate_error_message: themeScheme.MessageScheme.default(
    "Numer telefonu niepoprawny"
  ),
});

export const IBoxChangePhoneNumberSettingsScheme = z.object({
  change_phone_number_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  change_phone_number_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  change_phone_number_form_title_spacing:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  change_phone_number_form_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  change_phone_number_input_style:
    themeScheme.InputIdScheme.default("input$$1"),
  change_phone_number_button_style:
    themeScheme.ButtonIdScheme.default("button$$1"),
  change_phone_number_form_wrapper_margin:
    themeScheme.MarginSizeScheme.default("0px 0px 0px 0px").catch(
      "0px 0px 0px 0px"
    ),
  change_phone_number_redirect_to_verify_phone_step_1:
    themeScheme.UrlScheme.default("").catch(""),
  change_phone_number_redirect_successful_phone_change_step_2:
    themeScheme.UrlScheme.default("").catch(""),
});
