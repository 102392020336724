import { MOBILE_BREAKPOINT } from "@ecp-boxes/global/global";
import styled from "styled-components";

export const PaypalContainer = styled.div<{
  $isEverySectionValid: boolean;
  $background: string;
}>`
  width: 100%;
  height: 44px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 40px;
  }
  filter: grayscale(
    ${({ $isEverySectionValid }) => ($isEverySectionValid ? 0 : 1)}
  );
  background: ${({ $background }) => $background};
  border-radius: 6px;
  overflow: clip;
  #paypal-button {
    width: inherit;
    height: inherit;
  }
`;
