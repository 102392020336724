import composeCss from "@ecp-boxes/settingsPatterns/composeCss";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { IGlobalInputObject } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import styled, { css } from "styled-components";

interface IBoxPaypalPaymentStyledProps {
  $inputStyle: Omit<IGlobalInputObject, "text">;
  $isLoading: boolean;
}

export const CreditCardStyled = styled.form<IBoxPaypalPaymentStyledProps>(
  ({ $inputStyle, theme, $isLoading }) => {
    const inputBackgroundColor = convertColorIdToHex(
      $inputStyle.background.color,
      theme.colorPalette
    );
    const inputBackgroundColorFocused = convertColorIdToHex(
      $inputStyle.background.activeColor,
      theme.colorPalette
    );
    const inputBorderColor = convertColorIdToHex(
      $inputStyle.border.color,
      theme.colorPalette
    );
    const inputBorderColorFocused = convertColorIdToHex(
      $inputStyle.hoverBorder.color,
      theme.colorPalette
    );
    const inputBorderColorInvalid = convertColorIdToHex(
      $inputStyle.errorBorder.color,
      theme.colorPalette
    );

    return css`
      position: relative;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px;
      border-radius: 8px;
      gap: 16px;
      ${$isLoading &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}
      .input-container {
        label {
          ${composeCss.font($inputStyle.font.label, theme.typography)}
        }
      }
      div[id] {
        padding-inline: 10px;
        height: ${$inputStyle.height}px;
        width: ${$inputStyle.width}%;
        background-color: ${inputBackgroundColor};
        border-color: ${inputBorderColor};
        ${composeCss.borderWidth($inputStyle.border.width)}
        ${composeCss.borderRadius($inputStyle.border.radius)}
        ${composeCss.borderStyle($inputStyle.border.style)}
        &.braintree-hosted-fields-focused {
          background-color: ${inputBackgroundColorFocused};
          border-color: ${inputBorderColorFocused};
        }
        &.braintree-hosted-fields-invalid,
        &.invalid {
          border-color: ${inputBorderColorInvalid};
        }
      }
      .card-info {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
        .card-number {
          flex: 1 1 100%;
        }
        .expiration-date {
          flex: 1;
        }
        .cvv {
          flex: 1;
        }
        &__continue {
          flex: 1 1 100%;
          display: flex;
          justify-content: center;
        }
      }
    `;
  }
);
