import { BoxPaypalPaymentStyled } from "./BoxPaypalPayment.styled";
import { IBoxPaypalPaymentProps } from "./BoxPaypalPayment.types";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { useEffect, useMemo, useRef, useState } from "react";
import * as braintreeAPI from "@ecp-redux/api/braintree";
import * as cartAPI from "@ecp-redux/api/cart";
import braintree, { HostedFields } from "braintree-web";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertInputIdToValues } from "@ecp-boxes/shared/components/Input/StyledInput/StyledInput.methods";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { HostedFieldsHostedFieldsFieldName } from "braintree-web/hosted-fields";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";
const BoxPaypalPayment = ({ settings, messages }: IBoxPaypalPaymentProps) => {
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null);
  const theme = useTheme() as IThemeState;
  const {
    data: clientToken,
    error: clientTokenError,
    isLoading: isTokenLoading,
  } = braintreeAPI.useGetClientTokenQuery(undefined, {
    skip: !isPortalSide(),
  });

  const [processPayment] = cartAPI.usePostCartPlaceOrderMutation();

  const formRef = useRef<HTMLFormElement>(null);

  const { text, font, ...inputStyle } = useMemo(
    () =>
      convertInputIdToValues(settings.input_style, theme.globalObjects.inputs),
    [settings.input_style, theme.globalObjects.inputs]
  );

  useEffect(() => {
    if (!clientToken?.token) return;

    const inputFontStyle = convertTypoIdToValues(font.input, theme.typography);
    const initializeBraintree = async () => {
      try {
        const hostedFieldsInstance = await braintree.hostedFields.create({
          client: await braintree.client.create({
            authorization: clientToken.token,
          }),
          styles: {
            input: {
              color: convertColorIdToHex(text.color, theme.colorPalette),
              "font-family": inputFontStyle.family,
              "font-size": `${inputFontStyle.size}px`,
              "font-weight": `${inputFontStyle.weight}`,
              "letter-spacing": `${inputFontStyle.letterSpacing}px`,
              "line-height": `${inputFontStyle.lineHeight}px`,
            },
            "input.invalid": {
              color: convertColorIdToHex(text.errorColor, theme.colorPalette),
            },
            "input.focus": {
              color: convertColorIdToHex(text.activeColor, theme.colorPalette),
            },
            "::-webkit-input-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            ":-moz-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            "::-moz-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
            ":-ms-input-placeholder": {
              color: convertColorIdToHex(
                text.placeholderColor,
                theme.colorPalette
              ),
            },
          },
          fields: {
            number: {
              selector: "#card-number",
              placeholder: messages.card_number_placeholder,
            },
            cvv: {
              selector: "#cvv",
              placeholder: messages.cvv_placeholder,
            },
            expirationDate: {
              selector: "#expiration-date",
              placeholder: messages.expiration_date_placeholder,
            },
          },
        });

        setHostedFields(hostedFieldsInstance);

        // Event listeners
        hostedFieldsInstance.on("focus", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.add("focus");
        });

        hostedFieldsInstance.on("blur", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.remove("focus");
        });

        hostedFieldsInstance.on("validityChange", (event) => {
          const field = document.querySelector(`#${event.emittedBy}`);
          field?.classList.toggle(
            "invalid",
            !event.fields[event.emittedBy].isValid
          );
        });
      } catch (err) {
        console.error(err);
      }
    };

    initializeBraintree();

    return () => {
      hostedFields?.teardown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken]);

  const handleSubmit = async () => {
    const formState = hostedFields?.getState();
    if (!formState || !hostedFields) return;

    Object.keys(formState.fields).forEach((field) => {
      if (
        !formState.fields[field as HostedFieldsHostedFieldsFieldName].isValid
      ) {
        (
          formState.fields[field as HostedFieldsHostedFieldsFieldName]
            .container as HTMLElement
        ).classList.add("braintree-hosted-fields-invalid");
      }
      return;
    });

    try {
      const { nonce } = await hostedFields.tokenize();

      const result = await processPayment({
        nonceToken: nonce,
      }).unwrap();

      if (result.response.orderId) {
        console.log("Payment successful!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isTokenLoading && isPortalSide()) {
    return <div>Loading payment form...</div>;
  }

  if (clientTokenError && isPortalSide()) {
    return <div>Error loading payment form.</div>;
  }

  return (
    <BoxPaypalPaymentStyled
      id="my-sample-form"
      ref={formRef}
      $inputStyle={{ ...inputStyle, font }}
    >
      <header>
        <StyledText
          $settings={{
            text: { color: settings.header_color },
            font: settings.header_typo,
          }}
        >
          {messages.payment_method}
        </StyledText>
      </header>
      <div className="card-info">
        <div className="input-container card-number">
          <label htmlFor="card-number">{messages.card_number}</label>
          <div id="card-number"></div>
        </div>
        <div className="input-container expiration-date">
          <label htmlFor="expiration-date">{messages.expiration_date}</label>
          <div id="expiration-date"></div>
        </div>
        <div className="input-container cvv">
          <label htmlFor="cvv">{messages.cvv}</label>
          <div id="cvv"></div>
        </div>
      </div>

      <div className="card-info__continue">
        <StyledButton
          $settings={settings.continue_button_style}
          type="submit"
          onClick={handleSubmit}
        >
          {messages.continue}
        </StyledButton>
      </div>
    </BoxPaypalPaymentStyled>
  );
};
export default BoxPaypalPayment;
