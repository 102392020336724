import {
  Consent,
  INotificationsResponse,
} from "@ecp-redux/dto/notifications.type";
import { API, api } from "./api";

export const notifications = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<INotificationsResponse, void>({
      query: () => {
        return {
          url: API.getNotificationConsent.endpoint(),
        };
      },
    }),
    putNotificationConsent: builder.mutation<void, Consent[]>({
      query: (body) => {
        return {
          url: API.putNotificationConsent.endpoint(),
          method: "PUT",
          body: {
            consents: body,
          },
        };
      },
    }),
  }),
});

export const { useGetNotificationsQuery } = notifications;

export const { getNotifications } = notifications.endpoints;
