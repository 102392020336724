import styled from "styled-components";

export const StyledPickUpInStoreSection = styled.div`
  display: flex;
  flex-direction: column;
  .pick-up-in-store-section__chosen-store {
    display: flex;
    justify-content: space-between;
  }
`;
