import { isPasswordZod, isEmailZod } from "@ecp-boxes/helpers/validators";
import * as emailApi from "@ecp-redux/api/cart";
import {
  InputPassword,
  InputText,
} from "@ecp-boxes/shared/components/Input/Inputs";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { isNotEmptyMessage } from "@ecp-boxes/helpers/isNotEmptyMessage";
import { Checkbox } from "@ecp-boxes/shared/components/CheckboxesList/CheckboxesList";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../BoxCartStepTwoExtended.types";
import { useLinkContentActions } from "@ecp-boxes/shared/hooks/useLinkContentAction";
import { useBoxCartStepTwoExtended } from "../context/BoxCartStepTwoExtended.context";
import useLogin from "../hooks/useLogin";
import { ContactSectionStyled } from "./ContactSection.styled";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/router";
import { debounce } from "lodash";
import SectionWrapper from "./sections/SectionWrapper";
import * as cartAPI from "@ecp-redux/api/cart";
import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import htmlToReact from "../../../../helpers/HtmlToReactParser";
import { StyledInputValidation } from "@ecp-boxes/shared/components/Input/StyledInput/StyledInput";

interface IContactForm {
  username: string;
  password: string;
  terms?: boolean;
}

const contactDefaultValues: IContactForm = {
  username: "",
  password: "",
  terms: false,
};

const ContactSection: React.FC = () => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { openPopup } = useLinkContentActions();
  const [trigger] = emailApi.usePostCartShoppingWithoutRegisterMutation();
  const { data: cartData } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const { customerData, isLogged, setSectionValidation, isSameBillingAddress } =
    useBoxCartStepTwoExtended();
  const { setSessionStorageItem } = useSetSessionStorage();
  const { loginUser, loginError, clearLoginError } = useLogin();
  const router = useRouter();

  const { recipientAddress, billingAddress } = cartData?.response ?? {};

  const nonExistingCustomerValidationSchema = z.object({
    username: isEmailZod(
      messages.contact_validation_empty_field,
      messages.contact_message_validation_email
    ),
    terms: z.boolean().refine((val) => val === true, {
      message: messages.contact_validation_consent,
    }),
  });
  const existingCustomerValidationSchema = z.object({
    username: isEmailZod(
      messages.contact_validation_empty_field,
      messages.contact_message_validation_email
    ),
    password: isPasswordZod(
      messages.contact_validation_empty_field,
      messages.contact_message_validation_password
    ),
  });

  const isActiveClient = isLogged;
  const isClientLoginPassword = isRegistered && !isLogged;
  const isQuest = !isLogged && !isRegistered;

  const formOptions = useForm<IContactForm>({
    defaultValues: contactDefaultValues,
    resolver: zodResolver(
      isClientLoginPassword
        ? existingCustomerValidationSchema
        : nonExistingCustomerValidationSchema
    ),
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = formOptions;

  const emailValue = watch("username");
  const passwordValue = watch("password");

  useEffect(() => {
    if (loginError && (emailValue || passwordValue)) {
      clearLoginError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue, passwordValue]);

  useEffect(() => {
    setSectionValidation(
      "contact",
      isLogged || (isValid && !isClientLoginPassword)
    );
  }, [isValid, setSectionValidation, isClientLoginPassword, isLogged]);

  useEffect(() => {
    if (!!emailValue && !errors.username?.message) {
      setIsCheckingEmail(true);
      const delayedCheck = debounce(async () => {
        try {
          await trigger({ email: emailValue }).unwrap();
          setIsRegistered(false);
        } catch (error: any) {
          if (error?.data?.code === 9003) {
            setIsRegistered(true);
          } else {
            setIsRegistered(false);
          }
        } finally {
          setIsCheckingEmail(false);
          setIsValidated(true);
        }
      }, 1000);

      delayedCheck();

      return () => {
        delayedCheck.cancel();
      };
    } else {
      setIsRegistered(false);
      setIsValidated(false);
      setIsCheckingEmail(false);
    }

    return;
  }, [emailValue, trigger, !errors.username?.message]);

  return (
    <ContactSectionStyled className="contact">
      <SectionWrapper
        withEditButton={isLogged}
        variant="contact"
        withSubtitle={!isLogged}
        onClickCallback={() => {
          openPopup(settings.contact_change_account_popup);
          if (recipientAddress)
            setSessionStorageItem("recipient_address", recipientAddress);
          if (billingAddress && !isSameBillingAddress)
            setSessionStorageItem("billing_address", billingAddress);
        }}
        withSeparator={false}
      >
        {isQuest && (
          <div className="contact-form-quest">
            <div className="contact-form-quest__email-loader">
              <InputText
                name="username"
                control={control}
                placeholder={messages.contact_description_placeholder_email}
                label={messages.contact_description_label_email}
                $settings={settings.basket_step_2_full_input}
                className="contact-form-quest__username"
                isLoading={isCheckingEmail}
              />
            </div>
            <Checkbox
              label={
                <StyledText
                  className="contact-form-quest__consent"
                  $settings={{
                    font: settings.section_subtext_typography,
                    text: { color: settings.cart_color_primary },
                  }}
                  show={isNotEmptyMessage(messages.contact_consent_label)}
                >
                  <p>
                    {htmlToReact(
                      messages.contact_consent_label,
                      settings.contact_consent_link_style
                    )}
                  </p>
                </StyledText>
              }
              name="terms"
              control={control}
              textStyles={{
                labelTypo: settings.section_subtext_typography,
                labelColor: settings.cart_color_primary,
                errorTypo: "typo$$2",
                errorColor: "color$$2",
              }}
            />
          </div>
        )}

        {isActiveClient && (
          <div>
            <StyledText
              className="contact-customer__description"
              $settings={{
                font: settings.section_subtext_small_typography,
                text: { color: settings.cart_color_primary },
              }}
              show={isNotEmptyMessage(messages.contact_description_label_email)}
            >
              <p>{messages.contact_description_label_email}</p>
            </StyledText>
            <StyledText
              className="left-indent"
              $settings={{
                font: settings.section_subtext_typography,
                text: { color: settings.cart_color_primary },
              }}
            >
              <p>{customerData?.email}</p>
            </StyledText>
          </div>
        )}

        {!isActiveClient &&
          isValidated &&
          !isCheckingEmail &&
          !errors.username?.message &&
          !isClientLoginPassword && (
            <StyledText
              $settings={{
                font: settings.section_subtext_typography,
                text: { color: settings.cart_color_primary },
              }}
              show={isNotEmptyMessage(messages.no_logged_account_message)}
            >
              <StyledText
                className="contact-customer__login-message"
                $settings={{
                  font: settings.no_logged_account_message_typography,
                  text: { color: settings.no_logged_account_message_color },
                }}
              >
                {htmlToReact(
                  messages.no_logged_account_message,
                  settings.login_redirect_link_style
                )}
              </StyledText>
            </StyledText>
          )}

        {isClientLoginPassword && (
          <form
            onSubmit={handleSubmit(async (d) => loginUser(d))}
            className="contact__login-form-container"
          >
            <InputText
              name="username"
              control={control}
              placeholder={messages.contact_description_placeholder_email}
              label={messages.contact_description_label_email}
              $settings={settings.basket_step_2_full_input}
              className="contact-form-container__username"
            />
            <InputPassword
              name="password"
              control={control}
              placeholder={messages.contact_description_placeholder_password}
              label={messages.contact_description_label_password}
              $settings={settings.basket_step_2_full_input}
              className="contact-form-container__password"
            />
            {loginError && (
              <StyledInputValidation
                $settings={settings.basket_step_2_full_input}
                role="alert"
                className={"input-password--visible__validation"}
              >
                {messages.contact_message_validation_password_invalid}
              </StyledInputValidation>
            )}
            <div className="contact-form-action-container">
              <StyledButton
                renderAs="button"
                type="button"
                onClick={() =>
                  router.push(settings.contact_change_password_redirect)
                }
                $settings={settings.basket_step_all_back_button}
                className="contact-form-action-container__remind-password-button"
                show={isNotEmptyMessage(
                  messages.contact_remind_password_label_button
                )}
              >
                {messages.contact_remind_password_label_button}
              </StyledButton>
              <StyledButton
                renderAs="button"
                type="submit"
                $settings={settings.basket_step_all_second_button}
                className="contact-form-action-container__submit-button"
                show={isNotEmptyMessage(messages.contact_login_label_button)}
              >
                {messages.contact_login_label_button}
              </StyledButton>
            </div>
          </form>
        )}
      </SectionWrapper>
    </ContactSectionStyled>
  );
};

export default ContactSection;
