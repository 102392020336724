import React from "react";
import { useTheme } from "styled-components";
import {
  HEX,
  IThemeState,
  ITypography,
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { removeAlertByCode } from "../../../redux/slices/alertsSlice";
import { usePortalDispatch } from "../../../redux/store";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "../../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { StyledSimplyAlert } from "./StyledSimplyAlert.styled";
import PortalWarningIcon from "@ecp-boxes/shared/icons/PortalWarningIcon";

export interface SimplyAlertProps {
  message: string;
  backgroundColor: HEX | TColorId;
  typography: ITypography | TTypoId;
  textColor: HEX | TColorId;
  close?: () => void;
  alertsCodes?: string[];
  lifetime?: number;
  withWarningIcon?: boolean;
  warningIconColor?: HEX | TColorId;
  withoutLeftPadding?: boolean;
}

const SimplyAlert: React.FC<SimplyAlertProps> = (props): JSX.Element | null => {
  const {
    message,
    close,
    alertsCodes,
    backgroundColor,
    typography,
    textColor,
    lifetime,
    withWarningIcon = false,
    warningIconColor,
    withoutLeftPadding = false,
  } = props;
  const theme = useTheme() as IThemeState;

  const [isShown, setIsShown] = React.useState(true);
  const dispatch = usePortalDispatch();

  React.useEffect(() => {
    setIsShown(true);

    if (!lifetime) return;
    const timer = setTimeout(() => {
      setIsShown(false);
      alertsCodes && dispatch(removeAlertByCode(alertsCodes));
    }, lifetime);

    return () => clearTimeout(timer);
  }, []);

  return isShown ? (
    <StyledSimplyAlert backgroundColor={backgroundColor} withoutLeftPadding={withoutLeftPadding}>
      <div className="simply-alert__icon-container">
        {withWarningIcon && (
          <PortalWarningIcon
            fill={convertColorIdToHex(
              warningIconColor ?? `color$$1`,
              theme.colorPalette
            )}
          />
        )}
        <StyledText
          $settings={{
            font: {
              id: "custom",
              custom: convertTypoIdToValues(typography, theme.typography),
            },
            text: {
              color: {
                id: "custom",
                custom: convertColorIdToHex(textColor, theme.colorPalette),
              },
            },
          }}
          show={isNotEmptyMessage(message)}
        >
          {message}
        </StyledText>
      </div>
      {close && (
        <button className="simply-alert__close-button" onClick={close}>
          <CloseIcon />
        </button>
      )}
    </StyledSimplyAlert>
  ) : null;
};

export default SimplyAlert;
