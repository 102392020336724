import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  TColorReadoutValue,
  TTypoReadoutValue,
  AlignmentHorizontalOption,
  TDropdownReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export type IBoxFacetsVariantsData = GenericBoxData<
  IBoxFacetsVariantsContent,
  IBoxFacetsVariantsContentSettings,
  IBoxFacetsVariantsDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export type IBoxFacetsVariantsProps = IPortalBox<IBoxFacetsVariantsData>;

export enum MediaAsset {
  NONE = "NONE",
  WITH_MEDIA_ASSET = "WITH_MEDIA_ASSET",
}

export interface IFacetObject {
  show: boolean;
  label: string;
  type: MediaAsset;
  labelVisibility: boolean;
  categories: Record<string, string[]>;
  withMediaAssetConfig?: {
    size: number;
    borderWidth: number;
    borderRadius: number;
    borderColor: TColorReadoutValue;
  };
}

export interface IFacetObjects {
  [key: string]: IFacetObject;
}

export interface IBoxFacetsVariantsContent {
  facetsTitle: string;
  facetsObjects: IFacetObjects;
}

export interface IBoxFacetsVariantsContentSettings {
  title: {
    show: boolean;
    font: TTypoReadoutValue;
    text: TColorReadoutValue;
    gap: number;
    alignment: AlignmentHorizontalOption;
  };
}

export interface IBoxFacetsVariantsDisplaySettings {
  style: TDropdownReadoutValue;
  spaceBetweenObjects: number;
  facetsOrder: string[];
}
