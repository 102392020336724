import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { LanguageType } from "@ecp-redux/dto/themeSettings/translation.types";

export enum LANGUAGE_SWITCH_MODE {
  DROPDOWN = "DROPDOWN",
  BUTTONS = "BUTTONS",
}

export const IBoxLanguageSwitcherMessagesScheme = z.object({
  multi_language_placeholder:
    themeScheme.MessageScheme.default("czego szukasz?").catch("czego szukasz?"),
  language: themeScheme
    .JSONMessageScheme(
      z.record(
        z.custom<`label_${LanguageType}`>((val) => {
          return val.startsWith("label_");
        }),
        z.string()
      )
    )
    .default('{"label_en_US":"English","label_pl_PL":"Polski"}'),
});

export const IBoxLanguageSwitcherSettingsScheme = z.object({
  multi_language_dropdown_style:
    themeScheme.DropdownIdScheme.default("dropdown$$1").catch("dropdown$$1"),
  language_icon: themeScheme
    .JSONSettingScheme(
      z.record(
        z.custom<`icon_${LanguageType}`>((val) => {
          return val.startsWith("icon_");
        }),
        z.string()
      )
    )
    .default(
      '{"icon_en_US":"https://dev-minio-ecp.nd0.pl/ecp-media-tenant-unimax/en.svg","icon_pl_PL":"https://dev-minio-ecp.nd0.pl/ecp-media-tenant-unimax/en.svg"}'
    ),
  language_switch_mode: z
    .nativeEnum(LANGUAGE_SWITCH_MODE)
    .default(LANGUAGE_SWITCH_MODE.DROPDOWN)
    .catch(LANGUAGE_SWITCH_MODE.DROPDOWN),
  excluded_languages: themeScheme.SettingLanguagesScheme.default("").catch(""),
  button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
});
