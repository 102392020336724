import { useEffect } from "react";
import { StyledInput } from "../../../shared/components/Input/StyledInput/StyledInput";
import { ReactComponent as RemoveIcon } from "../../../shared/icons/close.svg";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { StyledInputSearchBarWrapper } from "../../BoxSearchBar/BoxSearchBar.styled";
import { StyledSearchBarWrapper } from "../BoxStoreLocator.styled";
import { IBoxStoreLocatorProps } from "../BoxStoreLocator.types";

interface StoreSearchBarProps {
  storeSearchPhrase: string;
  setStoreSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  onClickButton: (searchValue: string) => void;
}

const StoresSearchBar: React.FC<StoreSearchBarProps> = ({
  onClickButton,
  storeSearchPhrase,
  setStoreSearchPhrase,
}) => {
  const { messages, settings } = useBoxContext<IBoxStoreLocatorProps>();

  useEffect(() => {
    if (storeSearchPhrase === "") {
      onClickButton(storeSearchPhrase);
    }
  }, [storeSearchPhrase]);

  return (
    <StyledSearchBarWrapper className="stores-search-bar__container">
      <StyledInputSearchBarWrapper className="stores-search-bar__input-container">
        <StyledInput
          className="stores-search-bar__input-container__input"
          $settings={settings.store_search_bar_search_input_settings}
          placeholder={messages.store_search_bar_search_input_placeholder_text}
          type="text"
          data-testid="searchInput"
          value={storeSearchPhrase}
          onChange={(e) => setStoreSearchPhrase(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickButton(storeSearchPhrase);
            }
          }}
        />
        <div className="stores-input-search-bar__container__button-container">
          {storeSearchPhrase && (
            <button
              className="stores-search-bar__input-container__button-container__remove-button"
              data-testid="removeIcon"
              onClick={() => {
                setStoreSearchPhrase("");
                onClickButton("");
              }}
            >
              <RemoveIcon />
            </button>
          )}
        </div>
      </StyledInputSearchBarWrapper>
      <StyledButton
        className="stores-search-bar__container__button-container__search-button"
        renderAs="button"
        type="search"
        $settings={settings.store_search_bar_search_button_settings}
        onClick={() => onClickButton(storeSearchPhrase)}
      />
    </StyledSearchBarWrapper>
  );
};

export default StoresSearchBar;
