import React from "react";
import styled from "styled-components";
import { AddressPostalBilling } from "@ecp-redux/dto/cartAddresses.type";
import StyledText from "../../../../../../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../../../../../../structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../../../../BoxCartStepTwoExtended.types";
import { concatAddress } from "@ecp-boxes/helpers/helpers";

interface ChangeAddressProps {
  address: AddressPostalBilling;
}

const StyledAddressInfo = styled.div`
  overflow: hidden;

  .address-info {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const AddressInfo: React.FC<ChangeAddressProps> = ({ address }) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { addressOneLine } = concatAddress(address);
  const { firstName, lastName, phone } = address;
  return (
    <StyledAddressInfo className="left-indent">
      <StyledText
        $settings={{
          font: settings.section_strong_text_typography,
          text: { color: settings.section_strong_text_typography },
        }}
      >
        {firstName} {lastName}
      </StyledText>
      <StyledText
        $settings={{
          font: settings.section_subtext_typography,
          text: { color: settings.cart_color_primary },
        }}
        className="address-info"
      >
        <p>{addressOneLine}</p>
        <p>{phone}</p>
      </StyledText>
    </StyledAddressInfo>
  );
};

export default AddressInfo;
