import { API, api } from "./api";

interface ICurrencyResponse {
  configuredCurrencies: Array<{
    locale: `${string}_${string}`;
    currency: string;
    isDefault: boolean;
  }>;
}

export const currency = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrency: builder.query<ICurrencyResponse, void>({
      query: () => {
        return {
          url: API.getCurrency.endpoint(),
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetCurrencyQuery } = currency;

export const { getCurrency } = currency.endpoints;
