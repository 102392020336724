export const zipCodeRegex = new RegExp(/(^\d{5}$)|(^\d{9}$)|(^\d{2}-\d{3}$)/);

export const phoneRegex = new RegExp(/^[+]?[0-9]{9,13}$/);

export const removeSpecialChars = (val: string) => {
  // eslint-disable-next-line no-useless-escape
  return val.replace(/[\ \-]/gi, "");
};

export const passwordRegEx = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);
