import styled, { css } from "styled-components";
import composeCss from "../../../../settingsPatterns/composeCss";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "../../../../settingsPatterns/settingsPatterns.methods";
import { IInputSettings } from "../Inputs";
import { convertInputIdToValues } from "./StyledInput.methods";

export const StyledLine = styled.div`
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
`;

export const StyledInputLabel = styled.label<IInputSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      width: fit-content;
      color: ${convertColorIdToHex(text.labelColor, theme.colorPalette)};
      ${composeCss.font(font.label, theme.typography)};
    `;
  }
);

export const StyledInputValidation = styled.span<IInputSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      width: fit-content;
      ${composeCss.font(font.error, theme.typography)};
      color: ${convertColorIdToHex(text.errorColor, theme.colorPalette)};
    `;
  }
);

export const StyledInputComponent = styled.div<IInputSettings>(
  ({ $settings, hasLabel, theme }) => {
    const {
      width: inputWidth,
      height: inputHeight,
      font,
      floatingLabel,
    } = convertInputIdToValues($settings, theme.globalObjects?.inputs);

    const { lineHeight: fontLineHeight, size: fontSize } =
      convertTypoIdToValues(font.label, theme.typography);

    return css`
      display: flex;
      flex-direction: column;
      width: ${inputWidth}%;
      position: relative;

      label {
        order: -1;
      }

      .input-loader-container {
        position: relative;
        display: flex;
        flex-direction: row;
        position: absolute;
        display: block;
        right: 10px;
        align-self: center;
        top: calc(${inputHeight / 2}px + ${fontLineHeight / 2}px);
      }

      .eye-icon {
        position: relative;
        display: flex;
        flex-direction: row;
        position: absolute;
        display: block;
        right: 10px;
        align-self: center;
        ${hasLabel
          ? `top: calc(${inputHeight / 2}px + ${fontLineHeight / 2}px);`
          : `top: calc(${inputHeight / 2}px - ${fontLineHeight / 2}px);`}
      }

      ${floatingLabel &&
      css`
        .eye-icon {
          top: calc(${inputHeight / 2}px - ${fontLineHeight / 2}px);
        }

        input::placeholder {
          opacity: 0;
        }

        label {
          user-select: none;
          position: absolute;
          top: calc(${inputHeight / 2}px - ${fontLineHeight / 2}px);
          left: 12px;
          pointer-events: none;
          transition: all 0.1s ease-in-out;
        }

        input {
          padding-top: ${inputHeight / 2 - 10}px;
          padding-right: 10px;
          padding-left: 12px;
        }

        input:focus,
        input:not(:placeholder-shown) {
          & + label {
            top: calc(${inputHeight / 2}px - ${fontLineHeight}px);
            font-size: ${(fontSize / 16) * 0.8}rem;
            color: #7c7c7c;
          }
        }
      `}
    `;
  }
);

interface IStyledInputSearch extends IInputSettings {
  iconWrapperHeight: number;
}

export const StyledInputSearchComponent = styled.div<IStyledInputSearch>(
  ({ $settings, theme, iconWrapperHeight }) => {
    const { width } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return css`
      display: flex;
      width: ${width}%;

      .iconContainer {
        display: flex;
        align-items: center;
        position: absolute;
        height: ${iconWrapperHeight}px;
        right: 15px;
      }
    `;
  }
);

export const StyledInput = styled.input.attrs(({ type }) => ({
  type,
}))<IInputSettings>(({ $settings, theme, formNoValidate, isLoading }) => {
  const inputStyles = convertInputIdToValues(
    $settings,
    theme?.globalObjects?.inputs
  );
  const {
    height,
    font,
    border,
    background,
    text,
    errorBorder,
    activeBorder,
    disabledBorder,
    hoverBorder,
  } = inputStyles;
  return css`
    width: 100%;
    height: ${height}px;
    outline: none;
    padding-inline: 10px;
    ${isLoading ? "padding-right: 30px;" : ""}
    ${composeCss.borderWidth(border.width)}
    ${composeCss.borderRadius(border.radius)}
    ${composeCss.font(font.input, theme.typography)};
    ${composeCss.borderColor(
      formNoValidate === true || formNoValidate === undefined
        ? border.color
        : errorBorder.color,
      theme
    )}
    ${composeCss.borderStyle(border.style)}
    background-color: ${convertColorIdToHex(
      background.color,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(text.color, theme.colorPalette)};

    &:hover {
      background-color: ${convertColorIdToHex(
        background.hoverColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.hoverColor, theme.colorPalette)};
      ${composeCss.borderColor(hoverBorder.color, theme)}
    }

    &:active,
    &:focus {
      background-color: ${convertColorIdToHex(
        background.activeColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.activeColor, theme.colorPalette)};
      ${composeCss.borderColor(activeBorder.color, theme)}
    }
    &:disabled {
      background-color: ${convertColorIdToHex(
        background.disabledColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(text.disabledColor, theme.colorPalette)};
      ${composeCss.borderColor(disabledBorder.color, theme)}
    }
    &::placeholder {
      ${composeCss.font(font.placeholder, theme.typography)};
      color: ${convertColorIdToHex(text.placeholderColor, theme.colorPalette)};
    }
  `;
});
