import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";
import braintree, { GooglePayment } from "braintree-web";

import * as braintreeAPI from "@ecp-redux/api/braintree";
import { useEffect, useImperativeHandle, useState } from "react";
import Loader from "@ecp-boxes/shared/components/Loader/Loader";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import { TPaymentComponentRef } from "../sections/PaymentSection";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

const GooglePay = ({
  googlePayRef,
}: {
  googlePayRef: React.RefObject<TPaymentComponentRef>;
}) => {
  const [googlePayInstance, setGooglePayInstance] =
    useState<GooglePayment | null>(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { amountToPay } = useBoxCartStepTwoExtended();
  const { setSectionValidation } = useBoxCartStepTwoExtended();
  const { data: clientToken } = braintreeAPI.useGetClientTokenQuery(undefined, {
    skip: !isPortalSide(),
  });
  const { currency } = useLanguageCurrencyContext();

  useEffect(() => {
    if (window.google && window.google.payments) {
      console.log("Google API already loaded.");
      setGoogleLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.async = true;
    script.onload = () => {
      setGoogleLoaded(true);
    };
    script.onerror = () => {
      console.error("Failed to load Google Pay script");
      setSectionValidation("payment", false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!clientToken?.token || !googleLoaded) {
      setSectionValidation("payment", false);
      return;
    }

    const initializeBraintree = async () => {
      try {
        const client = await braintree.client.create({
          authorization: clientToken.token,
        });

        const googlePayInstance = await braintree.googlePayment.create({
          client,
          googlePayVersion: 2,
          googleMerchantId:
            process.env.NODE_ENV === "production"
              ? settings.google_pay_merchant_id
              : undefined,
        });

        const paymentsClient = new window.google.payments.api.PaymentsClient({
          environment:
            process.env.NODE_ENV === "production" ? "PRODUCTION" : "TEST",
          merchantInfo:
            process.env.NODE_ENV === "production"
              ? {
                  merchantId: settings.google_pay_merchant_id,
                  merchantName: settings.braintree_config_store_name,
                }
              : undefined,
        });

        const isReady = await paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods:
            googlePayInstance.createPaymentDataRequest().allowedPaymentMethods,
        });

        if (!isReady.result) {
          console.error("Google Pay is not ready for this user");
          setSectionValidation("payment", false);
          return;
        }

        setGooglePayInstance(googlePayInstance);
        setSectionValidation("payment", true);
      } catch (error) {
        console.error("Error initializing Braintree Google Pay:", error);
        setSectionValidation("payment", false);
      }
    };

    initializeBraintree();
  }, [
    clientToken,
    googleLoaded,
    amountToPay,
    currency,
    setSectionValidation,
    settings,
  ]);

  useImperativeHandle(googlePayRef, () => ({
    generateNonce: async () => {
      if (
        !googlePayInstance ||
        !window.google ||
        !window.google.payments ||
        typeof amountToPay !== "number"
      ) {
        console.error("Google Pay not initialized or Google API not available");
        return { nonce: null, hasError: true };
      }

      try {
        const paymentDataRequest = googlePayInstance.createPaymentDataRequest({
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: amountToPay.toFixed(2),
            currencyCode: currency,
          },
        });

        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
        cardPaymentMethod.parameters.billingAddressRequired = false;
        cardPaymentMethod.parameters.billingAddressParameters = {
          format: "MIN",
          phoneNumberRequired: false,
        };
        cardPaymentMethod.parameters.allowedCardNetworks = [
          "VISA",
          "MASTERCARD",
          "AMEX",
          "DISCOVER",
        ];

        const paymentsClient = new window.google.payments.api.PaymentsClient({
          environment:
            process.env.NODE_ENV === "production" ? "PRODUCTION" : "TEST",
        });

        const paymentData =
          await paymentsClient.loadPaymentData(paymentDataRequest);
        const payload = await googlePayInstance.parseResponse(paymentData);

        return { nonce: payload.nonce, hasError: false };
      } catch (error) {
        console.error("Error processing Google Pay payment:", error);
        return { nonce: null, hasError: true };
      }
    },
  }));

  return (
    <div className="payment-container">
      <StyledText
        $settings={{
          text: { color: settings.cart_color_primary },
          font: settings.section_subtext_typography,
        }}
      >
        {messages.payment_GOOGLE_PAY_description}
      </StyledText>
    </div>
  );
};

export default GooglePay;
