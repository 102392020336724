import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import RadioSelect from "./RadioSelect";
import Loader from "@ecp-boxes/shared/components/Loader/Loader";
import React, { useState, useEffect } from "react";
import { StyledSelectionTable } from "./SelectionTable.styled";
import classNames from "classnames";

export type TSelectionTableItem = {
  selected: boolean;
  component: React.ReactNode;
  extraComponent?: React.ReactNode;
  onClick: (...args: any[]) => void;
};

interface SelectionTableProps {
  items: TSelectionTableItem[];
  isLoading?: boolean;
  verticalPadding?: number;
}

const ANIMATION_DURATION = 200;

const SelectionTable = ({
  items,
  isLoading,
  verticalPadding = 10,
}: SelectionTableProps) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const [mountedItems, setMountedItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    items.forEach((item, index) => {
      if (item.selected && !mountedItems[index]) {
        setMountedItems((prev) => ({ ...prev, [index]: true }));
      } else if (!item.selected && mountedItems[index]) {
        const timeout = setTimeout(() => {
          setMountedItems((prev) => ({ ...prev, [index]: false }));
        }, ANIMATION_DURATION);
        timeouts.push(timeout);
      }
    });

    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [items]);

  if (items?.length === 0) return null;

  return (
    <StyledSelectionTable
      $settings={settings}
      $isLoading={isLoading}
      $verticalPadding={verticalPadding}
      $animationDuration={ANIMATION_DURATION}
    >
      {isLoading && <Loader borderSize={2} size={20} borderColor={"gray"} />}

      {items.map((item, index) => (
        <React.Fragment key={index}>
          <RadioSelect selected={item.selected} onClick={item.onClick}>
            {item.component}
          </RadioSelect>
          {(item.selected || mountedItems[index]) && item.extraComponent && (
            <div
              className={classNames(
                "expander",
                item.selected ? "expanded" : "collapsed"
              )}
            >
              <div className="expander-content">{item.extraComponent}</div>
            </div>
          )}
        </React.Fragment>
      ))}
    </StyledSelectionTable>
  );
};
export default SelectionTable;
