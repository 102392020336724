import { ChannelType } from "./cart.types";

export enum AddressType {
  POSTAL = "POSTAL",
  PACKSTATION = "PACKSTATION",
  STORE = "STORE",
  PICK_UP_POINT = "PICK_UP_POINT",
  BILLING = "BILLING",
}

export interface Address {
  id: number;
  city: string;
  zipCode: string;
  street: string;
  streetNumber: string;
  defaultAddress: boolean;
  packstationAvailable: boolean; //TODO what is this?
}

export type TAddresses =
  | AddressPackstation
  | AddressPickUpPoint
  | AddressPostalBilling
  | AddressStore;

export interface AddressPackstation extends Address {
  packstationCode: string;
}
export interface AddressPickUpPoint extends Address {
  storeName: string;
  pni: number;
}
export interface AddressPostalBilling extends Address {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  flatNumber: string;
  nip?: string;
  companyName?: string;
}
export interface AddressStore extends Address {
  storeName: string;
  storeCode: string;
  storeClickAndCollect: string;
}

export const isPackstationAddress = (
  type: TAddresses | null
): type is AddressPackstation =>
  type != null && "packstationCode" in type && type.packstationCode != null;

export const isPickUpPointAddress = (
  type: TAddresses | null
): type is AddressPickUpPoint =>
  type != null && "pni" in type && type.pni != null;

export const isPostalBillingAddress = (
  type: TAddresses | null
): type is AddressPostalBilling =>
  type != null &&
  "firstName" in type &&
  type.firstName != null &&
  type.lastName != null;

export const isStoreAddress = (type: TAddresses | null): type is AddressStore =>
  type != null && "storeCode" in type && type.storeCode != null;

export const isPostalType = (type: ChannelType) =>
  type === ChannelType.DPD ||
  type === ChannelType.POCZTEX ||
  type === ChannelType.UPS;

export const isInpostType = (type: ChannelType) => type === ChannelType.INPOST;

export const isPickUpPointType = (type: ChannelType) =>
  type === ChannelType.PICK_UP_AT_POINT;

export const isStoreType = (type: ChannelType) => type === ChannelType.STORE;

export interface IAddressesResponse {
  billingAddresses: AddressPostalBilling[];
  storeAddresses: AddressStore[];
  packstationAddresses: AddressPackstation[];
  postalAddresses: AddressPostalBilling[];
  pickUpPointAddresses: AddressPickUpPoint[];
}

export interface IAddressesAdd {
  addressId: number;
}

export type TAddressType =
  | "SHIPPING_PRIVATE"
  | "SHIPPING_COMPANY"
  | "BILLING"
  | "RECIPIENT";

export interface IBillingPostalAddAddressRequest {
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  flatNumber: string;
  zipCode: string;
  country: string;
  city: string;
  phone: string;
  companyName?: string; //only use in company
  nip?: string; //only use in company
  defaultAddress: boolean;
  type: TAddressType;
}

export interface IBillingPostalAddAddressExtendedRequest
  extends Omit<IBillingPostalAddAddressRequest, "type"> {
  type: "RECIPIENT" | "BILLING";
}

export interface IPackstationAddAddressRequest {
  packstationCode: string;
  type: AddressType.PACKSTATION;
  defaultAddress: boolean;
}

export interface IStoreAddAddressRequest {
  code: string;
  type: AddressType.STORE;
}

export type TAddAddressRequest =
  | IBillingPostalAddAddressRequest
  | IPackstationAddAddressRequest
  | IStoreAddAddressRequest;
