import {
  ColorReadoutValueScheme,
  DropdownReadoutValueScheme,
  GlobalDropdownObjectScheme,
  TypoReadoutValueScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { z } from "zod";
import { MediaAsset } from "./BoxFacetsVariants.types";

export const BoxFacetsVariantsContentScheme = z
  .object({
    facetsTitle: z.string().default("").catch(""),
    facetsObjects: z
      .record(
        z
          .object({
            show: z.boolean().default(true).catch(true),
            label: z.string().default("").catch(""),
            type: z
              .nativeEnum(MediaAsset)
              .default(MediaAsset.NONE)
              .catch(MediaAsset.NONE),
            labelVisibility: z.boolean().default(false).catch(false),
            categories: z.record(
              z.array(z.string().default("").catch("")).default([]).catch([])
            ),
            withMediaAssetConfig: z
              .object({
                size: z.number().default(15).catch(15),
                borderWidth: z.number().default(0).catch(0),
                borderRadius: z.number().default(0).catch(0),
                borderColor: ColorReadoutValueScheme,
              })
              .optional(),
          })
          .default({
            show: true,
            label: "",
            type: MediaAsset.NONE,
            labelVisibility: false,
            categories: {},
          })
      )
      .default({}),
  })
  .default({ facetsTitle: "", facetsObjects: {} });

export const BoxFacetsVariantsContentSettingsScheme = z
  .object({
    title: z
      .object({
        show: z.boolean().default(true).catch(true),
        font: TypoReadoutValueScheme,
        text: ColorReadoutValueScheme,
        gap: z.number().default(0).catch(0),
        alignment: z
          .nativeEnum(AlignmentHorizontalOption)
          .default(AlignmentHorizontalOption.LEFT)
          .catch(AlignmentHorizontalOption.LEFT),
      })
      .default({
        show: true,
        font: { id: "typo$$1", custom: {} },
        text: { id: "color$$1", custom: "#FFFFFF" },
        gap: 0,
        alignment: AlignmentHorizontalOption.LEFT,
      }),
  })
  .default({
    title: {
      show: true,
      font: { id: "typo$$1", custom: {} },
      text: { id: "color$$1", custom: "#FFFFFF" },
      gap: 0,
      alignment: AlignmentHorizontalOption.LEFT,
    },
  });

export const BoxFacetsVariantsDisplaySettingsScheme = z
  .object({
    style: DropdownReadoutValueScheme,
    spaceBetweenObjects: z.number().default(0).catch(0),
    facetsOrder: z.array(z.string()).default([]).catch([]),
  })
  .default({
    style: { id: "dropdown$$1", custom: GlobalDropdownObjectScheme.parse({}) },
    spaceBetweenObjects: 0,
    facetsOrder: [],
  });
