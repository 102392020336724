import styled from "styled-components";

export const StyledDeliverySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .delivery-channels {
    &__container {
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        &__content {
          display: flex;
          gap: 4px;
        }
        img {
          width: 48px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
    &__channel-name-container {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      &__text {
        display: flex;
        align-items: baseline;
        gap: 8px;
      }
    }
  }
`;
