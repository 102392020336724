import React from "react";
import { IIcon } from "../icons.types";

const ChevronIcon: React.FC<IIcon> = ({
  width = "7",
  height = "10",
  fill = "#000",
  viewBox = "0 0 7 10",
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99732 0.684563C5.99743 0.684668 5.99722 0.684458 5.99732 0.684563L5.80896 0.50103C5.80905 0.50112 5.80887 0.500941 5.80896 0.50103C5.61429 0.311645 5.30373 0.312014 5.10984 0.502265L0.726957 4.80297C0.527212 4.99897 0.527212 5.32074 0.726957 5.51673L5.1086 9.81623C5.30307 10.007 5.61452 10.007 5.80899 9.81623L5.99351 9.63517C6.19114 9.44124 6.19325 9.12415 5.99971 8.9276L2.1658 5.15985L5.99826 1.39925C5.99814 1.39937 5.99839 1.39913 5.99826 1.39925C6.19803 1.20284 6.19781 0.880251 5.99732 0.684563Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronIcon;
