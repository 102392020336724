import React, { useEffect, useMemo, useState } from "react";
import * as cartAPI from "@ecp-redux/api/cart";
import * as searchResults from "@ecp-redux/api/searchResults";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  StyledSummary,
  StyledFixedSummary,
} from "@ecp-boxes/boxes/Cart/BoxCartStepOne/BoxCartStepOne.styled";
import PromoCode from "@ecp-boxes/boxes/Cart/shared/PromoCode/PromoCode";
import {
  formatPrice,
  isClientSide,
  isPortalSide,
} from "@ecp-boxes/helpers/helpers";
import { isNotEmptyMessage } from "@ecp-boxes/helpers/isNotEmptyMessage";
import Alert from "@ecp-boxes/shared/components/Alert/Alert";
import useIsMobilePortal from "@ecp-boxes/shared/hooks/useIsMobilePortal";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { useProductInfo } from "@ecp-boxes/shared/hooks/useProductInfo";
import ProductCartElementExtended from "./element/ProductCartElementExtended";
import { TSrpProductType } from "@ecp-redux/dto/searchResults.types";
import useGetProductValueByKey from "@ecp-boxes/boxes/BoxSearchResultsB2B/hooks/GetProductValueByKey";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { ICartSummary } from "@ecp-redux/dto/cart.types";
import PlaceOrderButton from "../PlaceOrderButton/PlaceOrderButton";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";
import {
  AddressPostalBilling,
  IBillingPostalAddAddressExtendedRequest,
} from "@ecp-redux/dto/cartAddresses.type";

type ISummaryExtendedProps = {
  cartSummary: ICartSummary;
  isSelectedDeliveryChannel: boolean;
  handlePurchase: () => void;
  isPaymentProcessing: boolean;
  isPaypalPaymentSelected: boolean;
  paymentAutofillAddress:
    | AddressPostalBilling
    | IBillingPostalAddAddressExtendedRequest
    | null;
};

const SummaryExtended: React.FC<ISummaryExtendedProps> = ({
  cartSummary,
  isSelectedDeliveryChannel,
  handlePurchase,
  isPaymentProcessing,
  isPaypalPaymentSelected,
  paymentAutofillAddress,
}) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  const { data, isUninitialized, isLoading } =
    cartAPI.useGetCartByPortalUserTokenQuery();
  const intersectionRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useIsMobilePortal();
  const [totalStickyHeaderHeight, setTotalStickyHeaderHeight] = useState(0);
  const isInitialized = !isUninitialized && !isLoading;

  const skus = useMemo(
    () =>
      data?.cartViewResponse.orderLines.map((orderLine) => ({
        productSku: orderLine.productSku,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.cartViewResponse.orderLines.length]
  );

  const { getProductInfo, isLoadingProduct } = useProductInfo(skus);

  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;

  useEffect(() => {
    if (!isClientSide()) return;

    const updateHeight = () => {
      requestAnimationFrame(() => {
        const stickyElements = Array.from(
          document.querySelectorAll("header > *")
        ).filter((el) => window.getComputedStyle(el).position === "sticky");

        const totalHeight = stickyElements.reduce((sum, element) => {
          return sum + element.getBoundingClientRect().height;
        }, 0);

        setTotalStickyHeaderHeight(totalHeight);
      });
    };
    const observer = new MutationObserver(updateHeight);
    document.querySelectorAll("header > *").forEach((el) =>
      observer.observe(el, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    );

    updateHeight();

    return () => observer.disconnect();
  }, [isMobile]);

  useEffect(() => {
    if (!isInitialized || isLoadingProduct) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isInitialized, isLoadingProduct]);

  const {
    cartValueFromProducts,
    cartValueAfterDiscounts,
    cartDeliveryCosts,
    amountToPay,
  } = cartSummary;
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { currencyShort } = useLanguageCurrencyContext();

  const attributes =
    settings.product_subtitle_for_cart_attribute_row_1.split(";");

  const { data: productsVm } = searchResults.useGetSearchWithSrpResultsV2Query({
    searchQueries: skus
      ? `&filter=sku:${skus.map((sku) => sku.productSku).join(";")}`
      : "",
    projection: [...attributes, "sku"],
  });
  const getProductValue = useGetProductValueByKey();

  const attributeValues = useMemo(() => {
    if (productsVm?.products?.length) {
      return Object.fromEntries(
        productsVm.products.map((product) => [
          product.sku,
          attributes
            .map((attribute) => getProductValue(product, attribute))
            .filter((value) => value !== null) as string[],
        ])
      );
    }
    return {};
  }, [productsVm?.products]);

  if (isLoadingProduct) {
    return null;
  }

  return (
    <>
      <StyledSummary
        data-testid="Summary"
        className="summary"
        backgroundColor={settings.basket_step_product_summary_background_color}
        buttonWidth={settings.basket_step_all_summary_button_width}
        isSticky={settings.basket_step_all_summary_sticky_enabled === "true"}
        totalStickyHeaderHeight={totalStickyHeaderHeight}
      >
        <div className="summary_content">
          <StyledText
            className="summary__header"
            $settings={{
              font: settings.section_title_typography,
              text: { color: settings.cart_color_primary },
            }}
            show={isNotEmptyMessage(messages.summary_header)}
          >
            <p>{messages.summary_header}</p>
          </StyledText>

          <div className="summary__product-cart-element-container">
            {data?.cartViewResponse.orderLines.map((product, index) => (
              <div key={product.productSku}>
                <ProductCartElementExtended
                  product={product}
                  imageUrl={
                    getProductInfo(product.productSku)?.coverPhoto ??
                    globalSettings.dynamicBoxImagePlug
                  }
                  productType={
                    getProductInfo(product.productSku)?.conceptId
                      ? TSrpProductType.CONCEPT
                      : TSrpProductType.PRODUCT
                  }
                  imageHref={getProductInfo(product.productSku)?.url}
                  deliveryTime={
                    getProductInfo(product.productSku)?.deliveryTime
                  }
                  key={product.productSku}
                  attributeValues={attributeValues[product.productSku] ?? []}
                />
                {index !== data.cartViewResponse.orderLines.length - 1 && (
                  <hr className="summary__product-cart-underline" />
                )}
              </div>
            ))}
          </div>

          {settings.show_promo_code_input === "true" && (
            <>
              <PromoCode />
              <hr className="summary__promo-code-separator" />
            </>
          )}

          <Alert
            alertsCodes={["DELIVERY_CHANNELS_NOT_AVAILABLE", "1010"]}
            backgroundColor={settings.basket_alert_background}
            textColor={settings.basket_alert_typo_color}
            typography={settings.basket_alert_typo}
            closeable={false}
          />

          <div ref={intersectionRef}>
            <StyledText
              className="summary__products-price"
              $settings={{
                font: settings.section_subtext_typography,
                text: { color: settings.cart_color_primary },
              }}
              show={isNotEmptyMessage(messages.summary_products_price)}
            >
              <p>{messages.summary_products_price}</p>
              <p>{formatPrice(cartValueFromProducts, currencyShort)}</p>
            </StyledText>
            {cartValueFromProducts - cartValueAfterDiscounts >= 0.01 && (
              <StyledText
                className="summary__save-amount"
                $settings={{
                  font: settings.section_subtext_typography,
                  text: { color: settings.cart_color_secondary },
                }}
                show={isNotEmptyMessage(messages.summary_save_ammont)}
              >
                <p>{messages.summary_save_ammont}</p>
                <p>
                  {formatPrice(
                    cartValueFromProducts - cartValueAfterDiscounts,
                    currencyShort
                  )}
                </p>
              </StyledText>
            )}
            <StyledText
              className="summary__delivery-cost"
              $settings={{
                font: settings.section_subtext_typography,
                text: { color: settings.cart_color_primary },
              }}
              show={
                isSelectedDeliveryChannel
                  ? isNotEmptyMessage(
                      messages.summary_delivery_costs_after_select
                    )
                  : isNotEmptyMessage(
                      messages.summary_delivery_costs_before_select
                    )
              }
            >
              <p>
                {isSelectedDeliveryChannel
                  ? messages.summary_delivery_costs_after_select
                  : messages.summary_delivery_costs_before_select}
              </p>
              <p>{formatPrice(cartDeliveryCosts, currencyShort)}</p>
            </StyledText>
            <hr className="summary__summary-underline" />
            <div className="summary__amount-to-pay">
              <StyledText
                $settings={{
                  font: settings.section_subtext_large_typography,
                  text: { color: settings.cart_color_primary },
                }}
                show={isNotEmptyMessage(messages.summary_amount_to_pay)}
              >
                <p>{messages.summary_amount_to_pay}</p>
              </StyledText>
              <StyledText
                $settings={{
                  font: settings.summary_price_large,
                  text: { color: settings.cart_color_primary },
                }}
                show={isNotEmptyMessage(messages.summary_amount_to_pay)}
              >
                <p>{formatPrice(amountToPay, currencyShort)}</p>
              </StyledText>
            </div>
          </div>
          {isMobile && (
            <PlaceOrderButton
              onClick={handlePurchase}
              isPaymentProcessing={isPaymentProcessing}
              isPaypalPaymentSelected={isPaypalPaymentSelected}
              paymentAutofillAddress={paymentAutofillAddress}
              handlePurchase={handlePurchase}
            />
          )}
        </div>
      </StyledSummary>
      {isMobile && isPortalSide() && !isPaypalPaymentSelected && (
        <StyledFixedSummary
          inert={!isIntersecting}
          $backgroundColor={settings.basket_step_all_background_color}
          $isVisible={!isIntersecting}
          className="fixed-summary"
        >
          <StyledText
            className="fixed-summary-row"
            $settings={{
              font: settings.section_subtext_typography,
              text: { color: settings.cart_color_primary },
            }}
            show={isNotEmptyMessage(messages.summary_products_price)}
          >
            <p>{messages.fixed_summary_total_price}</p>
            <p>{formatPrice(amountToPay, currencyShort)}</p>
          </StyledText>
          <StyledText
            className="fixed-summary-row fixed-summary-row--last-row"
            $settings={{
              font: settings.section_subtext_typography,
              text: {
                color: settings.cart_color_primary,
              },
            }}
          >
            <p>{messages.fixed_summary_delivery_costs}</p>
            <p>
              {`${messages.fixed_summary_from} ${formatPrice(
                cartDeliveryCosts,
                currencyShort
              )}`}
            </p>
          </StyledText>
          {isMobile && (
            <PlaceOrderButton
              onClick={handlePurchase}
              isPaymentProcessing={isPaymentProcessing}
              isPaypalPaymentSelected={isPaypalPaymentSelected}
              paymentAutofillAddress={paymentAutofillAddress}
              handlePurchase={handlePurchase}
            />
          )}
        </StyledFixedSummary>
      )}
    </>
  );
};

export default SummaryExtended;
