import styled from "styled-components";

export const StyledPromoCode = styled.div`
  display: flex;
  align-items: center;
  row-gap: 8px;
  flex-wrap: wrap;
  .promo-code__input {
    width: unset;
    flex-grow: 1;
  }
  .promo-code__button {
    width: fit-content;
    flex-grow: 0;
  }
  .promo-code__error-message,
  .promo-code__additional-promo-description {
    flex: 1 1 100%;
  }
`;
