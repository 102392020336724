import React, { useEffect, useState } from "react";
import { AddressType } from "@ecp-redux/dto/cartAddresses.type";
import { StyledSelectedAddress } from "../../BoxCartStepTwoExtended.styled";
import AddressInfo from "../DeliveryChannels/elements/SelectedAddress/elements/AddressInfo";
import ChangeBillingPostalAddressExtended from "@ecp-boxes/boxes/Cart/shared/ChangeBillingPostalAddressExtended";
import { StyledAddressSection } from "../../components/sections/AddressSection.styled";
import SectionWrapper from "../../components/sections/SectionWrapper";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import { Checkbox } from "@ecp-boxes/shared/components/CheckboxesList/CheckboxesList";
import { useForm } from "react-hook-form";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import * as cartAPI from "@ecp-redux/api/cart";
import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";

const BillingSection: React.FC = () => {
  const [editAddress, setEditAddress] = useState(false);
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const [isBillingAddressValid, setIsBillingAddressValid] = useState(false);
  const { data: cartData } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const {
    isLogged,
    setSectionValidation,
    selectedAddress,
    setSelectedBillingAddress,
    setIsSameBillingAddress,
  } = useBoxCartStepTwoExtended();

  const handleToggleEditAddress = () => {
    setEditAddress(!editAddress);
  };

  const { getSessionStorageItem } = useSetSessionStorage();

  const { control, watch } = useForm({
    defaultValues: {
      copyPostalToBilling: !getSessionStorageItem("billing_address"),
    },
  });
  const { billingAddress, billingAddresses } = cartData?.response ?? {
    billingAddress: null,
    billingAddresses: [],
  };

  const hasSelectedBillingAddress = billingAddress !== null;
  const copyPostalToBillingValue = watch("copyPostalToBilling");

  useEffect(() => {
    setSectionValidation(
      "billingAddress",
      copyPostalToBillingValue ||
        hasSelectedBillingAddress ||
        (!isLogged && isBillingAddressValid)
    );
    setIsSameBillingAddress(copyPostalToBillingValue);
  }, [
    isBillingAddressValid,
    isLogged,
    setSectionValidation,
    copyPostalToBillingValue,
    hasSelectedBillingAddress,
    setIsSameBillingAddress,
  ]);

  useEffect(() => {
    if (copyPostalToBillingValue && selectedAddress) {
      setSelectedBillingAddress({ ...selectedAddress, type: "BILLING" });
    }
  }, [
    billingAddress,
    setSelectedBillingAddress,
    copyPostalToBillingValue,
    selectedAddress,
  ]);

  return (
    <StyledAddressSection
      className="delivery-channels"
      data-testid="DeliveryChannelsComponent"
      $extraBottomMargin
    >
      <SectionWrapper
        variant="billing"
        isEdit={editAddress || copyPostalToBillingValue}
        setIsEdit={setEditAddress}
        withEditButton={
          isLogged && !copyPostalToBillingValue && !!billingAddress
        }
        withSeparator={false}
      >
        {!copyPostalToBillingValue && (
          <StyledSelectedAddress className="address__selected-address-container">
            {!editAddress && billingAddress && isLogged ? (
              <AddressInfo address={billingAddress} />
            ) : (
              <ChangeBillingPostalAddressExtended
                handleToggleEditAddress={handleToggleEditAddress}
                selectedAddress={billingAddress}
                addresses={billingAddresses}
                addressType={AddressType.BILLING}
                setIsAddressValid={setIsBillingAddressValid}
              />
            )}
          </StyledSelectedAddress>
        )}

        <Checkbox
          control={control}
          name="copyPostalToBilling"
          label={messages.form_radio_same_address}
          textStyles={{
            labelTypo: settings.basket_step_2_checkbox_typo,
            labelColor: settings.basket_step_2_checbox_color,
            errorTypo: settings.basket_step_2_checkbox_typo,
            errorColor: settings.basket_step_2_checbox_color,
          }}
        />
      </SectionWrapper>
    </StyledAddressSection>
  );
};

export default BillingSection;
