import React, { useEffect, useState } from "react";
import { ChannelType, IDeliveryChannel } from "@ecp-redux/dto/cart.types";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../../../structure/Contexts/BoxContext";
import * as cartAPI from "@ecp-redux/api/cart";
import SectionWrapper from "./SectionWrapper";
import { StyledDeliverySection } from "./DeliverySection.styled";
import { formatPrice } from "@ecp-boxes/helpers/helpers";
import SelectionTable from "../shared/SelectionTable";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import DeliveryIcon from "../shared/DeliveryIcon";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import YourPackstationsSection from "./YourPackstationsSection";
import PickUpInStoreSection from "./PickUpInStoreSection";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

const NON_VALIDATED_CHANNELS = [
  ChannelType.POCZTEX,
  ChannelType.UPS,
  ChannelType.DPD,
];

const DeliverySection: React.FC = () => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { isPackstationSelected, isPickupInStoreSelected } =
    useBoxCartStepTwoExtended();
  const { data } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const { currencyShort } = useLanguageCurrencyContext();
  const { deliveryChannels } = data?.response ?? {};
  const selectedDeliveryChannel = deliveryChannels?.find((e) => e.selected);
  const hasDeliveryAddressSelected = !!(
    selectedDeliveryChannel && selectedDeliveryChannel.selectedAddress !== null
  );

  const [isEdit, setIsEdit] = useState(selectedDeliveryChannel === undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { setSectionValidation } = useBoxCartStepTwoExtended();
  const [changeDeliveryChannel] =
    cartAPI.usePostChangeDeliveryChannelExtendedMutation();
  const deliveryTranslation = selectedDeliveryChannel
    ? messages[`delivery_message_${selectedDeliveryChannel.channelType}`]
    : "";

  useEffect(() => {
    setSectionValidation(
      "delivery",
      NON_VALIDATED_CHANNELS.includes(
        selectedDeliveryChannel?.channelType as ChannelType
      ) || hasDeliveryAddressSelected
    );
    setIsEdit(!selectedDeliveryChannel);
  }, [
    selectedDeliveryChannel,
    hasDeliveryAddressSelected,
    setSectionValidation,
  ]);

  const deliveryOptionComponent = ({
    channelName,
    price,
    channelType,
  }: IDeliveryChannel) => {
    return (
      <div className="delivery-channels__container__item">
        <div className="delivery-channels__container__item__content">
          <StyledText>{formatPrice(price, currencyShort)}</StyledText>
          <StyledText>{channelName}</StyledText>
        </div>
        <DeliveryIcon channelType={channelType} channelName={channelName} />
      </div>
    );
  };

  const deliveryOptions =
    deliveryChannels?.map((channel) => ({
      selected: channel.selected,
      component: deliveryOptionComponent(channel),
      onClick: async () => {
        setIsLoading(true);
        await changeDeliveryChannel({
          deliveryChannelType: channel.channelType,
        });
        setIsEdit(false);
        setIsLoading(false);
      },
    })) ?? [];

  return (
    <StyledDeliverySection
      className="delivery-channels"
      data-testid="DeliveryChannelsComponent"
    >
      <SectionWrapper
        variant="delivery"
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        withSeparator={!isEdit}
        withEditButton={!!selectedDeliveryChannel}
      >
        {isEdit || selectedDeliveryChannel === undefined ? (
          <div className="delivery-channels__container">
            <SelectionTable
              isLoading={isLoading}
              verticalPadding={20}
              items={deliveryOptions}
            />
          </div>
        ) : (
          <div className="delivery-channels__channel-name-container left-indent">
            <div className="delivery-channels__channel-name-container__text">
              <StyledText
                className="delivery-channels__delivery-price"
                $settings={{
                  font: settings.section_strong_text_typography,
                  text: { color: settings.cart_color_primary },
                }}
              >
                {formatPrice(
                  selectedDeliveryChannel?.price ?? 0,
                  currencyShort
                )}
              </StyledText>
              <StyledText
                className="delivery-channels__delivery-name"
                $settings={{
                  font: settings.section_subtext_typography,
                  text: { color: settings.cart_color_primary },
                }}
              >
                {deliveryTranslation}
              </StyledText>
            </div>
            <DeliveryIcon {...selectedDeliveryChannel} />
          </div>
        )}
        {!isEdit && (
          <>
            {isPackstationSelected && <YourPackstationsSection />}
            {isPickupInStoreSelected && <PickUpInStoreSection />}
          </>
        )}
      </SectionWrapper>
    </StyledDeliverySection>
  );
};

export default DeliverySection;
