import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxRemindMessagesScheme = z.object({
  remind_password_form_title:
    themeScheme.MessageScheme.default("Remind password"),
  remind_password_form_description: themeScheme.MessageScheme.default(
    "Enter your email, if we have it in the system, we will send you a password reminder"
  ),
  remind_password_form_empty_verification_email:
    themeScheme.MessageScheme.default("Email is required"),
  remind_password_form_syntax_verification_email:
    themeScheme.MessageScheme.default(
      "Enter valid e-mail, eg. kowalski@domena.pl"
    ),
  remind_password_form_heading_email:
    themeScheme.MessageScheme.default("Email"),
  remind_password_form_placeholder_email:
    themeScheme.MessageScheme.default("Email"),
  remind_password_form_button:
    themeScheme.MessageScheme.default("Remind password"),
  remind_password_form_success: themeScheme.MessageScheme.default(
    "A password reset link has been sent to the e-mail address."
  ),
  remind_password_form_login:
    themeScheme.MessageScheme.default("Back to login."),
  remind_password_form_second_step_title:
    themeScheme.MessageScheme.default("Reset password"),
  remind_password_form_heading_password:
    themeScheme.MessageScheme.default("Password"),
  remind_password_form_placeholder_password:
    themeScheme.MessageScheme.default("Password"),
  remind_password_form_heading_repeat_password:
    themeScheme.MessageScheme.default("Repeat password"),
  remind_password_form_placeholder_repeat_password:
    themeScheme.MessageScheme.default("Repeat password"),
  remind_password_form_button_2:
    themeScheme.MessageScheme.default("Set new password"),
  remind_password_form_empty_verification_password:
    themeScheme.MessageScheme.default("Completing the field is required"),
  remind_password_form_syntax_verification_password:
    themeScheme.MessageScheme.default(
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  remind_password_form_diffrent_verification_password:
    themeScheme.MessageScheme.default("Passwords must match"),
  remind_password_form_sucess_send_activate_link:
    themeScheme.MessageScheme.default("Activation link has been send to email"),
  remind_password_activation_link_expired: themeScheme.MessageScheme.default(
    "The activation link has expired. Regenerate link."
  ),
  remind_password_resend_activation_link_button:
    themeScheme.MessageScheme.default("Generate token"),
  remind_password_resend_activation_link_terms:
    themeScheme.MessageScheme.default(
      "Możesz z łatwością cofnąć każdą z wyrażonych zgód, w każdej chwili, poprzez jeden z poniższych sposobów: zmianę ustawień Twojego konta na stronie http://www.hennes.com/, wysyłając wiadomość na adres mojedane@hennes.com, poprzez formularz kontaktowy na stronie https://www.hennes.com/contacts wybierając temat :ochrona danych osobowych, lub wysyłając list na adres: Hennes S.A., ul. Domaniewska 48, 02-672 Warszawa (najlepiej z dopiskiem „Dane Osobowe”)."
    ),
  remind_password_same_password_error: themeScheme.MessageScheme.default(
    "Nowe hasło musi się różnić od poprzedniego"
  ),
  remind_password_activation_link_expired_description:
    themeScheme.MessageScheme.default(
      ""
    ),
});

export const IBoxRemindSettingsScheme = z.object({
  remind_password_form_submit_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  remind_password_form_button_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  remind_password_form_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  remind_password_form_elements_gap:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  remind_password_form_wrapper_margin: themeScheme.MarginSizeScheme.default(
    "30px 30px 30px 30px"
  ).catch("30px 30px 30px 30px"),
  remind_password_form_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  remind_password_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  remind_password_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remind_password_form_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  remind_password_form_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  remind_password_form_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remind_password_form_description_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  remind_password_form_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  remind_password_form_button_style_2:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  remind_password_form_button_style_3:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  remind_password_form_redirect_login:
    themeScheme.UrlScheme.default("/login").catch("/login"),
  remind_password_redirect_blocked_account:
    themeScheme.UrlScheme.default("").catch(""),
  remind_password_redirect_link_sent:
    themeScheme.UrlScheme.default("").catch(""),
  remind_password_redirect_successful_password_change:
    themeScheme.UrlScheme.default("").catch(""),
  remind_password_form_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  remind_password_form_redirect_login_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  remind_password_form_login_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  remind_password_form_wrapper_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  remind_password_error_background_color:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  remind_password_error_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  remind_password_error_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remind_password_expired_token_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  remind_password_expired_token_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remind_password_expired_token_description_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  remind_password_expired_token_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remind_password_expired_token_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
