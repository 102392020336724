import styled, { css } from "styled-components";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

export const StyledSimplyAlert = styled.div<{
  backgroundColor: `#${string}` | `color$$${number}`;
  withoutLeftPadding?: boolean;
}>(({ backgroundColor, theme, withoutLeftPadding }) => {
  return css`
    background-color: ${convertColorIdToHex(
      backgroundColor,
      theme.colorPalette
    )};
    width: 100%;
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    ${withoutLeftPadding && `padding-left: 0;`}

    .simply-alert__icon-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .simply-alert__close-button {
      opacity: 1;

      &:hover {
        opacity: 0.25;
      }
    }
  `;
});
