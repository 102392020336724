import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxVerifyPhoneNumberMessagesScheme = z.object({
  verify_phone_number_form_title: themeScheme.MessageScheme.default(
    "Potwierdź swój numer telefonu"
  ),
  verify_phone_number_form_description: themeScheme.MessageScheme.default(
    "Weryfikacja numeru telefonu jest konieczna, aby upewnić się, że posiadamy poprawny kontakt do Ciebie"
  ),
  verify_phone_number_form_label_action: themeScheme.MessageScheme.default(
    "Kod do mnie nie dotarł"
  ),
  verify_phone_number_button_label:
    themeScheme.MessageScheme.default("Zwerifikuj"),
  verify_phone_number_form_error_message:
    themeScheme.MessageScheme.default("Hasło niepoprawne"),
  verify_phone_number_form_blocked_message: themeScheme.MessageScheme.default(
    "Twoje konto zostało zablokowane na minutę. Za chwilę spróbuj ponownie."
  ),
  verify_phone_number_form_blocked_label:
    themeScheme.MessageScheme.default("Blokada na"),
  verify_phone_number_form_blocked_label_time_unit:
    themeScheme.MessageScheme.default("min"),
});

export const IBoxVerifyPhoneNumberSettingsScheme = z.object({
  verify_phone_number_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  verify_phone_number_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  verify_phone_number_form_title_spacing:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  verify_phone_number_form_description_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  verify_phone_number_form_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  verify_phone_number_form_blocked_description_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  verify_phone_number_form_blocked_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  verify_phone_number_form_blocked_label_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  verify_phone_number_form_blocked_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  verify_phone_number_form_description_spacing:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  verify_phone_number_input_style:
    themeScheme.InputIdScheme.default("input$$1"),
  verify_phone_number_label_action_style:
    themeScheme.ButtonIdScheme.default("button$$1"),
  verify_phone_number_button_style:
    themeScheme.ButtonIdScheme.default("button$$1"),
  verify_phone_number_form_wrapper_margin:
    themeScheme.MarginSizeScheme.default("0px 0px 0px 0px").catch(
      "0px 0px 0px 0px"
    ),
  verify_phone_number_form_divided_input:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  verify_phone_number_form_code_field_gap:
    themeScheme.SizeCSSScheme.default("14px").catch("14px"),
});
