import {
  BoxMultiMenuContentScheme,
  BoxMultimenuContentSettingsScheme,
} from "./../../../boxes/BoxMultiMenu/BoxMultiMenu.scheme";
import { z } from "zod";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import { IBoxBaseSettings } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  ColorReadoutValueScheme,
  LinkReadoutValueScheme,
  SpacingScheme,
  TypoReadoutValueScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  ISpacing,
  SettingPositionOptions,
  TColorReadoutValue,
  TLinkReadoutValue,
  TRowWidth,
  TTypoReadoutValue,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxMultiMenuMessagesScheme,
  IBoxMultiMenuSettingsScheme,
} from "../../../boxes/BoxMultiMenu/BoxMultiMenu.scheme";
import { ILinkContent } from "../../contentPatterns.types";

export enum MultiMenuType {
  MENU_HORIZONTAL = "MENU_HORIZONTAL",
  MENU_LIST = "MENU_LIST",
}

export enum MultiMenuStyleOptions {
  STYLE_1 = "STYLE_1",
  STYLE_2 = "STYLE_2",
  STYLE_3 = "STYLE_3",
}

export enum MultimenuHorizontalExpandOptions {
  DROPDOWN = "DROPDOWN",
  FULL_WIDTH = "FULL_WIDTH",
}

export enum MultiMenuItemType {
  LINK = "LINK",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export type TMenuItemStyle = {
  id: MultiMenuStyleOptions;
  link: TLinkReadoutValue;
  font: TTypoReadoutValue;
};

const MenuItemsStyleSchema = z.object({
  id: z
    .nativeEnum(MultiMenuStyleOptions)
    .default(MultiMenuStyleOptions.STYLE_1)
    .catch(MultiMenuStyleOptions.STYLE_1),
});

export type TMenuItemContent = {
  heading: string;
  linkType: {
    iconUrl: TUrl;
    link: ILinkContent;
  };
  imageType: {
    url: TUrl;
    link: ILinkContent;
    seoDescription: string;
  };
  textType: string;
};

export type TMenuColumnContent = {
  columnSpan: number;
  items: TMenuItemContent[];
};

export type TMenuGroupContent = {
  heading: string;
  link: ILinkContent;
  linkType: {
    iconUrl: TUrl;
  };
  columns: TMenuColumnContent[];
};

export type TMenuItemContentSettings = {
  type: MultiMenuItemType;
  linkType: {
    style: MultiMenuStyleOptions;
    icon: {
      position: SettingPositionOptions;
      size: number;
      spacing: number;
    };
  };
};

export type TMenuColumnContentSettings = {
  items: TMenuItemContentSettings[];
};

export type TMenuGroupContentSettings = {
  linkType: {
    style: MultiMenuStyleOptions;
    icon: {
      position: SettingPositionOptions;
      size: number;
      spacing: number;
    };
  };
  columns: TMenuColumnContentSettings[];
  width: TRowWidth;
  editColumnsWidth: {
    expandType: MultimenuHorizontalExpandOptions;
    dropdownPercentage: number;
    fullPercentage: number;
  };
  padding: ISpacing;
};

export type IBoxMultiMenuData = GenericBoxData<
  IBoxMultiMenuContent,
  IBoxMultiMenuContentSettings,
  IBoxMultiMenuDisplaySettings,
  IBoxMultiMenuDataMessages,
  IBoxMultiMenuDataSettings
>;

export type IBoxMultiMenuDataMessages = z.infer<
  typeof IBoxMultiMenuMessagesScheme
>;
export type IBoxMultiMenuDataSettings = z.infer<
  typeof IBoxMultiMenuSettingsScheme
>;

export type IBoxMultiMenuContent = z.infer<typeof BoxMultiMenuContentScheme>;

const defaultItem = {
  id: MultiMenuStyleOptions.STYLE_1,
  font: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      letterSpacing: 0,
      lineHeight: 0,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 16,
    },
  },
  link: {
    id: "link$$1",
    custom: {
      text: {
        activeColor: {
          id: "color$$1",
          custom: "#",
        },
        color: {
          id: "color$$1",
          custom: "#",
        },
        hoverColor: {
          id: "color$$1",
          custom: "#",
        },
      },
    },
  },
};

const defaultBorder = {
  color: {
    id: "color$$1",
    custom: "#",
  } as TColorReadoutValue,
  size: 0,
};

const defaultFont = {
  id: "typo$$1",
  custom: {
    family: FontFamilyOptions.ROBOTO,
    letterSpacing: 0,
    lineHeight: 0,
    size: 16,
    weight: FontWeightOptionsLongListOfWeights.Weight300,
  },
} as TTypoReadoutValue;

export const MultiMenuDisplaySettingsScheme = z
  .object({
    items: z
      .array(
        z.object({
          id: z.nativeEnum(MultiMenuStyleOptions),
          link: LinkReadoutValueScheme,
          font: TypoReadoutValueScheme,
        })
      )
      .default([defaultItem, defaultItem, defaultItem])
      .catch([]),
    background: z
      .object({
        color: ColorReadoutValueScheme,
      })
      .default({
        color: {
          id: "color$$1",
          custom: "#",
        },
      })
      .catch({
        color: {
          id: "color$$1",
          custom: "#",
        },
      }),
    border: z
      .object({
        size: z.number(),
        color: ColorReadoutValueScheme,
      })
      .default(defaultBorder)
      .catch(defaultBorder),
    contentAlignment: z
      .object({
        group: z.nativeEnum(AlignmentHorizontalOption),
        columns: z.nativeEnum(AlignmentHorizontalOption),
        openMenu: z.nativeEnum(AlignmentHorizontalOption),
      })
      .default({
        columns: AlignmentHorizontalOption.LEFT,
        group: AlignmentHorizontalOption.LEFT,
        openMenu: AlignmentHorizontalOption.CENTER,
      })
      .catch({
        columns: AlignmentHorizontalOption.LEFT,
        group: AlignmentHorizontalOption.LEFT,
        openMenu: AlignmentHorizontalOption.CENTER,
      }),
    shadow: z.boolean().default(false),
    font: TypoReadoutValueScheme.default(defaultFont).catch(defaultFont),
    text: ColorReadoutValueScheme.default({
      id: "color$$1",
      custom: "#",
    }).catch({
      id: "color$$1",
      custom: "#",
    }),
    underline: z
      .object({
        show: z.boolean().default(true),
        color: ColorReadoutValueScheme.default({ id: "color$$1", custom: "#" }),
        thickness: z.number().default(5),
        gap: z.number().default(10),
      })
      .default({
        show: true,
        color: { id: "color$$1", custom: "#" },
        thickness: 5,
        gap: 10,
      })
      .catch({
        show: true,
        color: { id: "color$$1", custom: "#" },
        thickness: 5,
        gap: 10,
      }),
    spacing: z
      .object({
        itemHeader: SpacingScheme.catch(SpacingScheme.parse({})),
        itemHeaderGap: z.number().default(0).catch(0),
      })
      .default({
        itemHeader: { top: 0, right: 0, bottom: 0, left: 0 },
        itemHeaderGap: 0,
      })
      .catch({
        itemHeader: { top: 0, right: 0, bottom: 0, left: 0 },
        itemHeaderGap: 0,
      }),
  })
  .default({});

export type IBoxMultiMenuDisplaySettings = z.infer<
  typeof MultiMenuDisplaySettingsScheme
>;

export type IBoxMultiMenuContentSettings = z.infer<
  typeof BoxMultimenuContentSettingsScheme
>;

export type IBoxMultiMenuSettings = IBoxBaseSettings<
  IBoxMultiMenuDisplaySettings & IBoxMultiMenuContentSettings
>;
