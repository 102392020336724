import { isClientSide } from "../../helpers/helpers";
import { useState } from "react";

const useLocalStorage = (key: string) => {
  const [value, setValue] = useState(
    isClientSide() ? localStorage.getItem(key) : ""
  );

  const setValues = (value: string) => {
    setValue(value);
    isClientSide() && localStorage.setItem(key, value);
  };

  const removeItem = () => {
    setValue("");
    isClientSide() && localStorage.removeItem(key);
  };

  return {
    value,
    setValues,
    removeItem,
  };
};

export default useLocalStorage;
