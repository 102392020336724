import classNames from "classnames";
import { StyledRadioSelect } from "./RadioSelect.styled";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";

interface IRadioSelectProps {
  children: React.ReactNode;
  selected: boolean;
  onClick: (...args: any[]) => void;
}

const RadioSelect = ({ children, selected, onClick }: IRadioSelectProps) => {
  const {
    settings: { radio_button_color, selection_background_color },
  } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  return (
    <StyledRadioSelect
      $radioButtonColor={radio_button_color}
      selectionColor={selection_background_color}
      onClick={onClick}
      $selected={selected}
      className={classNames("radio-select", {
        "radio-select--selected": selected,
      })}
    >
      <div className="radio-select__dot"></div>
      <div className="radio-select__content">{children}</div>
    </StyledRadioSelect>
  );
};
export default RadioSelect;
