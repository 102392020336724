import { useState } from "react";
import { IBoxCartStepOneProps } from "@ecp-boxes/boxes/Cart/BoxCartStepOne/BoxCartStepOne.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { StyledInput } from "../../../../shared/components/Input/StyledInput/StyledInput";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { StyledPromoCode } from "./PromoCode.styled";
import {
  useDeleteCartCouponMutation,
  useGetCartByPortalUserTokenQuery,
  usePostCartCouponMutation,
} from "@ecp-redux/api/cart";
import { ReactComponent as TrashIcon } from "../../../../shared/icons/trash.svg";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import classNames from "classnames";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended/BoxCartStepTwoExtended.types";

const alertMessagesMap = {
  3531: "promo_code_error_message",
  3502: "promo_code_error_3502",
  3527: "promo_code_error_3527",
} as const;

const PromoCode = () => {
  const {
    settings: {
      promo_code_button_style,
      promo_code_input_style,
      promo_code_error_message_style,
      promo_code_error_message_color,
      promo_code_additional_promo_description_style,
      promo_code_additional_promo_description_color,
    },
    messages,
  } = useBoxContext<IBoxCartStepOneProps | IBoxCartStepTwoExtendedProps>();
  const [editingCoupon, setEditingCoupon] = useState("");
  const [isCouponInvalid, setIsCouponInvalid] = useState();
  const { data, isLoading } = useGetCartByPortalUserTokenQuery();
  const [postCartCoupon] = usePostCartCouponMutation();
  const [deleteCartCoupon] = useDeleteCartCouponMutation();
  const theme = useTheme() as IThemeState;
  const { couponCode: cartApiCoupon, coupons } = data?.cartViewResponse ?? {};

  const {
    id: selectedCouponId,
    code: selectedCouponCode,
    cartTileTitle: couponDescription,
  } = coupons?.find(({ selected }) => selected) ?? {};

  const handlePostCartCoupon = async () => {
    try {
      await postCartCoupon({
        promoCode: editingCoupon,
      }).unwrap();
    } catch (err: any) {
      console.warn("Error posting cart coupon", err);
      setIsCouponInvalid(err.data?.code);
    }
  };

  const handleDeleteCartCoupon = async () => {
    if (!selectedCouponId) return;
    await deleteCartCoupon({ couponId: selectedCouponId.toString() });
    setEditingCoupon("");
  };

  if (isLoading) return null;

  return (
    <StyledPromoCode className="promo-code">
      <StyledInput
        $settings={promo_code_input_style}
        placeholder={messages.promo_code_input_placeholder}
        className={classNames("promo-code__input", {
          "promo-code__input--invalid": isCouponInvalid,
        })}
        value={selectedCouponCode ?? editingCoupon}
        onChange={(e) => {
          setEditingCoupon(e.target.value);
          setIsCouponInvalid(undefined);
        }}
        disabled={!!cartApiCoupon}
        formNoValidate={!isCouponInvalid}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handlePostCartCoupon();
          }
        }}
      />
      <StyledButton
        renderAs="button"
        $settings={promo_code_button_style}
        onClick={
          selectedCouponCode ? handleDeleteCartCoupon : handlePostCartCoupon
        }
        className={classNames("promo-code__button", {
          "promo-code__button--invalid": isCouponInvalid,
        })}
        disabled={!editingCoupon && !selectedCouponCode}
        {...(isCouponInvalid !== undefined && {
          style: {
            borderColor: convertColorIdToHex(
              promo_code_error_message_color,
              theme.colorPalette
            ),
          },
        })}
      >
        {cartApiCoupon ? <TrashIcon /> : messages.promo_code_button_text}
      </StyledButton>
      {isCouponInvalid && !couponDescription && (
        <StyledText
          $settings={{
            font: promo_code_error_message_style,
            text: { color: promo_code_error_message_color },
          }}
          className="promo-code__error-message"
        >
          {messages[alertMessagesMap[isCouponInvalid]] ??
            messages.promo_code_error_message}
        </StyledText>
      )}
      {couponDescription && (
        <StyledText
          className="promo-code__additional-promo-description"
          $settings={{
            font: promo_code_additional_promo_description_style,
            text: { color: promo_code_additional_promo_description_color },
          }}
        >
          {couponDescription}
        </StyledText>
      )}
    </StyledPromoCode>
  );
};
export default PromoCode;
