import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledSelectStoreFromMapPopup } from "../../../boxes/BoxMyAccountMyAdressList/elements/SelectStoreFromMapPopup";
import Head from "next/head";
import Script from "next/script";

interface NewAddressFormProps {
  disableForm: () => void;
  onPointSelect: (point: any) => void;
}

declare global {
  interface Window {
    onPoint: any;
  }
}

const StyledInpostAddressMap = styled.div`
  height: 100%;
`;

const InpostAddressMap: React.FC<NewAddressFormProps> = ({
  disableForm,
  onPointSelect,
}) => {
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;
  const token = globalSettings.inpost_geowidget_token.trim();

  useEffect(() => {
    window.onPoint = onPointSelect;
  }, [onPointSelect, disableForm]);

  return (
    <StyledSelectStoreFromMapPopup
      style={{ height: "90vh" }}
      closePopup={disableForm}
    >
      <Head>
        <link
          rel="stylesheet"
          href="https://geowidget.inpost.pl/inpost-geowidget.css"
        />
      </Head>
      <Script
        nonce={process.env["NEXT_PUBLIC_CSP_NONCE"]}
        id="inpost"
        src="https://geowidget.inpost.pl/inpost-geowidget.js"
      />

      <StyledInpostAddressMap className="inpost-geowidget">
        <inpost-geowidget
          onpoint="onPoint"
          token={token}
          language="pl"
          config="parcelcollect"
        />
      </StyledInpostAddressMap>
    </StyledSelectStoreFromMapPopup>
  );
};

export default InpostAddressMap;
