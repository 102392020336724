import { z } from "zod";

export const createImageContentScheme = (imageUrl = "") =>
  z
    .object({
      imageUrl: z.string().default(imageUrl).catch(imageUrl),
    })
    .default({ imageUrl: imageUrl });

export const ButtonContentScheme = z
  .object({
    link: z.string().default("").catch(""),
  })
  .default({ link: "" });
