import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxNotificationsMessagesScheme = z.object({
  notifications_header_text: themeScheme.MessageScheme.default("Powiadomienia"),
  notifications_type_platform:
    themeScheme.MessageScheme.default("W Platformie"),
  notifications_type_email: themeScheme.MessageScheme.default("E-Mail"),
  notifications_type_text_message: themeScheme.MessageScheme.default("SMS"),
  notifications_button_text: themeScheme.MessageScheme.default("Zapisz zmiany"),
});

export const IBoxNotificationsSettingsScheme = z.object({
  notifications_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  notifications_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  notifications_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  notifications_type_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  notifications_type_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  notifications_button_typo: themeScheme.ButtonIdScheme.default("button$$1"),
  popup_redirect_confirmation: themeScheme.RowPopupScheme.default(""),
});
