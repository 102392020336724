import { z } from "zod";
import {
  AlignmentHorizontalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  SettingPositionOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { MainButtonContentSettingScheme } from "../../settingsSchemes/settingsSchemes.utils";

const defaultCustom = {
  horizontalPadding: 35,
  verticalPadding: 8,
  background: {
    color: { id: "color$$2", custom: `#000000` },
    hoverColor: { id: "color$$2", custom: `#000000` },
    activeColor: { id: "color$$3", custom: `#000000` },
    disabledColor: { id: "color$$4", custom: `#000000` },
  },
  border: {
    radius: 4,
    width: 0,
    radiusV2: null,
    widthV2: null,
    color: { id: "color$$5", custom: `#000000` },
    hoverColor: { id: "color$$6", custom: `#000000` },
    activeColor: { id: "color$$7", custom: `#000000` },
    disabledColor: { id: "color$$8", custom: `#000000` },
  },
  text: {
    color: { id: "color$$1", custom: `#000000` },
    hoverColor: { id: "color$$10", custom: `#000000` },
    activeColor: { id: "color$$11", custom: `#000000` },
    disabledColor: { id: "color$$12", custom: `#000000` },
  },
  font: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  icon: {
    iconUrl: null,
    position: SettingPositionOptions.RIGHT,
    size: 16,
    spacing: 10,
  },
};

export const BoxButtonContentSettingsScheme = z
  .object({
    buttonAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.CENTER)
      .catch(AlignmentHorizontalOption.CENTER),
    buttonStyle: z
      .object({
        id: MainButtonContentSettingScheme.shape.id,
        custom: MainButtonContentSettingScheme.shape.custom,
      })
      .default({
        id: "button$$1",
        custom: defaultCustom,
      }),
  })
  .default({
    buttonAlignment: AlignmentHorizontalOption.CENTER,
    buttonStyle: MainButtonContentSettingScheme.parse({}),
  });

export const BoxButtonContentScheme = z
  .object({
    title: z.string().default("Button").catch("Button"),
    buttonLink: LinkContentScheme.default({
      showLinkOptions: false,
      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
      goToRowSetting: { rowId: null },
      goToPageSetting: { link: "", openInNewTab: false },
    }).catch({
      showLinkOptions: false,
      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
      goToRowSetting: { rowId: null },
      goToPageSetting: { link: "", openInNewTab: false },
    }),
  })
  .default({
    title: "Button",
    buttonLink: {
      showLinkOptions: false,
      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
      goToRowSetting: { rowId: null },
      goToPageSetting: { link: "", openInNewTab: false },
    },
  })
  .catch({
    title: "Button",
    buttonLink: {
      showLinkOptions: false,
      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
      goToRowSetting: { rowId: null },
      goToPageSetting: { link: "", openInNewTab: false },
    },
  });
