import { LanguageType } from "@ecp-redux/dto/themeSettings/translation.types";
import { API, api } from "./api";

interface TranslationResponse {
  storeLanguageDTOs: { languageLabel: LanguageType; languageName: string }[];
}
interface TranslationTransformedResponse {
  dictionaryLanguageLabel: { language: LanguageType; name: string };
  additionalLanguageLabels: { language: LanguageType; name: string }[];
}

export const translation = api.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<TranslationTransformedResponse, void>({
      query: () => {
        return { url: API.getLanguages.endpoint() };
      },
      transformResponse: (response: TranslationResponse) => ({
        dictionaryLanguageLabel: {
          language: response.storeLanguageDTOs[0].languageLabel,
          name: response.storeLanguageDTOs[0].languageName,
        },
        additionalLanguageLabels: response.storeLanguageDTOs.map((e) => ({
          language: e.languageLabel,
          name: e.languageName,
        })),
      }),
    }),
  }),
});
export const { useGetLanguagesQuery } = translation;
