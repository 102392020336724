import {
  ChannelType,
  ICartResponse,
  ICouponResponse,
  IDeliveryAndPaymentResponse,
  IMiniCartCountResponse,
  IMiniCartResponse,
  IOrderConfirmationResponse,
  IPaymentStatusResponse,
  IPlaceOrderResponse,
  IProductsCountRequest,
  IProductsCountResponse,
  PaymentType,
} from "../dto/cart.types";
import { IAddressesAdd, TAddAddressRequest } from "../dto/cartAddresses.type";
import { AddToCartB2B2CRequest } from "../dto/cartB2B.types";

import { API, api } from "./api";

const optimisticUpdateForProductCountInCart =
  (arg: IProductsCountRequest) => (draft: ICartResponse) => {
    const index = draft?.cartViewResponse?.orderLines.findIndex(
      (e) => e.productSku === arg.productSku
    );

    if (index >= 0) {
      draft.cartViewResponse.orderLines[index].quantity =
        draft.cartViewResponse.orderLines[index].quantity + arg.quantity;
    }
  };

const optimisticUpdateForProductCountInMiniCart =
  (arg: IProductsCountRequest) => (draft: IMiniCartResponse) => {
    const index = draft?.content?.orderLines.findIndex(
      (e) => e.productSku === arg.productSku
    );

    if (index >= 0) {
      draft.content.orderLines[index].quantity =
        draft.content.orderLines[index].quantity + arg.quantity;
    }
  };

export const cart = api
  .enhanceEndpoints({ addTagTypes: ["PLACE_ORDER"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCartByPortalUserToken: builder.query<ICartResponse, void>({
        query: () => {
          return API.getCartByPortalUserToken.endpoint();
        },
        providesTags: [
          "CART",
          "PORTAL_USER_AUTH",
          "LANGUAGE",
          "PLACE_ORDER",
          "CURRENCY_DROPDOWN",
        ],
      }),
      getOrderStatus: builder.query<IPaymentStatusResponse, string>({
        query: (orderId) => {
          return API.getOrderStatus.endpoint(orderId);
        },
      }),
      getMiniCartByPortalUserToken: builder.query<IMiniCartResponse, void>({
        query: () => {
          return API.getMiniCartByPortalUserToken.endpoint();
        },
        providesTags: [
          "CART",
          "PORTAL_USER_AUTH",
          "LANGUAGE",
          "PLACE_ORDER",
          "CURRENCY_DROPDOWN",
        ],
      }),
      getMiniCartCountByPortalUserToken: builder.query<
        IMiniCartCountResponse,
        void
      >({
        query: () => {
          return API.getMiniCartCountByPortalUserToken.endpoint();
        },
        providesTags: [
          "CART",
          "PORTAL_USER_AUTH",
          "PLACE_ORDER",
          "CURRENCY_DROPDOWN",
        ],
      }),

      putChangeProductCount: builder.mutation<
        // endpoint only use in cart B2C
        IProductsCountResponse,
        IProductsCountRequest
      >({
        query: (body) => {
          return {
            url: API.putChangeProductQuantityByPortalUserToken.endpoint(),
            method: "PUT",
            body,
          };
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            cart.util.updateQueryData(
              "getCartByPortalUserToken",
              undefined,
              optimisticUpdateForProductCountInCart(arg)
            )
          );

          const patchMiniCartResult = dispatch(
            cart.util.updateQueryData(
              "getMiniCartByPortalUserToken",
              undefined,
              optimisticUpdateForProductCountInMiniCart(arg)
            )
          );
          await queryFulfilled.catch(() => patchResult.undo());
          await queryFulfilled.catch(() => patchMiniCartResult.undo());
        },
        invalidatesTags: (_result, error) => (error ? [] : ["CART"]),
      }),
      postAddProductQuantity: builder.mutation<
        ICartResponse,
        AddToCartB2B2CRequest
      >({
        query: ({ sku, quantity, unitOfMeasure, isCart }) => {
          return {
            url: API.postAddProductQuantityByPortalUserToken.endpoint(),
            method: "POST",
            body: { productSku: sku, quantity, unitOfMeasure, isCart },
          };
        },

        invalidatesTags: ["CART"],
      }),

      getDeliveryAndPaymentByPortalUserToken: builder.query<
        IDeliveryAndPaymentResponse,
        void
      >({
        query: () => {
          return API.getDeliveryAndPaymentByPortalUserToken.endpoint();
        },

        providesTags: [
          "CART",
          "PORTAL_USER_AUTH",
          "LANGUAGE",
          "CURRENCY_DROPDOWN",
        ],
      }),
      getDeliveryAndPaymentByPortalUserTokenExtended: builder.query<
        IDeliveryAndPaymentResponse,
        void
      >({
        query: () => {
          return API.getDeliveryAndPaymentByPortalUserTokenExtended.endpoint();
        },

        providesTags: [
          "CART",
          "PORTAL_USER_AUTH",
          "LANGUAGE",
          "CURRENCY_DROPDOWN",
        ],
      }),

      postChangeDeliveryChannel: builder.mutation<
        unknown,
        { deliveryChannelType: ChannelType }
      >({
        query: (body) => {
          return {
            url: API.postChangeDeliveryType.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),
      postChangeDeliveryChannelExtended: builder.mutation<
        unknown,
        { deliveryChannelType: ChannelType }
      >({
        query: (body) => {
          return {
            url: API.postChangeDeliveryTypeExtended.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      postChangePaymentMethod: builder.mutation<
        unknown,
        { paymentType: PaymentType }
      >({
        query: (body) => {
          return {
            url: API.postChangePaymentType.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),
      postChangePaymentMethodExtended: builder.mutation<
        unknown,
        { paymentType: PaymentType }
      >({
        query: (body) => {
          return {
            url: API.postChangePaymentTypeExtended.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      postChangeCartSelectedAddress: builder.mutation<unknown, IAddressesAdd>({
        query: (body) => {
          return {
            url: API.postChangeCartSelectedAddress.endpoint(
              body.addressId.toString()
            ),
            method: "POST",
          };
        },
        invalidatesTags: ["CART"],
      }),
      postChangeCartSelectedAddressExtended: builder.mutation<
        unknown,
        IAddressesAdd
      >({
        query: (body) => {
          return {
            url: API.postChangeCartSelectedAddressExtended.endpoint(
              body.addressId.toString()
            ),
            method: "POST",
          };
        },
        invalidatesTags: ["CART"],
      }),

      postCartAddressByPortalUserToken: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest
      >({
        query: (body) => {
          return {
            url: API.postCartAddressByPortalUserToken.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),
      postCartAddressByPortalUserTokenExtended: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest
      >({
        query: (body) => {
          return {
            url: API.postCartAddressByPortalUserTokenExtended.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      deleteCartAddressByPortalUserToken: builder.mutation<
        IAddressesAdd,
        IAddressesAdd["addressId"]
      >({
        query: (body) => {
          return {
            url: API.deleteCartAddressByPortalUserToken.endpoint(body),
            method: "DELETE",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      putCartAddressByPortalUserToken: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest & IAddressesAdd
      >({
        queryFn: async ({ addressId, ...body }, { getState }, _, baseQuery) => {
          const isUnregistered =
            (getState() as any).api.queries[
              "getDeliveryAndPaymentByPortalUserToken(undefined)"
            ].data.response.cartUserStatus === "UNREGISTERED";

          return await baseQuery({
            url: isUnregistered
              ? API.postCartAddressByPortalUserToken.endpoint()
              : API.putCartAddressByPortalUserToken.endpoint(addressId),
            method: isUnregistered ? "POST" : "PUT",
            body,
          });
        },
        invalidatesTags: ["CART"],
      }),
      putCartAddressByPortalUserTokenExtended: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest & IAddressesAdd
      >({
        queryFn: async ({ addressId, ...body }, { getState }, _, baseQuery) => {
          const isUnregistered =
            (getState() as any).api.queries[
              "getDeliveryAndPaymentByPortalUserToken(undefined)"
            ].data.response.cartUserStatus === "UNREGISTERED";

          return await baseQuery({
            url: isUnregistered
              ? API.postCartAddressByPortalUserTokenExtended.endpoint()
              : API.putCartAddressByPortalUserTokenExtended.endpoint(addressId),
            method: isUnregistered ? "POST" : "PUT",
            body,
          });
        },
        invalidatesTags: ["CART"],
      }),

      postCartShoppingWithoutRegister: builder.mutation<
        void,
        { email: string }
      >({
        query: (body) => {
          return {
            url: API.postCartShoppingWithoutRegister.endpoint(),
            method: "POST",
            body,
          };
        },
      }),
      postCartPlaceOrder: builder.mutation<
        IPlaceOrderResponse,
        { nonceToken: string } | void
      >({
        query: (body = { nonceToken: "" }) => {
          return {
            url: API.postCartPlaceOrder.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["PLACE_ORDER"],
      }),

      postAssignCart: builder.mutation<void, void>({
        query: (body) => {
          return {
            url: API.postAssignCart.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      getCartOrderConfirmation: builder.query<
        IOrderConfirmationResponse,
        string
      >({
        query: (orderId) => {
          return API.getCartOrderConfirmation.endpoint(orderId);
        },
        providesTags: ["LANGUAGE", "CURRENCY_DROPDOWN"],
      }),

      postCartCoupon: builder.mutation<ICouponResponse, { promoCode: string }>({
        query: ({ promoCode }) => {
          return {
            url: API.postCartCoupon.endpoint(),
            method: "POST",
            body: { promoCode },
          };
        },
        invalidatesTags: (_result, error) => {
          if (error) return [];
          return ["CART"];
        },
      }),

      deleteCartCoupon: builder.mutation<ICouponResponse, { couponId: string }>(
        {
          query: ({ couponId }) => {
            return {
              url: API.deleteCartCoupon.endpoint(couponId),
              method: "DELETE",
            };
          },
          invalidatesTags: ["CART"],
        }
      ),
    }),
    overrideExisting: true,
  });

export const {
  useGetCartByPortalUserTokenQuery,
  useGetMiniCartByPortalUserTokenQuery,
  useGetMiniCartCountByPortalUserTokenQuery,
  useGetDeliveryAndPaymentByPortalUserTokenQuery,
  useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery,
  usePutChangeProductCountMutation,
  usePostAddProductQuantityMutation,
  usePostChangeDeliveryChannelMutation,
  usePostChangeDeliveryChannelExtendedMutation,
  usePostChangePaymentMethodMutation,
  usePostChangePaymentMethodExtendedMutation,
  usePostChangeCartSelectedAddressMutation,
  usePostChangeCartSelectedAddressExtendedMutation,
  usePostCartAddressByPortalUserTokenMutation,
  usePostCartAddressByPortalUserTokenExtendedMutation,
  usePutCartAddressByPortalUserTokenMutation,
  usePutCartAddressByPortalUserTokenExtendedMutation,
  usePostCartShoppingWithoutRegisterMutation,
  usePostCartPlaceOrderMutation,
  useGetCartOrderConfirmationQuery,
  useGetOrderStatusQuery,
  usePostAssignCartMutation,
  useDeleteCartAddressByPortalUserTokenMutation,
  usePostCartCouponMutation,
  useDeleteCartCouponMutation,
} = cart;

export const {
  getCartByPortalUserToken,
  getDeliveryAndPaymentByPortalUserToken,
  getDeliveryAndPaymentByPortalUserTokenExtended,
} = cart.endpoints;
