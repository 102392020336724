import { z } from "zod";
import { HoverEffects } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentScheme,
  Border_v3Scheme,
  ColorReadoutValueScheme,
  LinkReadoutValueScheme,
  RadiusScheme,
  SpacingScheme,
  TypoReadoutValueScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  OutlineOptions,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { TilesSliderRotateOptions } from "./BoxTilesSlider.types";
import { SliderPaginationOptions } from "../BoxSlider/BoxSlider.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const BoxTilesSliderContentScheme = z
  .object({
    header: z.string().default("Tiles title"),
    tiles: z
      .array(
        z.object({
          id: z.string().default("tile1"),
          image: z
            .object({
              imageUrl: z.string().default(""),
            })
            .default({
              imageUrl: "",
            }),
          linkStyle: LinkReadoutValueScheme,
          title: z.string().default("Tile"),
          slideUrl: LinkContentScheme.default({
            showLinkOptions: true,
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            goToRowSetting: undefined,
            goToPageSetting: { link: "", openInNewTab: false },
            forceOrderInsideStack: undefined,
            openPopupSetting: undefined,
            closePopupSetting: undefined,
          }),
          seoDescription: z.string().default(""),
        })
      )
      .default([
        {
          id: "tile1",
          image: {
            imageUrl: "",
          },
          linkStyle: { id: "link$$1", custom: {} },
          title: "Tile",
          slideUrl: {
            showLinkOptions: true,
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            goToRowSetting: undefined,
            goToPageSetting: { link: "", openInNewTab: false },
            forceOrderInsideStack: undefined,
            openPopupSetting: undefined,
            closePopupSetting: undefined,
          },
          seoDescription: "",
        },
      ]),
  })
  .default({
    header: "Tiles title",
    tiles: [
      {
        id: "tile1",
        image: {
          imageUrl: "",
        },
        linkStyle: { id: "link$$1", custom: {} },
        title: "Tile",
        slideUrl: {
          showLinkOptions: true,
          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          goToRowSetting: undefined,
          goToPageSetting: { link: "", openInNewTab: false },
          forceOrderInsideStack: undefined,
          openPopupSetting: undefined,
          closePopupSetting: undefined,
        },
        seoDescription: "",
      },
    ],
  });

export const BoxTilesSliderContentSettingsScheme = z
  .object({
    headerTextStyle: TypoReadoutValueScheme,
    headerTextColor: ColorReadoutValueScheme,
    headerAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.LEFT),
    spaceBetweenTitleAndTiles: z.number().default(10),
    tileHeight: z.number().default(600),
    numberOfTilesPerSlide: z.number().default(4),
    spaceBetweenTiles: z.number().default(5),
    tileBorder: Border_v3Scheme.default({
      width: { top: 0, right: 0, bottom: 0, left: 0 },
      color: { id: "color$$1", custom: "#FFFFFF" },
      style: OutlineOptions.NONE,
      radius: { top: 0, right: 0, bottom: 0, left: 0 },
    }),
    tileHoverBorder: Border_v3Scheme.default({
      width: { top: 0, right: 0, bottom: 0, left: 0 },
      color: { id: "color$$1", custom: "#FFFFFF" },
      style: OutlineOptions.NONE,
      radius: RadiusScheme.default({
        topLeft: 0,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 0,
      }),
    }),
    tilesBackground: ColorReadoutValueScheme,
    tilesBackgroundHover: ColorReadoutValueScheme,
    tileSettings: z
      .array(
        z.object({
          id: z.string().default("tile1"),
          fill: z.boolean().default(true),
          image: z
            .object({
              opacity: z.number().default(100),
              alignment: AlignmentScheme.default({
                horizontal: AlignmentHorizontalOption.CENTER,
                vertical: AlignmentVerticalOption.CENTER,
              }),
              hoverEffect: z
                .nativeEnum(HoverEffects)
                .default(HoverEffects.NONE),
            })
            .default({
              opacity: 100,
              alignment: {
                horizontal: AlignmentHorizontalOption.CENTER,
                vertical: AlignmentVerticalOption.CENTER,
              },
              hoverEffect: HoverEffects.NONE,
            }),
          titleStyle: TypoReadoutValueScheme,
          titleColor: ColorReadoutValueScheme,
          titleAlignment: z
            .nativeEnum(AlignmentHorizontalOption)
            .default(AlignmentHorizontalOption.CENTER),
          titlePlacement: z
            .nativeEnum(VerticalPosition)
            .default(VerticalPosition.TOP),
          spaceBetweenTitleAndImage: z.number().default(5),
          show: z
            .object({
              image: z.boolean().default(true),
              title: z.boolean().default(true),
              link: z.boolean().default(true),
            })
            .default({ image: true, title: true, link: true }),
        })
      )
      .default([
        {
          id: "tile1",
          fill: true,
          image: {
            opacity: 100,
            alignment: {
              horizontal: AlignmentHorizontalOption.CENTER,
              vertical: AlignmentVerticalOption.CENTER,
            },
            hoverEffect: HoverEffects.NONE,
          },
          titleStyle: { id: "typo$$1", custom: {} },
          titleColor: { id: "color$$1", custom: "#FFFFFF" },
          titleAlignment: AlignmentHorizontalOption.LEFT,
          titlePlacement: VerticalPosition.TOP,
          spaceBetweenTitleAndImage: 10,
          show: { image: true, title: true, link: true },
        },
      ]),
  })
  .default({
    headerTextStyle: { id: "typo$$1", custom: {} },
    headerTextColor: { id: "color$$1", custom: "#FFFFFF" },
    headerAlignment: AlignmentHorizontalOption.LEFT,
    spaceBetweenTitleAndTiles: 10,
    tileHeight: 600,
    numberOfTilesPerSlide: 4,
    spaceBetweenTiles: 5,
    tileBorder: {
      width: { top: 0, right: 0, bottom: 0, left: 0 },
      color: { id: "color$$1", custom: "#FFFFFF" },
      style: OutlineOptions.NONE,
      radius: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    tileHoverBorder: {
      width: { top: 0, right: 0, bottom: 0, left: 0 },
      color: { id: "color$$1", custom: "#FFFFFF" },
      style: OutlineOptions.NONE,
      radius: { top: 0, right: 0, bottom: 0, left: 0 },
    },
    tilesBackground: { id: "color$$1", custom: "#FFFFFF" },
    tilesBackgroundHover: { id: "color$$1", custom: "#FFFFFF" },
    tileSettings: [
      {
        id: "tile1",
        fill: true,
        image: {
          opacity: 100,
          alignment: {
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          },
          hoverEffect: HoverEffects.NONE,
        },
        titleStyle: { id: "typo$$1", custom: {} },
        titleColor: { id: "color$$1", custom: "#FFFFFF" },
        titleAlignment: AlignmentHorizontalOption.LEFT,
        titlePlacement: VerticalPosition.TOP,
        spaceBetweenTitleAndImage: 10,
        show: { image: true, title: true, link: true },
      },
    ],
  });

const OmittedSliderPaginationOptionsSchema = z.enum([
  SliderPaginationOptions.DOTS,
  SliderPaginationOptions.ARROWS,
  SliderPaginationOptions.NONE,
]);

export type OmittedSliderPaginationOptions = z.infer<
  typeof OmittedSliderPaginationOptionsSchema
>;

export const BoxTilesSliderDisplaySettingsScheme = z
  .object({
    paginationStyle: OmittedSliderPaginationOptionsSchema.default(
      SliderPaginationOptions.DOTS
    ),
    autoRotate: z.boolean().default(false),
    timeBetweenSlides: z.number().default(5),
    slideRotateType: z
      .nativeEnum(TilesSliderRotateOptions)
      .default(TilesSliderRotateOptions.SINGLE_SLIDE),
    padding: SpacingScheme.default({ top: 0, right: 0, bottom: 0, left: 0 }),
  })
  .default({
    paginationStyle: SliderPaginationOptions.DOTS,
    autoRotate: false,
    timeBetweenSlides: 5,
    slideRotateType: TilesSliderRotateOptions.SINGLE_SLIDE,
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
  });

export const BoxTilesSliderSettingsScheme = z.object({
  enable_page_slider: themeScheme.SettingBooleanScheme.default("false"),
  slide_0: themeScheme.UrlScheme.default(""),
  slide_1: themeScheme.UrlScheme.default(""),
  slide_2: themeScheme.UrlScheme.default(""),
  slide_3: themeScheme.UrlScheme.default(""),
  slide_4: themeScheme.UrlScheme.default(""),
  slide_5: themeScheme.UrlScheme.default(""),
  slide_6: themeScheme.UrlScheme.default(""),
  slide_7: themeScheme.UrlScheme.default(""),
  slide_8: themeScheme.UrlScheme.default(""),
  slide_9: themeScheme.UrlScheme.default(""),
});

export const BoxTilesSliderMessagesScheme = z.object({});

export type BoxTilesSliderDisplaySettings = z.infer<
  typeof BoxTilesSliderDisplaySettingsScheme
>;
