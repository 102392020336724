import styled, { css } from "styled-components";

export const StyledBox = styled.div(
  () => css`
    min-width: 100%;
    overflow: clip;
    :has(#mini-cart-detailed) {
      overflow: visible;
    }
  `
);
