import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import withHydrationOnDemand from "react-hydration-on-demand";
import * as cartAPI from "@ecp-redux/api/cart";
import * as myData from "@ecp-redux/api/myData";
import { CART_ERROR_STATUS, PaymentType } from "@ecp-redux/dto/cart.types";
import { isPortalSide } from "../../../helpers/helpers";
import {
  useAddAlert,
  useAlertByCodes,
} from "../../../redux/slices/alertsSlice";
import { StyledBoxCartStepTwoExtended } from "./BoxCartStepTwoExtended.styled";
import BillingSection from "./potentialBoxes/BillingSection/BillingSection";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { eventTransaction } from "../../analytics/events";
import useSetSessionStorage from "../../../shared/hooks/useSetSessionStorage";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useTheme } from "styled-components";
import { ISiteType } from "../../analytics/schema";
import { IBoxCartStepTwoExtendedProps } from "./BoxCartStepTwoExtended.types";
import {
  BoxCartStepTwoExtendedContextProvider,
  IBoxCartStepTwoExtendedContextType,
} from "./context/BoxCartStepTwoExtended.context";
import AddressSection from "./components/sections/AddressSection";
import DeliverySection from "./components/sections/DeliverySection";
import PaymentSection, {
  TPaymentComponentRef,
} from "./components/sections/PaymentSection";
import ContactSection from "./components/ContactSection";
import SummaryExtended from "./components/SummaryExtended/SummaryExtended";
import PlaceOrderButton from "./components/PlaceOrderButton/PlaceOrderButton";
import CartLoader from "./components/shared/CartLoader";
import { IBillingPostalAddAddressExtendedRequest } from "@ecp-redux/dto/cartAddresses.type";
import useIsMobilePortal from "@ecp-boxes/shared/hooks/useIsMobilePortal";

const alertMessagesMap = {
  "1015": "basket_missing_billing_address_alert",
  "1016": "basket_missing_payment_method_alert",
  "4004": "basket_missing_delivery_address_alert",
  "1010": "basket_no_products_has_been_added_alert",
  "6000": "basket_packstation_not_available_alert",
  "4006": "order_amount_not_cover_amount_limits",
} as const;

const BoxCartStepTwoExtended: React.FC<IBoxCartStepTwoExtendedProps> = ({
  settings,
  messages,
}) => {
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<IBillingPostalAddAddressExtendedRequest | null>(null);
  const [selectedBillingAddress, setSelectedBillingAddress] =
    useState<IBillingPostalAddAddressExtendedRequest | null>(null);
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(true);
  const isMobile = useIsMobilePortal();
  const initialAddressAssignment = useRef(true);
  const { data, isError, isFetching } =
    cartAPI.useGetDeliveryAndPaymentByPortalUserTokenExtendedQuery();
  const [addAddress] =
    cartAPI.usePostCartAddressByPortalUserTokenExtendedMutation();
  const { deliveryChannels, payments, cartSummary, recipientAddress } =
    data?.response ?? {};
  const selectedDeliveryChannel = deliveryChannels?.find(
    ({ selected }) => selected
  );

  const paymentAutofillAddress = recipientAddress ?? selectedAddress;

  useEffect(() => {
    if (data?.response?.recipientAddress && initialAddressAssignment.current) {
      setSelectedAddress({
        ...data.response.recipientAddress,
        type: "RECIPIENT",
      });
      initialAddressAssignment.current = false;
    }
    if (isSameBillingAddress && selectedAddress) {
      setSelectedBillingAddress({ ...selectedAddress, type: "BILLING" });
    }
  }, [data?.response?.recipientAddress, isSameBillingAddress, selectedAddress]);

  const selectedPaymentMethod = payments?.find(
    ({ selected }) => selected
  )?.paymentType;
  const { amountToPay } = cartSummary ?? {};

  const [order, { error: orderError }] =
    cartAPI.usePostCartPlaceOrderMutation();
  const router = useRouter();
  const { addAlert } = useAddAlert([orderError]);
  const { closeAlert } = useAlertByCodes([
    "DELIVERY_CHANNELS_NOT_AVAILABLE",
    "1010",
  ]);
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { getSessionStorageItem } = useSetSessionStorage();

  const customer_id =
    parseInt(getSessionStorageItem("customer_id") as string) || null;
  const email = getSessionStorageItem("email");
  const siteType = getSessionStorageItem("site_type") as ISiteType;
  const { data: customerData } =
    myData.useGetCustomerDataWithNoValidationQuery();

  const paymentRefs = {
    CREDIT_CARD: useRef<TPaymentComponentRef>(null),
    GOOGLE_PAY: useRef<TPaymentComponentRef>(null),
    APPLE_PAY: useRef<TPaymentComponentRef>(null),
    PAYPAL: useRef<TPaymentComponentRef>(null),
  };

  const paymentComponents: Partial<
    Record<PaymentType, TPaymentComponentRef | null>
  > = {
    CREDIT_CARD: paymentRefs.CREDIT_CARD.current,
    GOOGLE_PAY: paymentRefs.GOOGLE_PAY.current,
    APPLE_PAY: paymentRefs.APPLE_PAY.current,
    PAYPAL: paymentRefs.PAYPAL.current,
  };

  useEffect(() => {
    if (
      data &&
      data.errors.some(
        (err) =>
          err.status === CART_ERROR_STATUS.DELIVERY_CHANNELS_NOT_AVAILABLE
      )
    ) {
      closeAlert && closeAlert();
      addAlert({
        code: "DELIVERY_CHANNELS_NOT_AVAILABLE",
        message: messages.basket_unavailable_delivery_channels_alert,
      });
    }
  }, [data?.errors]);

  useEffect(() => {
    if (
      router &&
      isPortalSide() &&
      (data?.response.orderLines.length === 0 || isError)
    ) {
      router.replace(`${settings.basket_step_2_url_step_0}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, isError, data?.response.orderLines]);

  if (data == null) {
    return null;
  }

  const isSelectedDeliveryChannelAndPaymentMethod =
    data.response.deliveryChannels?.find((e) => e.selected) !== undefined &&
    data.response.payments?.find((e) => e.selected) !== undefined;

  const isPaypalPaymentSelected = selectedPaymentMethod === PaymentType.PAYPAL;

  const contextData: IBoxCartStepTwoExtendedContextType = {
    selectedDeliveryChannel,
    customerData,
    isLogged: !!customerData?.customerId,
    amountToPay,
    setIsSameBillingAddress,
    isSameBillingAddress,
    selectedAddress,
    setSelectedAddress,
    selectedBillingAddress,
    setSelectedBillingAddress,
  };

  const handlePurchase = async (paypalNonce?: string) => {
    try {
      setIsPaymentProcessing(true);
      const braintreeResponse =
        await paymentComponents[
          selectedPaymentMethod as PaymentType
        ]?.generateNonce();

      if (braintreeResponse?.hasError && !paypalNonce) return;

      // Sends the addresses requests before placing an order for anonymous user or if checkbox "same billing address" is checked
      await Promise.all(
        [
          selectedAddress &&
            !data?.response?.recipientAddress &&
            addAddress(selectedAddress).unwrap(),
          selectedBillingAddress &&
            (!data?.response?.billingAddress || isSameBillingAddress) &&
            addAddress(selectedBillingAddress).unwrap(),
        ].filter(Boolean)
      );

      const { response } = await order({
        nonceToken: braintreeResponse?.nonce ?? paypalNonce ?? "",
      }).unwrap();

      eventTransaction({
        products: data.response?.orderLines.map((orderLine) => {
          return {
            currency: globalMessages.currencyShort,
            quantity: orderLine.quantity,
            product_id: orderLine.productSku,
            sales_price: orderLine.salePrice,
            net_sales_price: orderLine.nettoPrice,
            base_price: orderLine.price,
            suggested_retail_price: orderLine.suggestedRetailPrice,
          };
        }),
        transaction_id: response?.orderId,
        currency: globalMessages.currencyShort,
        customer_id: customer_id,
        site_type: siteType,
        email: email,
      });

      if (response?.redirectUrl) {
        router.replace(`${response.redirectUrl}`);
      } else {
        router.replace(
          `${settings.basket_step_2_summary_order_url}?orderId=${response?.orderId}`
        );
      }
    } catch (e) {
      closeAlert && closeAlert();
      const code = String((e as any).data?.code) as
        | "1015"
        | "1016"
        | "4004"
        | "1010"
        | "6000"
        | "4006";
      addAlert({
        code,
        message: messages[alertMessagesMap[code]],
      });
    } finally {
      setIsPaymentProcessing(false);
    }
  };

  return (
    <BoxCartStepTwoExtendedContextProvider data={contextData}>
      <StyledBoxCartStepTwoExtended
        className="cart-step-two"
        $backgroundColor={settings.basket_step_all_background_color}
        $summaryBackgroundColor={
          settings.basket_step_all_summary_background_color
        }
        $isLoading={isFetching}
      >
        <div className="cart-step-two__content">
          <ContactSection />
          <AddressSection />
          <DeliverySection />
          <PaymentSection paymentRefs={paymentRefs} />
          <BillingSection />
          {!isMobile && (
            <PlaceOrderButton
              isPaypalPaymentSelected={isPaypalPaymentSelected}
              paymentAutofillAddress={paymentAutofillAddress}
              onClick={handlePurchase}
              isPaymentProcessing={isPaymentProcessing}
              handlePurchase={handlePurchase}
            />
          )}
        </div>
        <SummaryExtended
          cartSummary={data.response.cartSummary}
          isSelectedDeliveryChannel={isSelectedDeliveryChannelAndPaymentMethod}
          handlePurchase={handlePurchase}
          isPaymentProcessing={isPaymentProcessing}
          isPaypalPaymentSelected={isPaypalPaymentSelected}
          paymentAutofillAddress={paymentAutofillAddress}
        />
        {isFetching && <CartLoader />}
      </StyledBoxCartStepTwoExtended>
    </BoxCartStepTwoExtendedContextProvider>
  );
};

export default Memo(
  withHydrationOnDemand({ on: ["visible"] })(BoxCartStepTwoExtended)
);
