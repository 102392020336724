import React, { useRef } from "react";
import { useTheme } from "styled-components";
import { IOrderLine, ProductStatusOption } from "@ecp-redux/dto/cart.types";
import {
  DeliveryTimeCode,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
  TColorId,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useImageSize } from "../../../../../../../lib/global/components/ImageWrapper/ImageWrapper.methods";
import ImageWrapper from "../../../../../../global/components/ImageWrapper/ImageWrapper";
import {
  formatPrice,
  isAbsoluteUrl,
  isTruthy,
} from "../../../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../../../helpers/isNotEmptyMessage";
import StyledText from "../../../../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../../../../structure/Contexts/BoxContext";
import Link from "next/link";
import Image from "next/image";
import { StyledProductsCartElementExtended } from "./ProductCartElementExtended.styled";
import { IBoxCartStepTwoExtendedProps } from "../../../BoxCartStepTwoExtended.types";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

interface ProductCartElementExtendedProps {
  product: IOrderLine;
  imageUrl: TUrl;
  attributeValues: string[];
  imageHref?: TUrl;
  productType?: TSrpProductType;
  deliveryTime?: DeliveryTimeCode;
}

const ProductCartElementExtended: React.FC<ProductCartElementExtendedProps> = ({
  product,
  imageUrl,
  attributeValues,
  imageHref,
  deliveryTime,
  productType,
}) => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { currencyShort } = useLanguageCurrencyContext();

  const [imgWrapper, { width: imageWidth }] = useImageSize();
  const productCartElement = useRef<HTMLAnchorElement>(null);

  const unavailableProduct =
    product.stockLevelStatus === ProductStatusOption.NOT_AVAILABLE ||
    product.enabled === false ||
    product.deliveryTime === DeliveryTimeCode.NOT_AVAILABLE;

  const { cartPromotionTitle } = product?.cartPromotionDetails ?? {};

  const chooseTextColor = (color: TColorId): TColorId =>
    unavailableProduct ? settings.cart_color_primary : color;

  const deliveryTimeMessage =
    deliveryTime && globalMessages[`delivery_${deliveryTime}`];

  return (
    <StyledProductsCartElementExtended
      ref={imgWrapper}
      unvailableProduct={unavailableProduct}
      bubbleBgColor={settings.mini_cart_with_value_bubble_bg_color}
      testTypo={settings.mini_cart_with_value_bubble_count_typo}
      className="product-cart-element"
    >
      {imageHref ? (
        <Link
          ref={productCartElement}
          href={imageHref}
          locale={isAbsoluteUrl(imageHref) ? false : undefined}
          className="product-cart-element__image"
        >
          {productType && (
            <div className="product-cart-element__bubble-quantity-container">
              <StyledText
                className="product-cart-element__bubble"
                $settings={{
                  font: settings.mini_cart_with_value_bubble_count_typo,
                  text: {
                    color: chooseTextColor(
                      settings.mini_cart_with_value_bubble_count_color
                    ),
                  },
                }}
              >
                <p className="product-cart-element__bubble-quantity">
                  {product.quantity}
                </p>
              </StyledText>
            </div>
          )}

          <ImageWrapper
            {...{ imageUrl }}
            seoDescription={product.productName}
            imageFit={false}
            imageAlignment={{
              horizontal: AlignmentHorizontalOption.CENTER,
              vertical: AlignmentVerticalOption.CENTER,
            }}
            width={imageWidth}
          />
        </Link>
      ) : (
        <>
          {productType && (
            <div className="product-cart-element__bubble-quantity-container">
              <StyledText
                className="product-cart-element__bubble"
                $settings={{
                  font: settings.mini_cart_with_value_bubble_count_typo,
                  text: {
                    color: chooseTextColor(
                      settings.mini_cart_with_value_bubble_count_color
                    ),
                  },
                }}
              >
                <p className="product-cart-element__bubble-quantity">
                  {product.quantity}
                </p>
              </StyledText>
            </div>
          )}
          <ImageWrapper
            {...{ imageUrl }}
            seoDescription={product.productName}
            imageFit={false}
            imageAlignment={{
              horizontal: AlignmentHorizontalOption.CENTER,
              vertical: AlignmentVerticalOption.CENTER,
            }}
            width={imageWidth}
          />
        </>
      )}

      <div className="product_cart_info">
        <div className="product-cart-element__info_details">
          <StyledText
            className="product_cart_info__text"
            $settings={{
              font: settings.section_subtext_large_typography,
              text: {
                color: chooseTextColor(settings.cart_color_primary),
              },
            }}
          >
            <p className="product-cart-element__name">{product.productName}</p>
          </StyledText>

          {cartPromotionTitle && (
            <div className="product_cart_info__promo-wrapper">
              {settings.promo_code_applied_code_title_icon && (
                <Image
                  src={settings.promo_code_applied_code_title_icon}
                  alt={cartPromotionTitle}
                  width={16}
                  height={16}
                />
              )}
              <StyledText
                className="product_cart_info__text"
                $settings={{
                  font: settings.promo_code_applied_code_title_style,
                  text: {
                    color: chooseTextColor(
                      settings.promo_code_applied_code_title_color
                    ),
                  },
                }}
              >
                <p className="product-cart-element__name">
                  {cartPromotionTitle}
                </p>
              </StyledText>
            </div>
          )}

          {settings.product_subtitle_for_cart_attribute_row_1.length === 0 ? (
            <>
              <StyledText
                className="product-cart-element__sale-price"
                $settings={{
                  font: settings.summary_price_regular,
                  text: {
                    color: chooseTextColor(settings.cart_color_primary),
                  },
                }}
              >
                <p>{formatPrice(product.salePrice, currencyShort)}</p>
              </StyledText>

              {product.suggestedRetailPrice !== product.salePrice && (
                <StyledText
                  className="product-cart-element__suggested-retail-price"
                  $settings={{
                    font: settings.summary_price_large,
                    text: {
                      color: chooseTextColor(settings.cart_color_secondary),
                    },
                  }}
                >
                  <p>
                    {formatPrice(product.suggestedRetailPrice, currencyShort)}
                  </p>
                </StyledText>
              )}
            </>
          ) : (
            <div className="product-cart-element__attributes-container">
              {attributeValues.map((value) => (
                <StyledText
                  key={value + product.productSku}
                  className="product-cart-element__attribute"
                  $settings={{
                    font: settings.product_subtitle_for_cart_attribute_typography_line,
                    text: {
                      color:
                        settings.product_subtitle_for_cart_attribute_color_line,
                    },
                  }}
                >
                  <p>{value}</p>
                </StyledText>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="product_cart_quantity">
        <StyledText
          className="product_cart_quantity__total-price"
          $settings={{
            font: settings.summary_price_regular,
            text: {
              color: chooseTextColor(settings.cart_color_primary),
            },
          }}
        >
          <p className="product_cart_quantity__total-price-details">
            {formatPrice(product.quantity * product.salePrice, currencyShort)}
          </p>
        </StyledText>
        {product.suggestedRetailPrice !== product.salePrice && (
          <StyledText
            className="product_cart_quantity__suggested-retail-price"
            $settings={{
              font: settings.summary_price_large,
              text: {
                color: chooseTextColor(settings.cart_color_secondary),
              },
            }}
          >
            <p className="product_cart_quantity__retail-price-details">
              {formatPrice(
                product.quantity * product.suggestedRetailPrice,
                currencyShort
              )}
            </p>
          </StyledText>
        )}
      </div>
      <StyledText
        className="product-cart-element__delivery-time"
        $settings={{
          font: settings.section_subtext_typography,
          text: {
            color: chooseTextColor(settings.cart_color_secondary),
          },
        }}
        show={
          (isNotEmptyMessage(messages.cart_delivery_time) &&
            unavailableProduct &&
            deliveryTime &&
            deliveryTime !== DeliveryTimeCode.NOT_AVAILABLE &&
            isTruthy(settings.show_delivery_time)) ??
          false
        }
      >
        {!unavailableProduct && (
          <p className="product-cart-element__delivery-time-details">{`${messages.cart_delivery_time} ${deliveryTimeMessage}`}</p>
        )}
      </StyledText>
    </StyledProductsCartElementExtended>
  );
};

export default ProductCartElementExtended;
