import { IStoreLocator } from "@ecp-redux/dto/storesLocator.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { IBoxMyAccountMyAddressListProps } from "../../BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import { StyledStore, StyledTableView } from "../BoxStoreLocator.styled";
import { IBoxStoreLocatorProps } from "../BoxStoreLocator.types";
import Store from "./Store";

interface TableViewProps {
  imagesVisible: boolean;
  stores: IStoreLocator[];
  onSelectAddressButtonClick?: (storeCode: string) => void;
  storeCode?: string;
}

const TableView: React.FC<TableViewProps> = ({
  imagesVisible,
  stores,
  onSelectAddressButtonClick,
  storeCode,
}) => {
  const { messages, settings } = useBoxContext<
    IBoxStoreLocatorProps | IBoxMyAccountMyAddressListProps
  >();
  return (
    <StyledTableView id="scrollableDiv" className="table-view">
      {stores?.length > 0 ? (
        stores.map((item, index) => {
          return (
            <StyledStore
              key={`${item.name}_${index}`}
              backgroundColor={
                item.code === storeCode
                  ? settings.storelocator_store_picked_color
                  : undefined
              }
            >
              <div className="chosen-item">
                <Store
                  isPhoto={imagesVisible}
                  store={item}
                  onSelectAddressButtonClick={onSelectAddressButtonClick}
                />
              </div>
            </StyledStore>
          );
        })
      ) : (
        <div className="table-view__not-found">
          <StyledText
            className="stores-display-desktop__not-found__label"
            $settings={{
              font: settings.storelocator_not_found_typography,
              text: { color: settings.storelocator_not_found_color },
            }}
            show={isNotEmptyMessage(messages.storelocator_not_found)}
          >
            {messages.storelocator_not_found}
          </StyledText>
        </div>
      )}
    </StyledTableView>
  );
};

export default TableView;
