import styled from "styled-components";

export const StyledSectionWrapper = styled.section<{
  $colorSecondary: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .section-wrapper__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
  }
  hr {
    border-color: ${({ $colorSecondary }) => $colorSecondary};
    margin-block: 8px 16px;
  }
`;
