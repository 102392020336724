import {
  IAddToWishlistSetting,
  IComparisonSetting,
} from "../../../../../settingsPatterns/contentPatterns.scheme";
import { TRegularBoxType } from "@ecp-pageTypes";
import {
  ISrpTransformResponseV2,
  ISrpWithSearchTransformResponseV2,
} from "@ecp-redux/dto/searchResults.types";
import {
  HoverEffects,
  IBorder,
  IElementButton,
  IElementLinkWithVisibility,
  IElementTextContent,
  IElementVisibility,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  ISpacing,
  SettingPositionOptions,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxSearchResultsContentSettings,
  IMediaAssetsSettings,
} from "../../../../../boxes/BoxSearchResults/BoxSearchResults.types";
import { TVariant } from "../ProductVariants/ProductVariants.types";
import {
  IDropdownReadoutValue,
  InputReadoutValueScheme,
  SpacingScheme,
  ButtonReadoutValueScheme,
  UrlScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { z } from "zod";
import {
  IAttributesWithMediaAssets,
  IAttributeMediaAsset,
} from "@ecp-redux/api/mediaAssets";

export enum ProductButtonActions {
  GO_TO_PRODUCT = "GO_TO_PRODUCT",
  GO_TO_URL = "GO_TO_URL",
  ADD_TO_CART = "ADD_TO_CART",
  COMPARISON = "COMPARISON",
  ADD_TO_WISHLIST = "ADD_TO_WISHLIST",
}

export interface IProductButton extends IElementButton {
  isCustomWidth: boolean;
  customWidth: number;
  type: ProductButtonActions;
  url: string;
  comparisonSetting: IComparisonSetting;
  addToWishlistSetting: IAddToWishlistSetting;
  labelText: string;
}

export interface IProductAttribute extends IElementTextContent {
  prefix: IPrefixSuffix;
  suffix: IPrefixSuffix;
  applyMediaAssets: boolean;
  mediaAssets?: {
    iconSize: { auto: boolean; height: number };
    borderRadius: number;
    pureIcon: boolean;
    iconPosition: SettingPositionOptions;
    spaceBetween: number;
  };
}

export interface IProductModuleRatingStyle extends IProductAttribute {
  filledPointsColor: TColorReadoutValue;
  unfilledPointsColor: TColorReadoutValue;
}

export interface IPrefixSuffix extends IElementTextContent {
  heading: string;
  spaceBetweenHeadingAndAttribute: number;
}

export interface IProductName extends IElementLinkWithVisibility {
  prefix: IPrefixSuffix;
  suffix: IPrefixSuffix;
  applyMediaAssets: boolean;
  mediaAssets?: IMediaAssetsSettings;
}

export interface IProductModuleElementsStyle {
  sku: IProductAttribute;
  productName: IProductName;
  actualPrice: IProductAttribute;
  oldPrice: IProductAttribute;
  netPrice: IProductAttribute;
  rating: IProductModuleRatingStyle;
  delivery: IProductAttribute;
  button: IProductButton;
  secondaryButton: IProductButton;
  custom: { [key: string]: IProductAttribute };
  customActionAttributes: {
    addToCartQuantity?: TAddToCartQuantity;
    customButton?: IProductButton;
    addToCartUnit?: IDropdownReadoutValue;
  };
}

export interface IProductModuleSettings {
  alignment: AlignmentHorizontalOption;
  padding: ISpacing;
  spaceBetweenPhotoAndContent: number;
  spaceBetweenTextElements: number;
  background: TColorReadoutValue;
  border: Omit<IBorder, "radius">;
  spaceBetweenSlides: number;
}

export interface IProductModulePhoto {
  height: number;
  hover: HoverEffects | ProductHoverEffects;
  listPhotoHeight?: number;
}

export interface IPrefixSuffixContainerProps {
  productSettings:
    | IProductName
    | IProductModuleRatingStyle
    | IProductAttribute
    | undefined;
  child: React.ReactNode;
}

export type TCustomElementsDetected = {
  delivery: boolean;
  deliveryTime: boolean;
  tax: boolean;
  badges: boolean;
  pools: boolean;
  default: boolean;
};

export const defaultCustomSelected: TCustomElementsDetected = {
  badges: false,
  default: false,
  delivery: false,
  deliveryTime: false,
  pools: false,
  tax: false,
};

interface IProductAttributesDisplay {
  product: ISrpTransformResponseV2;
  productSettings:
    | Partial<IBoxSearchResultsContentSettings["product"]>
    | Partial<IProductModuleElementsStyle>;
  boxId: number;
  boxType: TRegularBoxType;
  buttonText: string;
  ratingText: string;
  variantSettings?: TVariant;
  attributesLineDetection: ISrpWithSearchTransformResponseV2["attributesLineDetection"];
}

export interface IAttributesInRowProps extends IProductAttributesDisplay {
  attributesRows: string[];
  orderInModule: string[];
  moduleSettings: IProductModuleSettings;
  attributesWithMediaAssets?: IAttributesWithMediaAssets;
}

export interface IProductAttributesDisplayProps
  extends IProductAttributesDisplay {
  attribute: string;
  safeIndex: number;
  mediaAsset: IAttributeMediaAsset | undefined;
}

export interface IAttributesInColumnProps extends IProductAttributesDisplay {
  orderInModule: string[];
  moduleSettings: IProductModuleSettings;
  attributesWithMediaAssets?: IAttributesWithMediaAssets;
}

export const AddToCartQuantityScheme = z
  .object({
    inputStyle: InputReadoutValueScheme,
    padding: SpacingScheme,
    contentAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.CENTER),
    removeFromCartButtonStyle: ButtonReadoutValueScheme,
    addToCartButtonStyle: ButtonReadoutValueScheme,
  })
  .default({
    inputStyle: InputReadoutValueScheme.parse({}),
    padding: SpacingScheme.parse({}),
    contentAlignment: AlignmentHorizontalOption.CENTER,
    removeFromCartButtonStyle: ButtonReadoutValueScheme.parse({}),
    addToCartButtonStyle: ButtonReadoutValueScheme.parse({}),
  })
  .catch({
    inputStyle: InputReadoutValueScheme.parse({}),
    padding: SpacingScheme.parse({}),
    contentAlignment: AlignmentHorizontalOption.CENTER,
    removeFromCartButtonStyle: ButtonReadoutValueScheme.parse({}),
    addToCartButtonStyle: ButtonReadoutValueScheme.parse({}),
  });
export type TAddToCartQuantity = z.infer<typeof AddToCartQuantityScheme>;

export const CustomButtonScheme = ButtonReadoutValueScheme.removeCatch()
  .removeDefault()
  .merge(
    z.object({
      isCustomWidth: z.boolean().default(false).catch(false),
      customWidth: z.number().default(100).catch(100),
      type: z
        .nativeEnum(ProductButtonActions)
        .default(ProductButtonActions.ADD_TO_CART)
        .catch(ProductButtonActions.ADD_TO_CART),
      text: z.string().default("Custom button").catch("Custom button"),
      url: UrlScheme.default("").catch(""),
    })
  )
  .default({
    ...ButtonReadoutValueScheme.parse({}),
    isCustomWidth: false,
    customWidth: 100,
    type: ProductButtonActions.ADD_TO_CART,
    text: "Custom button",
    url: "",
  })
  .catch({
    ...ButtonReadoutValueScheme.parse({}),
    isCustomWidth: false,
    customWidth: 100,
    type: ProductButtonActions.ADD_TO_CART,
    text: "Custom button",
    url: "",
  });
export type TCustomButton = z.infer<typeof CustomButtonScheme>;

export interface IProductModuleProps {
  product: ISrpTransformResponseV2;
  moduleSettings: IProductModuleSettings;
  productPhoto: IProductModulePhoto;
  badge: IElementVisibility;
  favorites: IElementVisibility;
  buttonText: string;
  ratingText: string;
  productSettings:
    | Partial<IBoxSearchResultsContentSettings["product"]>
    | Partial<IProductModuleElementsStyle>;
  orderInModule: (keyof IProductModuleElementsStyle | string)[];
  boxId: number;
  boxType: TRegularBoxType;
  variantSettings?: TVariant;
  attributesLineDetection: ISrpWithSearchTransformResponseV2["attributesLineDetection"];
  leftPictureMode?: {
    photoSize: number;
    pictureAlignment: AlignmentVerticalOption;
    attributesAlignment: AlignmentVerticalOption;
    spaceBetweenPhotoAndAttributes: number;
    badgesShown: boolean;
  };
  setIsNextImageLoaded?: (loaded: boolean) => void;
  gapBetweenColumns?: number;
}

export interface IProductAttributeValue {
  value: string;
  label: string;
}
