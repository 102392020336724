import { useTheme } from "styled-components";
import {
  ISrpTransformResponseV2,
  TAttributeValue,
} from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPercentage, formatPrice } from "../../../helpers/helpers";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

export const isPIMAttribute = (
  attr: ISrpTransformResponseV2[keyof ISrpTransformResponseV2]
): attr is TAttributeValue => {
  return (
    attr !== null &&
    typeof attr === "object" &&
    ("label" in attr || "value" in attr)
  );
};

const useGetProductValueByKey = (): ((
  product: ISrpTransformResponseV2,
  columnKey: string
) => string | null) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { currencyShort } = useLanguageCurrencyContext();

  return (product: ISrpTransformResponseV2, columnKey: string) => {
    const deliveryTimeMessage =
      globalMessages[`delivery_${product.deliveryTime}`] ??
      globalMessages[`delivery_unknown`];
    if (columnKey === "sku") {
      return product["type"] === "VARIANT"
        ? product["conceptId"]
        : product["sku"];
    } else if (columnKey.toLocaleLowerCase().includes("price")) {
      const value = product[columnKey];
      return typeof value === "number"
        ? formatPrice(value, currencyShort)
        : null;
    } else if (columnKey === "rating") {
      return product.rating.rate ? `${product.rating.rate}/ 5` : null;
    } else if (columnKey === "badges") {
      return product.badges.map((badge) => badge.name).join(", ");
    } else if (columnKey === "pools") {
      return product.pools && product.pools?.length > 0
        ? product.pools.map((pool) => pool.name).join(", ")
        : null;
    } else if (columnKey === "TAX") {
      const value = product[columnKey];
      return product[columnKey] && isPIMAttribute(value)
        ? formatPercentage(parseFloat(value?.value), globalMessages["taxShort"])
        : null;
    } else if (columnKey === "deliveryTime" || columnKey === "delivery") {
      return deliveryTimeMessage;
    } else if (columnKey === "variants") {
      return product?.variants
        ?.map((variant) => variant.attribute.value)
        .join(", ");
    } else if (columnKey === "associations") {
      if (Object.keys(product?.associations ?? {}).length > 0) {
        return Object.keys(product?.associations ?? {}).join(", ");
      }
      return null;
    } else if (columnKey === "wishlist") {
      return (
        product?.wishlist.base.toString() ||
        product?.wishlist.concept.toString()
      );
    } else {
      const productAttributes = product[columnKey];

      if (isPIMAttribute(productAttributes)) {
        return String(productAttributes.value);
      } else if (
        Array.isArray(productAttributes) &&
        productAttributes.length > 0 &&
        isPIMAttribute(productAttributes[0])
      ) {
        return productAttributes.map((el) => el.value).join(", ");
      } else if (
        Array.isArray(productAttributes) &&
        productAttributes.length > 0
      ) {
        return productAttributes.join(", ");
      } else {
        return productAttributes != null ? String(productAttributes) : null;
      }
    }
  };
};

export default useGetProductValueByKey;
