import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { StyledSectionWrapper } from "./SectionWrapper.styled";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { isNotEmptyMessage } from "@ecp-boxes/helpers/isNotEmptyMessage";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useTheme } from "styled-components";
import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";

interface ISectionWrapperProps {
  variant:
    | "contact"
    | "address"
    | "delivery"
    | "your_packstations"
    | "payment"
    | "billing"
    | "store";
  isEdit?: boolean;
  setIsEdit?: (isEdit: boolean) => void;
  onClickCallback?: (action?: any) => void;
  withSeparator?: boolean;
  withEditButton?: boolean;
  withSubtitle?: boolean;
}

const SectionWrapper = ({
  children,
  isEdit,
  setIsEdit,
  onClickCallback,
  variant,
  withSeparator = true,
  withEditButton = true,
  withSubtitle = true,
}: React.PropsWithChildren<ISectionWrapperProps>) => {
  const { settings, messages } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { isLogged } = useBoxCartStepTwoExtended();
  const theme = useTheme() as IThemeState;

  const colorSecondary = convertColorIdToHex(
    settings.cart_color_secondary,
    theme.colorPalette
  );

  const title = messages[`${variant}_section_title`];
  const subtitle =
    messages[`${variant}_section_subtitle` as keyof typeof messages];

  return (
    <StyledSectionWrapper $colorSecondary={colorSecondary}>
      <div className="section-wrapper__header">
        <StyledText
          className="section-wrapper__title"
          $settings={{
            font: settings.section_title_typography,
            text: { color: settings.cart_color_primary },
          }}
          show={isNotEmptyMessage(title)}
        >
          {title}
        </StyledText>
        {(setIsEdit || onClickCallback) && withEditButton && (
          <StyledButton
            renderAs="button"
            $settings={settings.change_button_style}
            onClick={() => {
              onClickCallback?.();
              setIsEdit?.(!isEdit);
            }}
          >
            {isEdit ? messages.back : messages.change}
          </StyledButton>
        )}
      </div>
      {(isEdit || !isLogged) && withSubtitle && (
        <StyledText
          show={isNotEmptyMessage(subtitle)}
          className="section-wrapper__subtitle"
          $settings={{
            font: settings.section_subtitle_typography,
            text: { color: settings.cart_color_secondary },
          }}
        >
          {subtitle}
        </StyledText>
      )}
      {children}
      {withSeparator && <hr />}
    </StyledSectionWrapper>
  );
};

export default SectionWrapper;
