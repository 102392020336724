import styled, { css, keyframes } from "styled-components";
import { IBoxCartStepTwoExtendedSettings } from "../../BoxCartStepTwoExtended.types";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";

const slideDown = keyframes`
  from {
    grid-template-rows: 0fr;
    visibility: hidden;
  }
  to {
    grid-template-rows: 1fr;
    visibility: visible;
  }
`;

const slideUp = keyframes`
  from {
    grid-template-rows: 1fr;
    visibility: visible;
  }
  to {
    grid-template-rows: 0fr;
    visibility: hidden;
  }
`;

export const StyledSelectionTable = styled.div<{
  $settings: IBoxCartStepTwoExtendedSettings;
  $isLoading?: boolean;
  $verticalPadding?: number;
  $animationDuration?: number;
}>(({ $settings, theme, $isLoading, $verticalPadding, $animationDuration }) => {
  const frameColor = convertColorIdToHex(
    $settings.frame_color,
    theme.colorPalette
  );
  const selectedBackgroundColor = convertColorIdToHex(
    $settings.selection_background_color,
    theme.colorPalette
  );
  return css`
    ${$isLoading && `opacity: 0.5;`}
    display: flex;
    flex-direction: column;
    border: 1px solid ${frameColor};
    position: relative;
    border-radius: 3px;
    overflow: clip;
    .radio-select {
      padding: ${$verticalPadding}px 10px;
      border-bottom: 1px solid ${frameColor};
      &:last-child {
        border-bottom: none;
      }
      &--selected {
        background-color: ${selectedBackgroundColor};
        & ~ .expanded {
          background-color: ${selectedBackgroundColor};
        }
      }
    }
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .expander {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
    }
    .expanded {
      animation: ${slideDown} ${$animationDuration}ms ease-in-out forwards;
      visibility: visible;

      .expander-content {
        visibility: visible;
      }
    }

    .collapsed {
      animation: ${slideUp} ${$animationDuration}ms ease-in-out forwards;
    }
    .expander-content {
      min-height: 0;
      border-bottom: 1px solid ${frameColor};
    }
    .payment-container {
      min-height: 116px;
      padding: 16px;
      display: grid;
      place-items: center;
      text-align: center;
    }
  `;
});
