import React, { useEffect } from "react";
import cleanDeep from "clean-deep";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  AddressPostalBilling,
  AddressType,
  IBillingPostalAddAddressExtendedRequest,
  IBillingPostalAddAddressRequest,
} from "@ecp-redux/dto/cartAddresses.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNIP, isPhone, isZipCode } from "../../../helpers/validators";
import { Checkbox } from "../../../shared/components/CheckboxesList/CheckboxesList";
import { InputText } from "../../../shared/components/Input/Inputs";
import { FormRadio } from "../../../shared/components/Radio/Radio";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../BoxCartStepTwoExtended/BoxCartStepTwoExtended.types";
import styled, { css } from "styled-components";
import { MOBILE_BREAKPOINT } from "@ecp-boxes/global/global";
import { useBoxCartStepTwoExtended } from "../BoxCartStepTwoExtended/context/BoxCartStepTwoExtended.context";
import CountryDropdown from "./CountryDropdown";
import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import { enrichSanitizedAddressWithType } from "@ecp-boxes/helpers/helpers";

const StyledBillingPostalAddressFormExtended = styled.div(
  () => css`
    .save-button {
      display: flex;
      align-self: flex-end;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
        ${`${StyledButton} { width: 100%; }`}
      }
    }
    .billing-postal-address-form__form {
      &__address-type {
        margin-top: 10px;
      }
      .country-dropdown {
        > .dropdown__button {
          width: 100%;
        }
      }
    }

    .half_inputs {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
    }

    .billing-postal-address-form {
      &__add-address-button {
        margin-bottom: 0.75rem;
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__address-type {
          display: flex;
          gap: 1.25rem;
        }
      }
    }
  `
);

type CustomerAddressType = "private" | "company";

export interface BillingPostalAddressForm
  extends Omit<IBillingPostalAddAddressRequest, "type"> {
  addressType: CustomerAddressType;
  copyPostalToBilling?: boolean;
}
export interface BillingPostalAddressFormExtended
  extends Omit<IBillingPostalAddAddressExtendedRequest, "type"> {
  addressType: CustomerAddressType;
  copyPostalToBilling?: boolean;
}

interface NewAddressFormProps {
  disableForm: () => void;
  addressType: AddressType.POSTAL | AddressType.BILLING;
  editData: AddressPostalBilling | null;
  isDefaultAddress?: boolean;
  setIsAddressValid: (isValid: boolean) => void;
}

const BillingPostalAddressFormExtended: React.FC<NewAddressFormProps> = ({
  disableForm,
  addressType,
  editData,
  isDefaultAddress = false,
  setIsAddressValid,
}) => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { isLogged, setSelectedAddress, setSelectedBillingAddress } =
    useBoxCartStepTwoExtended();
  const { getSessionStorageItem, removeSessionStorageItem } =
    useSetSessionStorage();

  const isZipPhoneValidationEnabled =
    settings.form_zip_phone_validation_enabled === "true";

  const [addAddress] =
    cartAPI.usePostCartAddressByPortalUserTokenExtendedMutation();

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(messages.form_required_verification)
      .default(""),
    lastName: yup
      .string()
      .trim()
      .required(messages.form_required_verification)
      .default(""),
    street: yup
      .string()
      .trim()
      .required(messages.form_required_verification)
      .default(""),
    streetNumber: yup.string().trim().notRequired().default(""),
    flatNumber: yup.string().trim().notRequired().default(""),
    city: yup
      .string()
      .trim()
      .required(messages.form_required_verification)
      .default(""),
    zipCode: isZipPhoneValidationEnabled
      ? isZipCode(
          messages.form_syntax_verification_zip_code,
          messages.form_required_verification
        ).default("")
      : yup
          .string()
          .trim()
          .required(messages.form_required_verification)
          .default(""),
    country: yup
      .string()
      .trim()
      .required(messages.form_required_verification)
      .default(""),
    phone: isZipPhoneValidationEnabled
      ? isPhone(messages.form_syntax_verification_phone)
          .required(messages.form_required_verification)
          .default("")
      : yup
          .string()
          .trim()
          .required(messages.form_required_verification)
          .default(""),
    defaultAddress: yup.boolean().default(isDefaultAddress),
    addressType: yup
      .mixed<CustomerAddressType>()
      .oneOf(["private", "company"])
      .default(
        (editData?.companyName ? "company" : "private") as CustomerAddressType
      ),
    nip: yup
      .string()
      .when("addressType", {
        is: "company",
        then: isNIP(messages.form_syntax_verification_nip),
      })
      .default(""),
    companyName: yup
      .string()
      .when("addressType", {
        is: "company",
        then: yup.string().trim().required(messages.form_required_verification),
      })
      .default(""),
  });

  const storageRecipientDefaultValues =
    getSessionStorageItem("recipient_address");
  const storageBillingDefaultValues = getSessionStorageItem("billing_address");

  const storageDefaultValues =
    addressType === AddressType.BILLING
      ? storageBillingDefaultValues
      : storageRecipientDefaultValues;

  const billingPostalAddressFormMethods = useForm<BillingPostalAddressForm>({
    defaultValues:
      !isLogged && storageDefaultValues
        ? (enrichSanitizedAddressWithType(storageDefaultValues) as any)
        : validationSchema.getDefault(),
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const {
    handleSubmit,
    control,
    register,
    watch,
    resetField,
    getValues,
    formState: { isValid },
  } = billingPostalAddressFormMethods;

  useEffect(() => {
    setIsAddressValid(isValid);
  }, [isValid, setIsAddressValid]);

  const parseFormData = (data: BillingPostalAddressFormExtended) => {
    const formPayload: IBillingPostalAddAddressExtendedRequest = {
      ...data,
      phone: data.phone.replace(/-/g, ""),
      type: addressType === AddressType.BILLING ? "BILLING" : "RECIPIENT",
      nip:
        data.addressType === "company"
          ? data.nip?.replace(/-/g, "")
          : undefined,
      companyName:
        data.addressType === "company" ? data.companyName : undefined,
    };

    return cleanDeep(formPayload) as IBillingPostalAddAddressExtendedRequest;
  };

  const onSubmit = (data: BillingPostalAddressFormExtended) => {
    if (editData == null) {
      addAddress(parseFormData(data)).then(disableForm);
    }
  };

  const isPrivate = watch("addressType") === "private";

  useEffect(() => {
    if (isPrivate) {
      resetField("nip");
      resetField("companyName");
    }
  }, [isPrivate, resetField]);

  const showPostalCheckbox = isLogged && addressType === AddressType.POSTAL;
  const showSaveButton =
    isLogged &&
    (addressType === AddressType.BILLING || addressType === AddressType.POSTAL);

  const formValues = useWatch({ control });

  useEffect(() => {
    if (!isLogged) {
      if (addressType === AddressType.POSTAL) {
        setSelectedAddress(
          isValid
            ? parseFormData(formValues as BillingPostalAddressFormExtended)
            : null
        );
      } else {
        setSelectedBillingAddress(
          isValid
            ? parseFormData(formValues as BillingPostalAddressFormExtended)
            : null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, getValues, isLogged, formValues]);

  useEffect(() => {
    if (!isLogged && storageDefaultValues) {
      removeSessionStorageItem("recipient_address");
      removeSessionStorageItem("billing_address");
    }
  }, [isLogged, storageDefaultValues]);

  return (
    <StyledBillingPostalAddressFormExtended
      data-testid="PostalBillingAddressForm"
      className="billing-postal-address-form"
    >
      <FormProvider {...billingPostalAddressFormMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="billing-postal-address-form__form"
        >
          <div className="billing-postal-address-form__form__address-type">
            <FormRadio
              {...register("addressType")}
              label={messages.form_heading_private}
              value="private"
              textStyles={{
                labelColor: settings.basket_step_all_color_main,
                labelTypo: settings.basket_step_all_typography_paragraph_2,
              }}
            />
            <FormRadio
              {...register("addressType")}
              label={messages.form_heading_company}
              value="company"
              textStyles={{
                labelColor: settings.basket_step_all_color_main,
                labelTypo: settings.basket_step_all_typography_paragraph_2,
              }}
            />
          </div>
          <CountryDropdown isPostal={addressType === AddressType.POSTAL} />
          <div className="half_inputs">
            <InputText
              name="firstName"
              control={control}
              placeholder={messages.form_placeholder_first_name}
              label={messages.form_heading_first_name}
              $settings={settings.basket_step_2_half_input}
              maxLength={20}
            />
            <InputText
              name="lastName"
              control={control}
              placeholder={messages.form_placeholder_last_name}
              label={messages.form_heading_last_name}
              $settings={settings.basket_step_2_half_input}
              maxLength={20}
            />
          </div>
          {!isPrivate && (
            <InputText
              name="nip"
              control={control}
              placeholder={messages.form_placeholder_nip}
              label={messages.form_heading_nip}
              $settings={settings.basket_step_2_full_input}
              mask="999-999-99-99"
            />
          )}
          {!isPrivate && (
            <InputText
              name="companyName"
              control={control}
              placeholder={messages.form_placeholder_company_name}
              label={messages.form_heading_company_name}
              $settings={settings.basket_step_2_full_input}
              maxLength={100}
            />
          )}
          <InputText
            name="street"
            control={control}
            placeholder={messages.form_placeholder_street}
            label={messages.form_heading_street}
            $settings={settings.basket_step_2_full_input}
            maxLength={75}
          />
          <div className="half_inputs">
            <InputText
              name="streetNumber"
              control={control}
              placeholder={messages.form_placeholder_house_number}
              label={messages.form_heading_house_number}
              $settings={settings.basket_step_2_half_input}
              maxLength={10}
            />
            <InputText
              name="flatNumber"
              control={control}
              placeholder={messages.form_placeholder_apartment_number}
              label={messages.form_heading_apartment_number}
              $settings={settings.basket_step_2_half_input}
              maxLength={10}
            />
          </div>
          <div className="half_inputs">
            <InputText
              name="zipCode"
              control={control}
              placeholder={messages.form_placeholder_zip_code}
              label={messages.form_heading_zip_code}
              $settings={settings.basket_step_2_half_input}
              {...(isZipPhoneValidationEnabled && {
                mask: "99-999",
              })}
            />
            <InputText
              name="city"
              control={control}
              placeholder={messages.form_placeholder_city}
              label={messages.form_heading_city}
              $settings={settings.basket_step_2_half_input}
              maxLength={35}
            />
          </div>
          <InputText
            name="phone"
            control={control}
            placeholder={messages.form_placeholder_phone}
            label={messages.form_heading_phone}
            $settings={settings.basket_step_2_full_input}
            {...(isZipPhoneValidationEnabled && {
              mask: "999-999-999",
            })}
          />
          {showPostalCheckbox && (
            <Checkbox
              control={control}
              name="defaultAddress"
              label={messages.form_heading_default_address}
              textStyles={{
                labelTypo: settings.basket_step_2_checkbox_typo,
                labelColor: settings.basket_step_2_checbox_color,
                errorTypo: settings.basket_step_2_checkbox_typo,
                errorColor: settings.basket_step_2_checbox_color,
              }}
              disabled={isDefaultAddress}
            />
          )}

          {showSaveButton && (
            <div className="save-button">
              <StyledButton
                renderAs="button"
                type="submit"
                $settings={settings.save_form_button__style}
                show={messages.form_button_save}
              >
                {messages.form_button_save}
              </StyledButton>
            </div>
          )}
        </form>
      </FormProvider>
    </StyledBillingPostalAddressFormExtended>
  );
};

export default BillingPostalAddressFormExtended;
