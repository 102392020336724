import Image from "next/image";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import { ChannelType } from "@ecp-redux/dto/cart.types";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";

interface IDeliveryIcon {
  channelType: ChannelType;
  channelName: string;
}

const DeliveryIcon = <T extends IDeliveryIcon>({
  channelType,
  channelName,
}: T) => {
  const { settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  if (!settings[`delivery_image_${channelType}`])
    return <div className="icon-placeholder" />;
  return (
    <Image
      src={settings[`delivery_image_${channelType}`]}
      alt={channelName}
      width={48}
      height={24}
      style={{ objectFit: "contain", width: "48px", height: "24px" }}
    />
  );
};
export default DeliveryIcon;
