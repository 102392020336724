import styled, { css } from "styled-components";

export const ApplePayContainer = styled.div<{ $isLoading: boolean }>`
  position: relative;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0.5;
    `}
`;
