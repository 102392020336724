import { useBoxCartStepTwoExtended } from "../../context/BoxCartStepTwoExtended.context";
import SectionWrapper from "./SectionWrapper";
import SelectionTable from "../shared/SelectionTable";
import AddressElement from "../shared/AddressElement";
import {
  AddressType,
  isPackstationAddress,
} from "@ecp-redux/dto/cartAddresses.type";
import * as cartAPI from "@ecp-redux/api/cart";
import { useEffect, useState } from "react";
import { StyledYouPackstation } from "./YouPackstation.styled";
import StyledButton from "@ecp-boxes/shared/styleElements/StyledButton/StyledButton";
import { useBoxContext } from "@ecp-boxes/structure/Contexts/BoxContext";
import { IBoxCartStepTwoExtendedProps } from "../../BoxCartStepTwoExtended.types";
import InpostAddressMap from "@ecp-boxes/boxes/Cart/shared/InpostAddressMap";
import { useOpenPortalSnackbar } from "@ecp-boxes/shared/components/Snackbar/Snackbar";

const YourPackstationsSection = () => {
  const { messages, settings } = useBoxContext<IBoxCartStepTwoExtendedProps>();
  const { openPortalSnackbar } = useOpenPortalSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddNewPackstationMode, setIsAddNewPackstationMode] = useState(false);
  const { selectedDeliveryChannel, isAnyPackstationAvailable } =
    useBoxCartStepTwoExtended();
  const [addPackstationAddress] =
    cartAPI.usePostCartAddressByPortalUserTokenMutation();
  const [changeSelectedAddress] =
    cartAPI.usePostChangeCartSelectedAddressMutation();
  const { addresses = [], selectedAddress: selectedPackstation } =
    selectedDeliveryChannel ?? {};

  useEffect(() => {
    if (!selectedPackstation) {
      setIsEdit(true);
    }
  }, [selectedPackstation]);

  if (!addresses.every(isPackstationAddress)) return null;

  const packstations = addresses.map((address) => ({
    selected: address?.id === selectedPackstation?.id,
    component: <AddressElement address={address} isPackstation />,
    onClick: async () => {
      setIsLoading(true);
      await changeSelectedAddress({ addressId: address.id });
      setIsLoading(false);
      setIsEdit(false);
    },
  }));

  const selectPackstationAddress = async (code: string) => {
    const response = await addPackstationAddress({
      packstationCode: code,
      defaultAddress: true,
      type: AddressType.PACKSTATION,
    });
    if (response.error) {
      openPortalSnackbar(
        settings.packstation_error_snackbar,
        messages.packstation_error_text
      );
    } else {
      await changeSelectedAddress({ addressId: response.data.addressId });
    }
    setIsAddNewPackstationMode(false);
    setIsEdit(false);
  };

  return (
    <StyledYouPackstation className="your-packstation-section">
      {isEdit ? (
        <SectionWrapper
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          variant="your_packstations"
          withEditButton={isAnyPackstationAvailable}
          withSeparator={false}
        >
          <SelectionTable items={packstations} isLoading={isLoading} />
          <StyledButton
            className="your-packstation-section__add-new-btn"
            renderAs="button"
            $settings={settings.change_button_style}
            onClick={() => setIsAddNewPackstationMode(true)}
          >
            {messages.add_new}
          </StyledButton>
        </SectionWrapper>
      ) : (
        <div className="your-packstation-section__chosen-packstation">
          <AddressElement
            address={selectedPackstation}
            isPackstation
            className="left-indent"
          />
          <StyledButton
            renderAs="button"
            $settings={settings.change_button_style}
            onClick={() => setIsEdit(true)}
          >
            {messages.change_point}
          </StyledButton>
        </div>
      )}
      {isAddNewPackstationMode && (
        <InpostAddressMap
          disableForm={() => setIsAddNewPackstationMode(false)}
          onPointSelect={(point) => {
            selectPackstationAddress(point.name);
          }}
        />
      )}
    </StyledYouPackstation>
  );
};

export default YourPackstationsSection;
