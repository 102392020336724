import { useSectionEffectsContext } from "@ecp-boxes/structure/Contexts/SectionEffectsContext";
import { StyledPortalToastContainer } from "./Snackbar.styled";
import styled, { css } from "styled-components";

const StyledSnackbarUnderHeaderWrapper = styled.div<{
  $totalStickyEffectsHeight: number;
}>`
  ${({ $totalStickyEffectsHeight }) => {
    return css`
      position: fixed;
      top: ${$totalStickyEffectsHeight}px;
      left: 0;
      width: 100vw;
      z-index: 1002;
      overflow: hidden;
      height: calc(100dvh - ${$totalStickyEffectsHeight}px);
      pointer-events: none;
    `;
  }}
`;

export const SnackbarUnderHeaderWrapper = () => {
  const { effectsData } = useSectionEffectsContext();

  const totalStickyEffectsHeight = effectsData.reduce((acc, effect) => {
    if (effect.sticky && effect.isHeaderSection) {
      return acc + (effect.height ?? 0);
    }
    return acc;
  }, 0);

  return (
    <StyledSnackbarUnderHeaderWrapper
      $totalStickyEffectsHeight={totalStickyEffectsHeight}
    >
      <StyledPortalToastContainer
        containerId="PortalHeaderSnackbar"
        closeButton={false}
        limit={3}
        autoClose={1000}
        style={{
          position: "absolute",
        }}
      />
    </StyledSnackbarUnderHeaderWrapper>
  );
};
