import React from "react";
import { IIcon } from "./Icon.types";

const PortalWarningIcon: React.FC<IIcon> = ({
  width = "20",
  height = "20",
  fill = "#FF9300",
  viewBox = "0 0 20 20",
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6V10M10 14H10.01M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PortalWarningIcon;
