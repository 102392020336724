import { z } from "zod";
import {
  ColorReadoutValueScheme,
  LinkReadoutValueScheme,
  SpacingScheme,
  TypoReadoutValueScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const EDITOR_INITIAL_VALUE = [
  {
    type: "paragraph",
    children: [
      {
        text: "text",
      },
    ],
    align: "left",
  },
];

export const BoxTextMessagesScheme = z.object({
  box_text_content_text: themeScheme.MessageScheme.default(
    JSON.stringify(EDITOR_INITIAL_VALUE)
  ),
});

export const BoxTextContentScheme = z.object({}).default({}).catch({});

export const BoxTextDisplaySettingsScheme = z
  .object({
    padding: SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }).catch({ top: 0, right: 0, bottom: 0, left: 0 }),
  })
  .default({
    padding: SpacingScheme.parse({ top: 0, right: 0, bottom: 0, left: 0 }),
  })
  .catch({
    padding: SpacingScheme.parse({ top: 0, right: 0, bottom: 0, left: 0 }),
  });

export const BoxTextContentSettingsScheme = z
  .object({
    font: TypoReadoutValueScheme,
    text: z
      .object({ color: ColorReadoutValueScheme })
      .default({ color: ColorReadoutValueScheme.parse({}) })
      .catch({ color: ColorReadoutValueScheme.parse({}) }),
    link: LinkReadoutValueScheme,
  })
  .default({
    font: TypoReadoutValueScheme.parse({}),
    text: { color: ColorReadoutValueScheme.parse({}) },
    link: LinkReadoutValueScheme.parse({}),
  })
  .catch({
    font: TypoReadoutValueScheme.parse({}),
    text: { color: ColorReadoutValueScheme.parse({}) },
    link: LinkReadoutValueScheme.parse({}),
  });
