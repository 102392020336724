import React, { useEffect } from "react";
import {
  HEX,
  IThemeState,
  ITypography,
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { AlertCode, useAlertByCodes } from "../../../redux/slices/alertsSlice";
import SimplyAlert from "./SimplyAlert";
import { useTheme } from "styled-components";

interface AlertProps {
  alertsCodes: AlertCode[];
  closeable: boolean;
  backgroundColor: HEX | TColorId;
  typography: ITypography | TTypoId;
  textColor: HEX | TColorId;
  className?: string;
  removeWhen?: boolean;
}

const Alert: React.FC<AlertProps> = (props): JSX.Element | null => {
  const { alert, closeAlert } = useAlertByCodes(props.alertsCodes);
  const theme = useTheme() as IThemeState;
  const displayTime = Number(theme.advanceSettings.settings.bar_message_display_time);
  const displayTimeInMiliseconds = displayTime * 1000;

  useEffect(() => {
    if (props.removeWhen && closeAlert) {
      closeAlert();
    }
  }, [props.removeWhen, closeAlert]);

  if (alert === undefined) {
    return null;
  }

  return (
    <div className={props.className}>
      <SimplyAlert
        {...props}
        lifetime={displayTimeInMiliseconds}
        message={alert.message}
        {...(props.closeable && { close: closeAlert })}
      />
    </div>
  );
};

export default Alert;
