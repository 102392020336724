import { z } from "zod";
import {
  ColorReadoutValueSchemeBlackDefault,
  SpacingScheme,
  createTextStyleShowSettingScheme,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
  SettingPositionOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { BannerSizeOptions } from "./BoxBanner.types";
import {
  ButtonContentScheme,
  createImageContentScheme,
} from "../../settingsSchemes/contentSchemes.utils";
import { LinkContentScheme } from "../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import {
  createImageContentSettingScheme,
  InnerBoxContentSettingScheme,
  createTitleContentSettingsScheme,
  MainButtonContentSettingScheme,
  SecondButtonContentSettingScheme,
} from "../../settingsSchemes/settingsSchemes.utils";

const defaultContentSettings = {
  image: {
    show: true,
    alignment: {
      vertical: AlignmentVerticalOption.CENTER,
      horizontal: AlignmentHorizontalOption.LEFT,
    },
    backgroundUrl:
      "https://preprod-minio-ecp.nd0.pl/ecp-media-tenant-b2b-demo/pop%20zaslepka%200.png",
    opacity: 100,
    fit: false,
    parallax: false,
  },
  box: {
    show: true,
    color: {
      id: "color$$3",
      custom: "#000000",
    },
    alignment: {
      horizontal: AlignmentHorizontalOption.LEFT,
      vertical: AlignmentVerticalOption.BOTTOM,
    },
    contentAlignment: AlignmentHorizontalOption.LEFT,
    textPadding: {
      top: 2,
      right: 2,
      bottom: 2,
      left: 2,
    },
    width: 40,
  },
  title: {
    show: true,
    font: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    },
    text: {
      color: {
        id: "color$$1",
        custom: "#000000",
      },
    },
  },
  description: {
    show: true,
    font: {
      id: "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    },
    text: {
      color: {
        id: "color$$2",
        custom: "#000000",
      },
    },
  },
  mainButton: {
    id: "button$$1",
    show: true,
    custom: {
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$3", custom: "#000000" },
        disabledColor: { id: "color$$4", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$5", custom: "#000000" },
        hoverColor: { id: "color$$6", custom: "#000000" },
        activeColor: { id: "color$$7", custom: "#000000" },
        disabledColor: { id: "color$$8", custom: "#000000" },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$10", custom: "#000000" },
        activeColor: { id: "color$$11", custom: "#000000" },
        disabledColor: { id: "color$$12", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
      },
    },
  },
  secondaryButton: {
    id: "button$$1",
    show: true,
    custom: {
      horizontalPadding: 35,
      verticalPadding: 8,
      background: {
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      border: {
        radius: 4,
        width: 0,
        radiusV2: null,
        widthV2: null,
        color: { id: "color$$1", custom: "#000000" },
        hoverColor: { id: "color$$1", custom: "#000000" },
        activeColor: { id: "color$$1", custom: "#000000" },
        disabledColor: { id: "color$$1", custom: "#000000" },
      },
      text: {
        color: { id: "color$$2", custom: "#000000" },
        hoverColor: { id: "color$$2", custom: "#000000" },
        activeColor: { id: "color$$2", custom: "#000000" },
        disabledColor: { id: "color$$2", custom: "#000000" },
      },
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      icon: {
        iconUrl: null,
        position: SettingPositionOptions.RIGHT,
        size: 16,
        spacing: 10,
      },
    },
  },
};

export const BoxBannerDisplaySettingsScheme = z
  .object({
    size: z.string().default("500").catch("500"),
    padding: SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }).catch({ top: 0, right: 0, bottom: 0, left: 0 }),
    margin: SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }).catch({ top: 0, right: 0, bottom: 0, left: 0 }),
    border: z
      .object({
        color: ColorReadoutValueSchemeBlackDefault,
        style: z
          .nativeEnum(OutlineOptions)
          .default(OutlineOptions.NONE)
          .catch(OutlineOptions.NONE),
        width: z.number().default(0).catch(0),
      })
      .default({
        color: { id: "color$$1", custom: "#000000" },
        style: OutlineOptions.NONE,
        width: 0,
      })
      .catch({
        color: { id: "color$$1", custom: "#000000" },
        style: OutlineOptions.NONE,
        width: 0,
      }),
    customHeight: z.number().default(600).catch(600),
  })
  .default({
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    border: { color: "color$$1", style: OutlineOptions.NONE, width: 0 },
    customHeight: 600,
  });

export const getBoxBannerContentScheme = (imageUrl = ""): z.ZodType => {
  return z
    .object({
      image: createImageContentScheme(imageUrl),
      bannerUrl: LinkContentScheme.default({
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: true },
      }).catch({
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: true },
      }),
      mainButton: ButtonContentScheme,
      secondaryButton: ButtonContentScheme,
    })
    .default({
      image: {
        imageUrl: imageUrl,
      },
      bannerUrl: {
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: { rowId: null },
        goToPageSetting: { link: "", openInNewTab: true },
      },
      mainButton: { link: "" },
      secondaryButton: { link: "" },
    });
};

export const getBoxBannerContentSettingsScheme = (imageUrl?: string) => {
  return z
    .object({
      image: createImageContentSettingScheme(imageUrl).default(
        defaultContentSettings.image
      ),
      box: InnerBoxContentSettingScheme.default(defaultContentSettings.box),
      title: createTitleContentSettingsScheme().default(
        defaultContentSettings.title
      ),
      description: createTextStyleShowSettingScheme().default(
        defaultContentSettings.description
      ),
      mainButton: MainButtonContentSettingScheme.default(
        defaultContentSettings.mainButton
      ),
      secondaryButton: SecondButtonContentSettingScheme.default(
        defaultContentSettings.secondaryButton
      ),
    })
    .default(defaultContentSettings);
};

export const BoxBannerMessageScheme = z.object({
  box_banner_content_description:
    themeScheme.MessageScheme.default("description"),
  box_banner_content_main_button_heading:
    themeScheme.MessageScheme.default("main button"),
  box_banner_content_title: themeScheme.MessageScheme.default("title"),
  box_banner_content_seo_description:
    themeScheme.MessageScheme.default("seo description"),
  box_banner_content_secondary_button_heading:
    themeScheme.MessageScheme.default("secondary button"),
});
